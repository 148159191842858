
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busserviceforpicnic() {



  const cardData =
  {
    keyword: ' Bus Service for Picnics in Pune',
    heading: 'Shraddha Travels: Bus Service for Picnics in Pune',
    headingDescription: 'Planning the perfect picnic goes beyond just selecting a location; it requires reliable and comfortable transportation. Shraddha Travels specializes in providing exceptional bus services tailored for picnic outings in and around Pune. Here’s a comprehensive guide to our top offerings for picnic bus services that guarantee a smooth and enjoyable experience.',

    topPlaces: [
        {
            title: "1. Spacious Family-Friendly Buses",
            description: "Our buses are designed with maximum comfort and space in mind, perfect for family picnics. With ample seating, adjustable headrests, and plenty of legroom, everyone can enjoy a relaxing journey. Safety features, such as seatbelts and anti-lock braking systems, ensure your loved ones are secure while traveling. Enjoy the convenience of onboard storage for picnic supplies, games, and equipment, making your outing hassle-free."
        },
        {
            title: "2. Corporate Picnic Solutions",
            description: "For corporate picnics, we provide efficient transportation for large groups, accommodating anywhere from small teams to sizable departments. Our buses can cater to high-capacity needs, ensuring that all employees can travel together. Our professional drivers are trained to handle corporate clients, ensuring a smooth ride where employees can relax, network, and enjoy refreshments onboard, making the journey part of the fun."
        },
        {
            title: "3. Cost-Effective Rental Options",
            description: "We understand the importance of budget constraints when planning a picnic. That’s why we offer affordable bus rental options without compromising on quality. Enjoy competitive rates and flexible packages that cater to different needs, whether you're planning a small family gathering or a large corporate event. Our transparent pricing ensures no hidden fees, allowing you to plan your outing with confidence."
        },
        {
            title: "4. Timely and Dependable Service",
            description: "Punctuality is crucial for a successful picnic. Shraddha Travels is known for its reliable service, ensuring your bus arrives on time to pick you up and drop you off at your desired location. Our commitment to timely service means you can focus on enjoying your picnic without delays or stress. Our drivers are trained to plan for traffic conditions and potential delays, ensuring a smooth experience."
        },
        {
            title: "5. Luxury Bus Experiences",
            description: "For those looking to add a touch of luxury to their picnic, we offer high-end buses with premium amenities. Our luxury buses feature plush seating, climate control, and entertainment options like TVs and music systems, creating a sophisticated picnic experience. Enjoy complimentary refreshments and snacks as you travel, making the journey just as enjoyable as the destination."
        },
        {
            title: "6. Mini Buses for Intimate Gatherings",
            description: "If your picnic involves a smaller group, our mini buses are an ideal choice. They provide a cozy environment while being economical, ensuring a more personal travel experience. With fewer passengers, the mini bus allows for closer interaction among family or friends, fostering a more intimate atmosphere during your outing."
        },
        {
            title: "7. Customizable Itineraries",
            description: "Every picnic is unique, and so are your travel needs. We offer customizable routes and itineraries, allowing you to design your travel plan based on your picnic destination and activities. Whether you want to visit local parks, scenic spots, or attractions, our team is ready to assist in crafting the perfect route for your group, ensuring you get the most out of your outing."
        },
        {
            title: "8. Experienced and Courteous Drivers",
            description: "Our drivers are not only experienced but also courteous, ensuring a pleasant journey. They are well-trained to handle various road conditions and can assist with loading and unloading your picnic gear. With their friendly demeanor and professionalism, our drivers contribute to a positive travel experience, making everyone feel welcome and comfortable."
        },
        {
            title: "9. Well-Maintained and Clean Vehicles",
            description: "We prioritize hygiene and vehicle maintenance to guarantee your safety and comfort. Our buses undergo regular inspections and thorough cleaning before each trip. We use high-quality cleaning products and sanitization procedures, ensuring a clean and pleasant environment for all passengers. This commitment to cleanliness allows you to focus on enjoying your picnic without any worries."
        },
        {
            title: "10. Comprehensive Customer Support",
            description: "From the moment you inquire about our services to the end of your trip, our customer support team is available to assist you. We ensure all your questions are answered and any issues resolved promptly. Our knowledgeable staff can provide recommendations for picnic spots, help with itinerary planning, and offer support during your journey, ensuring a seamless experience from start to finish."
        }
    ],    
    services: [
        {
            name: "Tempo Traveller on Rent in Pune",
            description: "Looking for an easy and convenient solution for group travel? Shraddha Travels offers a wide range of Tempo Travellers on rent in Pune for all kinds of trips, whether it's a short local journey or a long-distance outstation trip. Our vehicles are designed to provide ample space, comfort, and reliability, making them ideal for family vacations, corporate outings, or weekend getaways with friends. Enjoy a hassle-free travel experience with all the amenities you need, including air conditioning, spacious seating, and entertainment options."
        },
        {
            name: "Traveller Bus on Rent in Pune",
            description: "Shraddha Travels provides Traveller buses on rent in Pune with flexible options suited for both small and large groups. Our buses come with comfortable seating, air conditioning, and professional drivers who prioritize your safety and comfort. Whether you’re planning a school trip, a corporate event, or a family reunion, we have the perfect solution to meet your needs. Experience seamless transportation with timely pickups and drop-offs."
        },
        {
            name: "Tempo Traveller on Rent Pune to Outstation",
            description: "For long-distance journeys, Shraddha Travels offers Tempo Travellers on rent from Pune to popular outstation destinations like Shirdi, Mahabaleshwar, and Goa. Our well-maintained vehicles ensure a hassle-free and comfortable journey, complete with all necessary amenities, allowing you to relax and enjoy the scenery on your way. With experienced drivers who know the best routes, we guarantee a smooth and enjoyable ride."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Rate",
            description: "Planning a pilgrimage to Shirdi? Shraddha Travels offers affordable Pune to Shirdi Tempo Traveller rates starting from ₹18 per kilometer. With our professional drivers and well-maintained vehicles, you can expect a comfortable, safe, and memorable journey as you focus on your spiritual experience. We also offer flexible scheduling to accommodate your travel plans."
        },
        {
            name: "Tempo Traveller Hire in Pune",
            description: "Shraddha Travels offers Tempo Traveller hire in Pune for all occasions, including family outings, corporate trips, and group tours. With a variety of seating capacities, we can accommodate groups of any size, ensuring everyone travels comfortably. Our flexible booking options and reliable service make planning your trip easy, while our experienced staff is on hand to assist you at every step."
        },
        {
            name: "Tempo Traveller on Rent in Hadapsar",
            description: "If you're in Hadapsar and need a reliable travel option, Shraddha Travels provides Tempo Travellers on rent for group travel, family events, and weekend getaways. Our experienced drivers and well-maintained vehicles guarantee a smooth and enjoyable journey for all your local travel needs, ensuring you reach your destination on time and in comfort."
        },
        {
            name: "Tempo Traveller on Rent in Pune Rate Per Km",
            description: "At Shraddha Travels, our Tempo Traveller rates start from ₹18 to ₹25 per kilometer, depending on the vehicle type and trip duration. We pride ourselves on transparent pricing with no hidden costs, ensuring that you receive the best value for your money while enjoying a comfortable travel experience. Contact us for a personalized quote tailored to your specific requirements."
        },
        {
            name: "Tempo Traveller Rental in Pune",
            description: "Shraddha Travels is your go-to choice for Tempo Traveller rental in Pune. Whether you're planning a local tour or a long-distance journey, our fleet of well-equipped vehicles is ready to provide a comfortable travel experience. Our knowledgeable staff is available to assist you in selecting the perfect vehicle for your needs, ensuring that your trip is well-organized and enjoyable."
        },
        {
            name: "Pune to Mahabaleshwar Cab Tempo Traveller",
            description: "Planning a scenic trip to Mahabaleshwar? Shraddha Travels offers cab Tempo Traveller services for a comfortable journey with ample space for passengers and luggage. Our experienced drivers are familiar with the best routes, ensuring you reach your destination safely and on time while enjoying the beautiful landscapes. We prioritize your comfort and satisfaction throughout the journey."
        },
        {
            name: "Pune to Goa Tempo Traveller on Rent",
            description: "Planning a trip to Goa? Shraddha Travels offers Tempo Travellers on rent, equipped with modern amenities to ensure a smooth and comfortable journey. Enjoy your beach holiday with friends and family without worrying about travel logistics. Our reliable service allows you to focus on having fun and making memories, with our team handling all your travel needs."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune",
            description: "For those seeking a premium travel experience, Shraddha Travels provides Luxury Tempo Travellers featuring plush interiors, spacious seating, and advanced amenities. Perfect for special occasions or corporate events, our luxury vehicles ensure you travel in style and comfort. Experience the best in comfort and service with our dedicated team available to assist you throughout your journey."
        },
        {
            name: "Tempo Traveller On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tempo Traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today! Whether you have questions about our fleet or need help with your itinerary, we’re here to help."
        }
    ],
    
    tableData: [
        ['- Best Bus Service for Picnic in Pune', '- Full Bus Booking Price'],
        ['- Bus Booking for One Day Trip', '- Bus Hire in Hadapsar'],
        ['- Bus Hire in Kharadi', '- Bus Hire in Kothrud'],
        ['- Bus Hire on Rent in Pimpri Chinchwad, Pune', '- Bus Hire in Baner'],
        ['- Mini AC Bus on Rent in Wanowrie, Pune', '- Mini AC Bus on Rent in Sinhagad Road, Pune'],
        ['- Mini Bus on Rent in Aundh, Pune', '- Mini Bus on Rent in Hinjewadi for 1 Day'],
        ['- Mini Bus on Rent in Wagholi', '- Bus Service for Picnic in Pune'],
        ['- Mini Bus Rental Service in Pimpri Chinchwad', '- Tempo Traveller Service for Picnic in Pune'],
        ['- 17-Seater Tempo Traveller on Rent for Picnic in Pune', '- Tempo Traveller 17-Seater Rent Per Km'],
        ['- 17-Seater Bus on Rent in Pune', '- Tempo Traveller on Rent in Pune']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Bus Service for Picnic in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we prioritize your comfort and satisfaction. Our bus service for picnics is designed to provide a smooth and enjoyable travel experience, ensuring your group has a memorable outing. With our commitment to quality and customer care, your picnic will be well-organized and stress-free."
        },
        {
            WhyChooseheading: "Comfortable Travel Experience:",
            WhyChoosedescription: "Our buses are spacious, featuring comfortable seating, ample legroom, and modern amenities like air conditioning and entertainment systems. We understand that comfort is key during travel, especially for longer journeys, allowing everyone to relax and enjoy the ride."
        },
        {
            WhyChooseheading: "Diverse Fleet of Buses:",
            WhyChoosedescription: "Whether you're planning a small family outing or a large corporate picnic, we offer a diverse fleet of buses to accommodate groups of all sizes. From compact mini buses perfect for intimate gatherings to luxurious coaches equipped with premium features, we have the ideal vehicle for your needs."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We believe in providing value for money. Our competitive pricing ensures that you can enjoy high-quality service without breaking the bank. We also offer transparent quotes with no hidden fees, helping you budget effectively for your picnic."
        },
        {
            WhyChooseheading: "Customizable Itineraries:",
            WhyChoosedescription: "Every picnic is unique, and we offer customizable itineraries to suit your preferences. Work with our team to select your destination, route, and schedule. Whether you want to visit a specific park, enjoy a scenic drive, or include stops along the way, we tailor our services to match your vision."
        },
        {
            WhyChooseheading: "Punctual and Reliable Service:",
            WhyChoosedescription: "We pride ourselves on our punctuality and reliability. Our professional drivers ensure timely arrivals and departures, so you can maximize your picnic time without the stress of delays. We value your time and strive to make your outing enjoyable from start to finish."
        },
        {
            WhyChooseheading: "Experienced and Friendly Drivers:",
            WhyChoosedescription: "Our drivers are not only skilled and experienced but also friendly and courteous. They are dedicated to providing a safe and enjoyable travel experience, guiding you with local insights and ensuring that everyone feels welcome and comfortable throughout the journey."
        },
        {
            WhyChooseheading: "Hygiene and Maintenance:",
            WhyChoosedescription: "Your safety is our top priority. Our buses undergo regular cleaning and maintenance to the highest standards, ensuring a hygienic environment for all travelers. We take extra precautions, especially in today's context, to ensure your peace of mind during travel."
        },
        {
            WhyChooseheading: "Comprehensive Customer Support:",
            WhyChoosedescription: "From the moment you book your bus to the end of your trip, our customer support team is here to assist you. We are available to answer any questions, provide guidance, and address any concerns you may have, ensuring a seamless experience. Your satisfaction is our mission."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for picnic rentals?",
        answer: "We offer a range of buses, including mini buses, standard coaches, and luxury buses to accommodate different group sizes and preferences."
    },
    {
        question: "How can I book a bus for my picnic?",
        answer: "You can easily book a bus by contacting our customer service team through phone or email. We’ll guide you through the booking process and help you choose the best vehicle for your needs."
    },
    {
        question: "Can I customize the itinerary for my picnic trip?",
        answer: "Absolutely! We encourage our customers to customize their itineraries. You can choose your destination, stops along the way, and the schedule that works best for your group."
    },
    {
        question: "Are your buses well-maintained and safe?",
        answer: "Yes, safety is our top priority. Our buses undergo regular maintenance and inspections to ensure they are in excellent condition. We also adhere to strict hygiene standards to provide a safe travel environment."
    },
    {
        question: "What are the payment options available?",
        answer: "We offer flexible payment options, including cash, bank transfers, and online payments. Our team will provide you with all the details during the booking process."
    },
    {
        question: "How many people can fit in a bus?",
        answer: "Our buses vary in capacity. Mini buses typically accommodate 15-20 passengers, while larger coaches can accommodate 30-50 passengers or more. Let us know your group size, and we’ll recommend the right bus for you."
    },
    {
        question: "What should I do if I need to make changes to my booking?",
        answer: "If you need to make changes to your booking, please contact us as soon as possible. We will do our best to accommodate your requests based on availability."
    },
    {
        question: "Are there any additional fees or charges?",
        answer: "We provide transparent pricing with no hidden fees. Any additional charges, such as tolls or parking fees, will be communicated to you upfront during the booking process."
    },
    {
        question: "Is it possible to hire a bus for a one-day picnic?",
        answer: "Yes, we offer flexible rental options for one-day picnics, allowing you to enjoy your outing without any hassle."
    },
    {
        question: "Do you provide drivers with the rental?",
        answer: "Yes, all our bus rentals come with experienced and professional drivers, ensuring a safe and enjoyable journey for your group."
    }
];


const testimonials = [
    {
        name: "Miss. Priya Desai",
        text: "We recently hired a bus from Shraddha Travels for our family picnic to Lonavala, and I can't recommend them enough! The bus was spacious and comfortable, which made the journey enjoyable for everyone. Our driver was punctual, friendly, and knew the best routes, ensuring we arrived on time. The whole experience was hassle-free, and we will definitely use their services again for our future outings!"
    },
    {
        name: "Mr. Abhijit Singh",
        text: "I organized a corporate picnic for our team and chose Shraddha Travels for transportation. They exceeded our expectations! The bus was clean and well-maintained, and the driver was very professional. We loved the flexibility to customize our itinerary, and the entire process from booking to the end of the trip was seamless. Our team had a fantastic time, and I appreciate the effort Shraddha Travels put into making our day special. Highly recommend them!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Service for Picnic in Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Service for Picnic in Pune | Affordable Rentals</title>
    <meta name="description" content="Best bus rental services in Pune for picnics, day trips, and group outings." />
    <meta name="keywords" content="Bus Service for Picnic in Pune, Picnic Bus Hire, Mini Bus Rental, Tempo Traveller for Picnic, Affordable Bus Rentals" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Service for Picnic in Pune | Affordable Rentals" />
    <meta property="og:description" content="Reliable bus rental services in Pune for picnics, day trips, and group outings." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-service-for-picnic-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/picnic-bus-service.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Service for Picnic in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-service-for-picnic-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Service for Picnic in Pune",
                    "description": "Best bus rental services in Pune for picnics, day trips, and group outings."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-14.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for "Bus Service for Picnic in Pune" - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busserviceforpicnic;