
import React from "react";
import '../css/Booking.css';
import { Helmet } from 'react-helmet';

const Booking = () => {
  const cabs = [
        {
          id: 1,
          name: "13 Seater Tempo Traveller",
          seats: "13",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/booking img-01.jpg" 
        },
        {
          id: 2,
          name: "17 Seater Tempo Traveller",
          seats: "17",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/booking img-02.jpg" 
        },
        {
          id: 3,
          name: "13 Seater Urbania Bus",
          seats: "13",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/booking img-03.jpg"
        },
        {
          id: 4,
          name: "17 Seater Urbania Bus",
          seats: "17",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/booking img-04.jpg" 
        },
        {
          id: 5,
          name: "14 Seater Tata Winger",
          seats: "14",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-11.jpg"
        },
        {
          id: 6,
          name: "20 Seater Ac / Non AC bus",
          seats: "20",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-10.jpg" 
        },
        {
          id: 7,
          name: "25 Seater Ac / Non AC bus",
          seats: "25",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-09.jpg" 
        },
        {
          id: 8,
          name: "32 Seater Ac / Non AC bus",
          seats: "32",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-08.jpg" 
        },
        {
          id: 9,
          name: "35 Seater Ac / Non AC bus",
          seats: "35",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-07.jpg" 
        },
        {
          id: 10,
          name: "40 Seater Ac / Non AC bus",
          seats: "40",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-06.jpg" 
        },
        {
          id: 11,
          name: "45 Seater Ac / Non AC bus",
          seats: "45",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-05.jpg" 
        },
        {
          id: 12,
          name: "50 Seater Ac / Non AC bus",
          seats: "50",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-04.jpg" 
        },
        {
          id: 13,
          name: "Luxury Bus",
          seats: "40",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-03.jpg" 
        },
        {
          id: 14,
          name: "Semi Luxury Bus",
          seats: "40",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-02.jpg" 
        },
        {
          id: 15,
          name: "Volvo Bus",
          seats: "40",
          local: "₹2500",
          perKm: "₹13",
          extraHr: "₹150",
          driverAllowance: "₹400",
          nightHalt: "₹250",
          pickupDrop: "₹3000",
          outstation: "₹3900",
          distance: "300km",
          image: "/img/Booking/Bokking 1-01.jpg" 
        }
      ];

  // Function to create the WhatsApp message
  const createWhatsAppMessage = (cabName) => {
    return `I want to book ${cabName}`;
  };

  return (
    <div>
      <Helmet>
        {/* Your existing Helmet meta tags */}
      </Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row container">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>Online Booking and hr By Hrs.</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      <div className="container d-flex justify-content-center align-items-center py-5">
        <div className="row w-100">
          {cabs.map((cab) => (
            <div key={cab.id} className="col-12 mb-4 d-flex justify-content-center">
              <div className="row booking-card">
                {/* Column for Image */}
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <img src={cab.image} alt={cab.name} className="booking-image img-fluid" />
                </div>

                <div className="col-md-4 d-flex flex-column justify-content-center">
                  {/* Cab details */}
                  <h3 className="darkcolor">{cab.name}</h3>
                  <p>Seater: {cab.seats}</p>
                  <p>Local: {cab.local}</p>
                  <p>Mumbai Pickup & Drop: {cab.pickupDrop}</p>
                  <p>Outstation ({cab.distance}): {cab.outstation}</p>
                </div>

                {/* Column for Pricing Details */}
                <div className="col-md-4 d-flex flex-column justify-content-center pricing-details">
                  <p>Per Km: {cab.perKm}</p>
                  <p>Extra Hr: {cab.extraHr}</p>
                  <p>Driver Allowance: {cab.driverAllowance}</p>
                  <p>Night Halt: {cab.nightHalt}</p>
                  <a 
                    href={`https://wa.me/918087847191?text=${encodeURIComponent(createWhatsAppMessage(cab.name))}`} 
                    className="book-now-btn mt-3" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Booking;
