import React from 'react';
import UniqueSection from './UniqueSection';
import Visionn from './Visionn';
import TestimonialSection from './TestimonialSection';
import { Helmet } from 'react-helmet';

const AboutSection = () => {
    return (
       <div>
        <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

        <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>About Us</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>

 <section className="about-section pt_100 pb_70">
            <div className="auto-container">
                <div className="sec-title centred mb_50">
                </div>
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-12">
                        <div className="content-bo">
                            <h3 className='darkcolor'>Who We Are</h3>
                            <p>
                            Since 2012, Shraddha Travel has been crafting unforgettable journeys, blending adventure, culture, and relaxation into one-of-a-kind experiences. Our expert team creates itineraries that showcase iconic landmarks and hidden treasures alike, allowing travelers to deeply connect with each destination. We’re proud to prioritize sustainable travel, working with local communities and eco-friendly partners to ensure a positive impact on the places we explore. Whether you’re seeking city excitement, coastal serenity, or cultural immersion, Shraddha Travel offers personalized adventures that make every journey memorable. Let us bring your travel dreams to life with care, passion, and expertise.
  </p>
                           <a className='darkcolor fw-bold'>Founder - Mr. BHIM AMRUTRAO KORE</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="image-box">
                            <img src="/img/tempooo.webp" alt="About Us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <UniqueSection/>
        <Visionn/>
        <TestimonialSection/>








       
       </div>
    );
};

export default AboutSection;
