import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const ContactInfo = () => {
    return (


<div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

<section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Contact Us</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>

<section className="contact-info-section pt_120 pb_90 centred">
            <div className="auto-container">
             
                <div className="row clearfix">



                <div className="col-lg-4 col-md-6 col-sm-12 info-column py-md5">
                        <div className="info-block-one ">
                            <div className="inner-box borderrr">
                                <div className="icon-box"><FontAwesomeIcon icon={faPhone} /></div>
                                <h3>Phone Number</h3>
                                <p>
                                    <a href="tel:+918087847191">+91 8087847191</a><br/>
                                    <a href="tel:+919552010777">+91 9552010777</a>
                                </p>
                            </div>
                        </div>
                    </div>




                   
                    <div className="col-lg-4 col-md-6 col-sm-12 info-column ">
                        <div className="info-block-one borderrr">
                            <div className="inner-box">
                                <div className="icon-box"><FontAwesomeIcon icon={faEnvelope} /></div>
                                <h3>Email Address</h3>
                                <p>
                                    <a href="mailto:booking@shraddhatravel.com">booking@shraddhatravel.com</a><br />
                                  
                                </p>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                        <div className="info-block-one ">
                            <div className="inner-box borderrr">
                                <div className="icon-box"><FontAwesomeIcon icon={faLocationArrow} /></div>
                                <h3>Our Location</h3>
                                <h6>Shraddha Travels</h6>
                                <p>Bhairab nivas lane number 8  <br />uttareshwar Nagar S/R nb.275 <br/> sathe vasti Lohegaon Pune,Maharashtra 411047</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section> 


        <section className="contact-map-section">
            <div className="auto-container">
                <div className="map-inner">
               
             

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.1013340646923!2d73.92061937423937!3d18.600478266730416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ab72838df57063d%3A0x4066ace9cdcea2f9!2sShraddha%20Travels!5e1!3m2!1sen!2sin!4v1733492249260!5m2!1sen!2sin" width="auto" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
            </div>
        </section>

</div>





    );
};

export default ContactInfo;
