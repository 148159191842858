
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinkharadipune() {



  const cardData =
  {
    keyword: ' Bus Rentals in Kharadi',
    heading: 'Shraddha Travels: Bus Rentals in Kharadi',
    headingDescription: 'Are you in Kharadi, Pune, and looking for a reliable and comfortable bus rental service? Shraddha Travels is your go-to solution for all types of bus rentals, offering a diverse range of vehicles tailored to meet various needs. Whether you’re planning a corporate event, a family outing, or a local sightseeing tour, we have the perfect bus for you. Discover our top bus rental options in Kharadi, Pune:',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. 32-Seater Luxury Bus",
            description: "For medium-sized groups that desire both comfort and elegance, our 32-seater luxury bus is an excellent choice. Equipped with plush seating, advanced air conditioning, and entertainment systems, this bus provides a serene atmosphere for corporate meetings, weddings, and special events. Enjoy amenities like charging points and ample luggage space, making it perfect for longer journeys."
        },
        {
            title: "2. 35-Seater Mini Bus",
            description: "The 35-seater mini bus from Shraddha Travels is designed for slightly larger groups who need ample space without sacrificing comfort. This bus is ideal for school trips, community events, or family gatherings, offering a smooth travel experience with comfortable seating and modern safety features. Enjoy the convenience of a reliable vehicle equipped to handle various travel needs."
        },
        {
            title: "3. 40-Seater Standard Bus",
            description: "If you’re organizing a tour or event for a bigger group, our 40-seater standard bus provides ample space while ensuring comfort. It's perfect for group excursions, city tours, or any occasion that requires moderate seating capacity. With its well-maintained interiors and efficient air conditioning, passengers can enjoy a pleasant ride while taking in the sights."
        },
        {
            title: "4. 45-Seater Executive Bus",
            description: "For those seeking a premium travel experience, our 45-seater executive bus offers unparalleled luxury and convenience. With ergonomic seating, onboard refreshments, and state-of-the-art amenities, this bus is perfect for business trips, team outings, and executive travel. It includes Wi-Fi access and presentation facilities, ensuring you can stay connected and productive on the road."
        },
        {
            title: "5. 50-Seater Deluxe Bus",
            description: "Our 50-seater deluxe bus is designed for large groups who need extensive seating combined with top-notch comfort. Whether it’s a corporate event, a family reunion, or a community function, this bus guarantees a pleasant journey. With spacious interiors, advanced entertainment options, and premium comfort features, it's the perfect choice for a memorable travel experience."
        },
        {
            title: "6. Kharadi Local Sightseeing Bus",
            description: "Explore Kharadi and its nearby attractions with our dedicated local sightseeing bus. Tailored for city tours, this bus covers major landmarks and scenic spots, making it an ideal choice for tourists and locals wanting to discover the region comfortably. Knowledgeable drivers can offer insights about the sites, enhancing your experience."
        },
        {
            title: "7. Kharadi Darshan Mini Bus",
            description: "Experience a comprehensive tour of Kharadi with our Darshan mini bus. Perfect for tourists and local residents, this service allows for organized exploration of the city’s key attractions in a comfortable setting. With a flexible itinerary, you can stop at various points of interest, making it an enriching experience for everyone."
        },
        {
            title: "8. Local Bus for Corporate Events",
            description: "For business functions and corporate events, our local buses are equipped to meet professional needs. Featuring comfortable seating and ample space for equipment, these buses ensure that your business travel is smooth and efficient. Onboard Wi-Fi and charging facilities allow for a productive journey, while our professional drivers maintain a punctual schedule."
        },
        {
            title: "9. Local Bus for School Trips",
            description: "Safety and comfort are paramount for school trips, and our buses are specifically designed to cater to school outings. With features that ensure a secure and enjoyable journey for students and teachers alike, these buses come with seat belts and trained drivers who prioritize safety, making your educational trips worry-free and enjoyable."
        },
        {
            title: "10. Local Bus for Family Gatherings",
            description: "Whether it’s a family reunion, wedding, or any other social gathering, our local buses provide the comfort and convenience needed for a memorable event. Spacious interiors allow for easy movement and socializing, while modern amenities ensure that everyone travels in style. Enjoy a hassle-free journey with our family-friendly bus rentals tailored to your event's needs."
        }
    ],    
    services: [
        {
            name: "Urbania Bus on Rent in Kharadi",
            description: "Looking for a modern, comfortable bus for your local travel needs in Kharadi? The Urbania bus on rent offers spacious seating and contemporary amenities. Perfect for corporate outings, family gatherings, or local tours, the Urbania ensures a smooth and enjoyable ride. With features like air conditioning, charging ports, and entertainment systems, it's designed for a premium travel experience."
        },
        {
            name: "17 Seater Bus on Rent Per Km in Kharadi",
            description: "For smaller groups needing flexibility, the 17-seater bus on rent per km in Kharadi provides an ideal solution. With competitive pricing based on distance traveled, it’s a cost-effective choice for short trips, local events, and day outings. Enjoy the convenience of a reliable vehicle tailored for comfortable local travel."
        },
        {
            name: "Tempo Traveller on Rent in Kharadi",
            description: "The Tempo Traveller on rent in Kharadi is perfect for medium-sized groups seeking comfort and efficiency. Whether you're heading out for a business trip or a leisure tour, the Tempo Traveller offers ample space, comfortable seating, and modern amenities, making it an excellent choice for all your travel needs."
        },
        {
            name: "Bus on Rent in Wagholi Pune for Outstation",
            description: "Planning a trip outside of Pune? The bus on rent in Wagholi Pune for outstation travel ensures that your journey is comfortable and hassle-free. Ideal for group travel to destinations beyond Pune, these buses come equipped with everything you need for a pleasant trip, including reclining seats, air conditioning, and entertainment options."
        },
        {
            name: "Bus Hire in Wagholi for Picnic",
            description: "A day out with family or friends is always more enjoyable with the right transportation. The bus hire in Wagholi for picnic trips provides comfort and convenience, making your outdoor excursions and recreational activities stress-free and enjoyable. Spacious interiors allow for easy movement and socializing during your fun-filled day."
        },
        {
            name: "Wedding Bus on Rental in Wagholi",
            description: "Make your special day even more memorable with a wedding bus on rental in Wagholi. These buses offer elegant and comfortable transport for your wedding guests, ensuring they arrive at the venue in style and with ease. With features like plush seating and a professional driver, your guests will enjoy a smooth ride to celebrate your big day."
        },
        {
            name: "Bus On Rent For Wedding in Wagholi",
            description: "Looking for a tailored solution for your wedding transport needs? The bus on rent for wedding in Wagholi can accommodate large groups and is equipped to handle the specifics of wedding transportation, providing comfort and elegance on your big day. Our buses are designed to enhance the experience for you and your guests."
        },
        {
            name: "Urbania Bus on Rent in Wagholi",
            description: "For a modern and stylish transport solution, consider the Urbania bus on rent in Wagholi. With its sleek design and high-end features, it’s perfect for both corporate events and social gatherings. Enjoy spacious interiors and advanced amenities for a premium travel experience that impresses."
        },
        {
            name: "Force Urbania on Rent in Kharadi",
            description: "The Force Urbania on rent in Kharadi offers a robust and comfortable option for various transportation needs. Whether it’s for a corporate event, a family outing, or a local tour, the Force Urbania delivers reliability and comfort, ensuring that your group travels together seamlessly."
        },
        {
            name: "Bus Hire in Kharadi IT Park",
            description: "For businesses in Kharadi IT Park, bus hire services are tailored to corporate requirements. Providing reliable transportation for team outings, client meetings, and other corporate events, these buses ensure a smooth and professional travel experience, allowing your team to focus on what matters most."
        },
        {
            name: "14, 17, 20, 25 Seater Bus on Rent in Kharadi",
            description: "When you need a bus with specific seating capacity, the 14, 17, 20, and 25 seater bus on rent in Kharadi offers the flexibility to choose the right size for your group. Perfect for various events, including office trips, family gatherings, and local excursions, these options cater to diverse needs."
        },
        {
            name: "32, 35, 40, 45, 50 Seater Bus on Rent in Kharadi",
            description: "For larger groups, the 32, 35, 40, 45, and 50 seater buses on rent in Kharadi provide ample space and comfort. Ideal for school trips, corporate functions, and community events, these buses ensure everyone travels together comfortably, complete with modern amenities for a pleasant journey."
        },
        {
            name: "Bus Hire in Kharadi for Corporate Outing",
            description: "The bus hire in Kharadi for corporate outings offers professional and reliable transportation for your team. With comfortable seating and essential amenities, these buses are perfect for team-building activities, conferences, and business travel, ensuring a productive and enjoyable experience."
        },
        {
            name: "Bus Hire in Kharadi for Corporate Employees",
            description: "Shraddha Travels offers reliable bus hire in Kharadi for corporate employees. Our fleet includes a variety of buses suitable for corporate travel, ensuring that your employees travel comfortably to and from work, events, or seminars. With professional drivers and well-maintained buses, we provide a seamless and efficient transportation solution tailored to your corporate needs."
        },
        {
            name: "Luxury Bus Hire in Kharadi for Corporate Employees",
            description: "For a premium travel experience, consider our luxury bus hire in Kharadi for corporate employees. Shraddha Travels provides high-end buses equipped with modern amenities such as comfortable seating, Wi-Fi, and entertainment systems. Perfect for executive travel, client shuttles, and special corporate events, our luxury buses ensure a sophisticated and enjoyable journey for your team."
        },
        {
            name: "Tempo Traveller on Rent in Kharadi Wagholi",
            description: "Need a tempo traveller on rent in Kharadi Wagholi? Shraddha Travels offers well-maintained tempo travellers that are perfect for small group travel. Whether you’re planning a family outing, a corporate event, or a local trip, our tempo travellers provide a comfortable and reliable transportation solution with ample space and modern amenities."
        },
        {
            name: "Tempo Traveller Hire in Viman Nagar",
            description: "Shraddha Travels provides tempo traveller hire in Viman Nagar, ideal for group outings and local travel. Our tempo travellers are well-equipped to handle various group sizes and travel needs, ensuring a comfortable and hassle-free journey. Whether it's for a corporate event or a family trip, our service guarantees convenience and reliability."
        },
        {
            name: "Tempo Traveller Hire in Wadgaon Sheri",
            description: "For those in Wadgaon Sheri, Shraddha Travels offers tempo traveller hire services that cater to both small and large groups. Our tempo travellers are designed for comfort and convenience, making them an excellent choice for local excursions, business trips, and more. Enjoy a smooth and enjoyable ride with our professional drivers and well-maintained vehicles."
        },
        {
            name: "Tempo Traveller Hire in Chandan Nagar",
            description: "If you're looking for tempo traveller hire in Chandan Nagar, Shraddha Travels has you covered. We offer a range of tempo travellers that are perfect for various group sizes and travel purposes. From local trips to corporate events, our tempo travellers are equipped to provide a comfortable and reliable travel experience."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Urbania Bus on Rent in Kharadi', '- 17 Seater Bus on Rent Per Km in Kharadi'],
        ['- Tempo Traveller on Rent in Kharadi', '- Bus on Rent in Wagholi Pune for Outstation'],
        ['- Bus Hire in Wagholi for Picnic', '- Wedding Bus on Rental in Wagholi'],
        ['- Bus On Rent For Wedding in Wagholi', '- Urbania Bus on Rent in Wagholi'],
        ['- Force Urbania on Rent in Kharadi', '- Bus Hire in Kharadi IT Park'],
        ['- 14, 17, 20, 25 Seater Bus on Rent in Kharadi', '- 32, 35, 40, 45, 50 Seater Bus on Rent in Kharadi'],
        ['- Bus Hire in Kharadi for Corporate Outing', '- Force Urbania on Rent in Kharadi'],
        ['- Bus Hire in Kharadi for Corporate Employees', '- Luxury Bus Hire in Kharadi for Corporate Employees'],
        ['- Tempo Traveller on Rent in Kharadi Wagholi', '- Tempo Traveller Hire in Viman Nagar'],
        ['- Tempo Traveller Hire in Wadgaon Sheri', '- Tempo Traveller Hire in Chandan Nagar']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Bus on Rent in Kharadi, Pune?",
            WhyChoosedescription: "When it comes to renting a bus in Kharadi, Pune, Shraddha Travels stands out for several reasons. We are committed to providing a travel experience that is not only comfortable and reliable but also tailored to meet the unique needs of each customer."
        },
        {
            WhyChooseheading: "Diverse Fleet:",
            WhyChoosedescription: "We offer a wide range of buses to suit various needs, from compact 14-seater options to spacious 50-seater buses. Each vehicle is meticulously maintained to ensure safety and comfort. Whether you’re organizing a corporate event, a family outing, or a local tour, we have the right vehicle to accommodate your group size and travel requirements."
        },
        {
            WhyChooseheading: "Comfort and Luxury:",
            WhyChoosedescription: "Our buses are equipped with modern amenities, ensuring a comfortable and enjoyable journey. Features such as air conditioning, plush seating, ample legroom, and entertainment systems are standard in our fleet. We strive to make every trip pleasant and relaxing, allowing passengers to enjoy their travel experience to the fullest."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced drivers are not only skilled but also courteous and knowledgeable about the local area. They prioritize passenger safety and comfort, ensuring a smooth travel experience. Our drivers are trained to handle various road conditions and adhere to all safety regulations, providing peace of mind during your journey."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We provide competitive and transparent pricing with no hidden fees. Our rental rates are designed to fit various budgets while offering excellent value for money. We believe that quality service should be accessible, allowing everyone to enjoy a premium travel experience without breaking the bank."
        },
        {
            WhyChooseheading: "Flexible Booking Options:",
            WhyChoosedescription: "We offer flexible booking solutions to accommodate your schedule and itinerary. Whether you need a bus for a few hours or several days, we can tailor our services to meet your requirements. Our user-friendly booking process makes it easy to plan your travel, ensuring that your arrangements fit seamlessly into your schedule."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "At Shraddha Travels, we pride ourselves on reliability and customer satisfaction. Our booking process is straightforward, and our team is always available to assist with any inquiries or special requests. We understand the importance of punctuality, and our commitment to on-time service means you can trust us to be there when you need us."
        },
        {
            WhyChooseheading: "Hassle-Free Experience:",
            WhyChoosedescription: "From booking to the end of your journey, we ensure a seamless experience. Our well-maintained buses and efficient service mean you can focus on enjoying your trip without worrying about the details. We handle all aspects of your travel, from planning to execution, allowing you to relax and enjoy the journey with your group."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses are available for rent in Kharadi, Pune?",
        answer: "At Shraddha Travels, we offer a variety of buses, including 14-seater, 17-seater, 20-seater, 25-seater, 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. Each bus is equipped with modern amenities to suit different needs."
    },
    {
        question: "How can I book a bus with Shraddha Travels?",
        answer: "You can book a bus by contacting us directly via phone or through our online booking platform. Provide us with details such as the type of bus, rental duration, and any special requirements you may have."
    },
    {
        question: "Are the buses equipped with air conditioning?",
        answer: "Yes, all our buses are equipped with air conditioning to ensure a comfortable journey, regardless of the weather conditions."
    },
    {
        question: "What is the pricing structure for renting a bus in Kharadi?",
        answer: "Our pricing is competitive and based on factors such as the type of bus, rental duration, and distance traveled. We offer transparent rates with no hidden fees. For a detailed quote, please contact us directly."
    },
    {
        question: "Are drivers provided with the rental?",
        answer: "Yes, all our bus rentals come with professional drivers who are experienced, courteous, and knowledgeable about local routes. They ensure a safe and smooth travel experience."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, you can make changes to your booking based on availability and our policy. Please contact us as soon as possible to discuss any modifications to your reservation."
    },
    {
        question: "Is there a minimum rental duration for the buses?",
        answer: "Our minimum rental duration may vary depending on the type of bus and the specifics of your trip. Contact us for detailed information regarding rental durations."
    },
    {
        question: "Do you offer buses for outstation travel?",
        answer: "Yes, we offer buses for both local and outstation travel. Whether you’re planning a day trip or a longer journey, we can accommodate your travel needs."
    },
    {
        question: "Are there any additional charges for long-distance travel?",
        answer: "Additional charges may apply for long-distance travel, depending on the distance and duration of the trip. Our team will provide a detailed quote that includes all applicable fees."
    },
    {
        question: "What should I do if I have special requirements or requests?",
        answer: "If you have any special requirements or requests, please inform us at the time of booking. We will do our best to accommodate your needs and ensure a satisfactory experience."
    }
];



const testimonials = [
    {
        name: "Mr. Ravi Patel",
        text: "I recently organized a corporate event in Kharadi, Pune, and chose Shraddha Travels for our transportation needs. The service was impeccable from start to finish. We rented a 40-seater bus for the day, and it was spotlessly clean, comfortable, and well-maintained. The driver was punctual, professional, and had excellent knowledge of the area, ensuring a smooth and stress-free journey for our team. The booking process was straightforward, and the team at Shraddha Travels was incredibly responsive to all our queries. I highly recommend them for anyone needing reliable bus rental services in Kharadi!"
    },
    {
        name: "Miss Neha Sharma",
        text: "Our family reunion in Kharadi was made even more memorable thanks to Shraddha Travels. We rented a 35-seater bus for a day trip around Pune, and the entire experience was fantastic. The bus was spacious, comfortable, and equipped with all the amenities we needed. The driver was courteous and ensured that we reached all our destinations on time. The team at Shraddha Travels went above and beyond to accommodate our schedule and special requests. If you’re looking for top-notch bus rental services in Kharadi, look no further than Shraddha Travels!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "  Bus On Rent in Kharadi Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Kharadi Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Explore bus rental services in Kharadi Pune. Affordable options for corporate outings, weddings, and picnics." />
    <meta name="keywords" content="Bus on Rent in Kharadi, Urbania Bus Hire, Tempo Traveller Rent in Kharadi, Wedding Bus Rental, Corporate Bus Hire" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Kharadi Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Affordable bus rental services in Kharadi Pune for weddings, corporate outings, and picnics." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-on-rent-kharadi" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/bus-rent-kharadi.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus On Rent in Kharadi Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Kharadi, Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-on-rent-kharadi",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental in Kharadi",
                    "description": "Affordable and reliable bus rental services for weddings, corporate outings, and picnics in Kharadi Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-03.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Bus on Rent in Kharadi, Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinkharadipune;