
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seatet32onrent() {



  const cardData =
  {
    keyword: '32 Seater Bus Rent in Pune',
    heading: 'Shraddha Travels: 32 Seater Bus Rent in Pune',
    headingDescription: 'When planning group travel in Pune, whether for a corporate event, family function, school excursion, or tour, renting a 32-seater bus from Shraddha Travels is the ideal solution. Our fleet of buses offers comfort, safety, and convenience for your large group travel needs. Here is why our 32-seater bus rental service stands out from the rest:',
    top: 'Top Reasons to Rent a 32-Seater Bus from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Spacious Seating for Groups",
          description: "Our 32-seater buses are specifically designed to accommodate larger groups, making it easy for families, friends, or colleagues to travel together without the hassle of coordinating multiple vehicles. Enjoy the comfort of sitting side by side and sharing the journey, fostering camaraderie and making the travel experience more enjoyable."
        },
        {
          title: "2. Affordable Group Rentals",
          description: "Renting a single bus is a budget-friendly option for group travel. By choosing our 32-seater, you can save on expenses such as fuel, tolls, and parking fees that would accrue with multiple cars. This allows you to allocate more resources to your trip activities, whether it’s dining, sightseeing, or accommodation, maximizing your travel budget."
        },
        {
          title: "3. Comfortable Travel Experience",
          description: "Designed for comfort, our buses feature spacious interiors with cushioned seating and ample legroom. Whether it’s a short ride or a long journey, passengers can relax and enjoy the ride without feeling cramped. Additional features like adjustable headrests and large windows enhance the overall travel experience, allowing everyone to take in the views."
        },
        {
          title: "4. Professional Drivers",
          description: "Our fleet is managed by skilled and courteous drivers who prioritize your safety and comfort. With extensive knowledge of local routes and traffic patterns, they ensure you reach your destination efficiently and on time. Their professionalism includes not just safe driving but also a friendly attitude, making your journey pleasant and stress-free."
        },
        {
          title: "5. Flexible Itineraries",
          description: "We understand that each group has its own plans and preferences. Our rental service provides the flexibility to customize your itinerary. Whether you want to make sightseeing stops, explore hidden gems along the way, or adjust your schedule on the go, we work with you to create a travel plan that suits your needs perfectly."
        },
        {
          title: "6. Regular Maintenance and Safety Checks",
          description: "Safety is our top priority. Our buses undergo regular maintenance and thorough inspections to meet high safety standards, ensuring a reliable and secure travel experience. With well-maintained vehicles, you can travel with peace of mind, knowing that we are committed to your safety on every journey."
        },
        {
          title: "7. Onboard Amenities",
          description: "To enhance your travel experience, our buses are equipped with modern amenities, including air conditioning for comfort during warm weather, entertainment systems to keep passengers engaged, and charging ports to keep your devices powered throughout the journey. These features ensure a pleasant atmosphere, making your trip more enjoyable."
        },
        {
          title: "8. 24/7 Customer Support",
          description: "We believe in providing exceptional customer service. Our support team is available around the clock to assist you with any questions, changes, or special requests regarding your rental. Whether you need assistance before your trip or during your journey, we ensure a hassle-free experience with prompt responses to your needs."
        },
        {
          title: "9. Positive Reputation",
          description: "Shraddha Travels has established a strong reputation in Pune for reliable service and customer satisfaction. Many of our clients return for their travel needs, reflecting our commitment to providing an exceptional rental experience. Customer testimonials and repeat business highlight our dedication to quality and service excellence."
        },
        {
          title: "10. Easy Booking Process",
          description: "Our user-friendly online booking system simplifies the rental process, allowing you to reserve your 32-seater bus in just a few clicks. You can focus on planning your itinerary while we handle the logistics. Our streamlined process ensures that you can secure your booking quickly and efficiently, with all the necessary information at your fingertips."
        }
      ],      
      services: [
        {
          name: "32 Seater Bus Rent in Pune",
          description: "Looking for a reliable and comfortable 32-seater bus on rent in Pune? Shraddha Travels offers a range of spacious and well-maintained buses ideal for all kinds of group travel. Whether it's for a family outing, corporate event, wedding, or religious tours, our 32-seater bus services are designed to make your journey smooth and hassle-free. With competitive pricing, professional drivers, and luxurious interiors, we ensure a memorable travel experience for your group."
        },
        {
          name: "32 Seater Bus on Rent in Pune",
          description: "Shraddha Travels provides 32-seater buses on rent in Pune, perfect for large groups. Our buses are equipped with modern amenities such as air-conditioning, reclining seats, and entertainment systems. Whether you're traveling within the city or on long-distance trips, our 32-seater bus ensures comfort and convenience for all passengers."
        },
        {
          name: "32 Seater Bus Price in Pune",
          description: "Our 32-seater bus rent price in Pune is affordable and transparent. We offer competitive rates tailored to the type of journey, whether it's a local trip or an outstation tour. Contact us for the best pricing for your travel needs without compromising on quality and service."
        },
        {
          name: "32 Seater Bus Hire for Corporate Events",
          description: "For corporate events, conferences, and team outings, our 32-seater bus on hire is an excellent choice. Shraddha Travels offers punctual and professional transportation services, ensuring your team reaches the destination comfortably and on time. With spacious seating, ample legroom, and onboard entertainment, our buses create a perfect environment for group travel."
        },
        {
          name: "32 Seater Bus on Hire for Weddings in Pune",
          description: "Planning a wedding and need reliable transportation? Our 32-seater bus on hire for weddings in Pune is the ideal solution for transporting your guests safely and comfortably. Whether it's for a local wedding or a destination wedding in Mahabaleshwar or Lonavala, our buses provide spacious interiors and luxurious comfort for all guests."
        },
        {
          name: "32 Seater AC Bus on Hire",
          description: "Our 32-seater AC bus on hire ensures a cool and comfortable journey, even during the hottest months in Pune. With powerful air-conditioning systems, your group can enjoy a relaxed ride, whether you're traveling for a wedding, corporate event, or pilgrimage."
        },
        {
          name: "32 Seater Non-AC Bus on Hire",
          description: "Looking for a budget-friendly option? Our 32-seater non-AC bus on hire is perfect for those who want an economical yet comfortable travel solution. Ideal for short trips and cooler months, our non-AC buses provide spacious seating and ample storage for luggage at a lower cost."
        },
        {
          name: "32 Seater Bus Hire for Mahabaleshwar",
          description: "Planning a trip to Mahabaleshwar? Our 32-seater bus on rent for Mahabaleshwar is the perfect option for large groups. With comfortable seating, ample legroom, and a professional driver, you can enjoy the scenic journey to this beautiful hill station without any worries. Whether it's a family vacation or a group outing, our bus ensures a smooth and pleasant ride."
        },
        {
          name: "32 Seater Bus Hire for Ashtavinayak Darshan",
          description: "For pilgrims seeking a spiritual experience, Shraddha Travels offers 32-seater bus hire for Ashtavinayak Darshan. Our buses provide a convenient and comfortable way to visit the eight sacred temples of Lord Ganesha. Enjoy the journey with your family or group in a safe and comfortable environment."
        },
        {
          name: "32 Seater Bus Hire for 5 Jyotirlinga Darshan",
          description: "Shraddha Travels also offers 32-seater bus hire for 5 Jyotirlinga Darshan, making your religious tour more comfortable and memorable. Our spacious buses ensure you and your fellow travelers enjoy a peaceful journey, visiting the revered Jyotirlinga temples across Maharashtra."
        },
        {
          name: "Pune to Konkan Darshan 32 Seater Bus on Rent",
          description: "Shraddha Travels offers a reliable and comfortable Pune to Konkan Darshan 32-seater bus on rent, perfect for group tours. Our spacious and well-maintained buses provide a smooth journey for large groups looking to explore the scenic Konkan region. With luxurious seating, air-conditioning, and ample luggage space, we ensure a pleasant experience as you travel to beautiful destinations like Alibaug, Ratnagiri, and Ganpatipule."
        },
        {
          name: "32 Seater Bus on Rent in Hadapsar",
          description: "Looking for a 32-seater bus on rent in Hadapsar? Shraddha Travels offers a wide range of bus rental services in Hadapsar, Pune. Whether it's for a corporate outing, family function, or religious tour, our buses are equipped with modern amenities, including comfortable seats, air-conditioning, and entertainment systems, making group travel enjoyable and hassle-free."
        },
        {
          name: "32 Seater Bus on Rent in Pimpri Chinchwad",
          description: "Shraddha Travels provides 32-seater buses on rent in Pimpri Chinchwad for various group travel needs, including weddings, corporate events, and outstation tours. Our buses are driven by experienced and professional drivers who ensure a smooth and safe journey. With competitive pricing and excellent customer service, we are the top choice for bus rentals in Pimpri Chinchwad."
        },
        {
          name: "Pune to Bhimashankar 32 Seater Bus Hire",
          description: "For groups planning a pilgrimage to Bhimashankar, Shraddha Travels offers Pune to Bhimashankar 32-seater bus hire. Our buses are spacious, air-conditioned, and equipped with comfortable seating to make your spiritual journey enjoyable. Whether it’s a one-day trip or a multi-day excursion, we ensure your group travels in comfort and style."
        },
        {
          name: "Pune to Ashtavinayak 32 Seater Bus on Rent",
          description: "Experience the divine Ashtavinayak Darshan with Shraddha Travels' Pune to Ashtavinayak 32-seater bus on rent. Our well-maintained buses provide the perfect solution for large groups seeking a convenient and comfortable way to visit the eight revered Ganesha temples. With ample seating, air-conditioning, and professional drivers, we make your pilgrimage smooth and memorable."
        },
        {
          name: "32 Seater Bus on Rent in Kothrud",
          description: "If you're searching for a 32-seater bus on rent in Kothrud, Shraddha Travels offers the best bus rental services in the area. Our fleet of buses is perfect for corporate trips, weddings, family outings, and more. With competitive pricing and modern amenities, we provide reliable and efficient transportation for all your group travel needs in Kothrud."
        },
        {
          name: "32 Seater Bus for Corporate Office",
          description: "Shraddha Travels provides 32-seater buses for corporate office transportation needs. Whether it's for team outings, conferences, or daily office commutes, our buses are equipped with modern amenities like air-conditioning, comfortable seats, and entertainment systems to ensure your employees have a comfortable and convenient travel experience. We offer reliable and punctual services to meet the demands of corporate clients."
        },
        {
          name: "32 Seater Bus for Outstation from Pune",
          description: "Planning an outstation trip from Pune? Shraddha Travels offers 32-seater buses for outstation from Pune, ideal for group travel to destinations like Mahabaleshwar, Shirdi, Lonavala, and more. Our buses provide ample space, air-conditioning, and professional drivers who ensure a safe and enjoyable journey for your group, whether it’s for a family vacation or a corporate tour."
        },
        {
          name: "32 Seater Bus Rental Services in Pune",
          description: "Shraddha Travels is your go-to provider for 32-seater bus rental services in Pune. We cater to various group travel needs, including weddings, corporate events, school excursions, and religious tours. Our buses come with luxurious seating, air-conditioning, and ample storage space, ensuring a comfortable and stress-free travel experience. With competitive rates and exceptional customer service, we are the preferred choice for bus rentals in Pune."
        },
        {
          name: "32 Seater Bus Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 32 Seater Bus Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
      ],      
      tableData: [
        ['- 32 Seater Bus Rent in Pune', '- 32 Seater Bus on Rent in Pune'],
        ['- 32 Seater Bus Price in Pune', '- 32 Seater Bus On Hire'],
        ['- 32 Seater Bus on Hire for Wedding in Pune', '- 32 Seater Bus on Rent for Marriage'],
        ['- 32 Seater Bus on Rent for Corporate Events', '- 32 Seater Bus on Rent for Mahabaleshwar'],
        ['- 32 Seater AC Bus on Hire', '- 32 Seater Non-AC Bus on Hire'],
        ['- 32 Seater Bus Hire for Ashtavinayak Darshan', '- 32 Seater Bus Hire for 5 Jyotirlinga Darshan'],
        ['- Pune to Konkan Darshan 32 Seater Bus on Rent', '- 32 Seater Bus on Rent in Hadapsar'],
        ['- 32 Seater Bus on Rent in Pimpri Chinchwad', '- Pune to Bhimashankar 32 Seater Bus Hire'],
        ['- Pune to Ashtavinayak 32 Seater Bus on Rent', '- 32 Seater Bus on Rent in Kothrud'],
        ['- 32 Seater Bus for Corporate Office', '- 32 Seater Bus for Outstation from Pune'],
        ['- 32 Seater Bus Rental Services in Pune', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for 32-Seater Bus Rental in Pune?",
          WhyChoosedescription: "When it comes to organizing group travel in and around Pune, Shraddha Travels stands out as your premier choice for 32-seater bus rentals. Our commitment to excellence ensures that you experience a comfortable and reliable journey, making us the best option for your transportation needs. We focus on creating memorable experiences, ensuring that every trip is enjoyable and stress-free."
        },
        {
          WhyChooseheading: "Spacious and Comfortable Buses:",
          WhyChoosedescription: "Our 32-seater buses are designed to offer maximum comfort for all passengers. With ample legroom, plush seating, and air-conditioning, you and your group will enjoy a relaxed journey, whether it's a short trip within Pune or a longer journey to popular destinations. The interior is thoughtfully designed to provide a pleasant ambiance, making your travel experience truly enjoyable."
        },
        {
          WhyChooseheading: "Professional and Experienced Drivers:",
          WhyChoosedescription: "Safety and reliability are our top priorities. Our drivers are highly experienced and trained to ensure a smooth and secure travel experience. They are well-versed with local routes and traffic conditions, making your journey both efficient and pleasant. Our drivers are friendly and courteous, ready to assist you with any questions or needs during your trip."
        },
        {
          WhyChooseheading: "Well-Maintained Fleet:",
          WhyChoosedescription: "At Shraddha Travels, we take pride in maintaining our fleet to the highest standards. Our 32-seater buses are regularly serviced and undergo rigorous inspections to ensure they are in top condition, minimizing the risk of breakdowns. We believe that a well-maintained vehicle enhances the travel experience, providing peace of mind for all passengers."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "We offer competitive pricing for our 32-seater bus rentals without compromising on quality. Our transparent pricing structure ensures there are no hidden fees, so you know exactly what to expect. We aim to provide the best value for your money, allowing you to enjoy a premium travel experience without breaking the bank."
        },
        {
          WhyChooseheading: "Customizable Travel Solutions:",
          WhyChoosedescription: "Whether you need transportation for a corporate event, family gathering, school trip, or any other group travel needs, we provide customizable solutions tailored to your specific requirements. Our team is dedicated to accommodating your preferences and ensuring a seamless experience. From special requests to itinerary planning, we work closely with you to meet your expectations."
        },
        {
          WhyChooseheading: "Excellent Customer Service:",
          WhyChoosedescription: "From booking to the end of your journey, our customer service team is available to assist you every step of the way. We prioritize your needs and are committed to providing prompt and effective support to ensure a hassle-free experience. Our staff is knowledgeable and ready to answer any questions, ensuring that you feel valued and well taken care of."
        },
        {
          WhyChooseheading: "Punctual and Reliable:",
          WhyChoosedescription: "We understand the importance of time, especially when it comes to group travel. Our buses adhere to strict schedules to ensure timely departures and arrivals, helping you stay on track with your plans. We value your time and make it our mission to provide a reliable transportation service that you can count on, regardless of the occasion."
        }
      ]
      
  }


  const faqData = [
    {
      question: "What is included in the rental of a 32-seater bus?",
      answer: "Our 32-seater bus rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request, depending on the specific bus model."
    },
    {
      question: "How do I book a 32-seater bus with Shraddha Travels?",
      answer: "You can book a 32-seater bus by contacting us directly via phone or email, or by filling out our online booking form. Provide us with details such as your travel dates, pick-up and drop-off locations, and any special requirements."
    },
    {
      question: "Are there any additional charges I should be aware of?",
      answer: "Our pricing is transparent, with no hidden fees. However, additional charges may apply for extra services, such as extended travel hours or special requests. We will provide a detailed quote before finalizing your booking."
    },
    {
      question: "What is the cancellation policy for a 32-seater bus rental?",
      answer: "Our cancellation policy varies based on the timing of the cancellation. Typically, cancellations made well in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact our customer service for specific details regarding your booking."
    },
    {
      question: "Can I request a specific type of 32-seater bus?",
      answer: "Yes, you can request specific features or types of buses based on availability. We offer a range of options to suit different needs, including luxury and standard models."
    },
    {
      question: "Are the drivers experienced and licensed?",
      answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and are dedicated to providing a safe and comfortable travel experience."
    },
    {
      question: "Can I make changes to my booking after it is confirmed?",
      answer: "Yes, changes to your booking can be made, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
    },
    {
      question: "What should I do if I need assistance during my journey?",
      answer: "If you require any assistance during your journey, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth trip."
    },
    {
      question: "How far in advance should I book a 32-seater bus?",
      answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
    },
    {
      question: "Are there any restrictions on luggage or other items?",
      answer: "There are standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Suresh Patil",
      text: "I recently hired a 32-seater bus from Shraddha Travels for a family wedding in Pune, and the experience was outstanding! The bus was extremely comfortable with spacious seating and excellent air-conditioning. The driver was punctual, professional, and knew all the routes well. Shraddha Travels made sure our journey was smooth and stress-free. I would highly recommend their services to anyone looking for bus rental in Pune for events or group travel."
    },
    {
      name: "Miss. Anjali Deshmukh",
      text: "We booked a 32-seater bus from Shraddha Travels for a corporate outing, and we couldn't have been more satisfied with the service! The bus was clean, well-maintained, and had all the modern amenities we needed for a long journey. The staff was courteous, and the driver ensured we reached our destination safely and on time. If you're looking for reliable and comfortable bus rental services in Pune, Shraddha Travels is the best choice!"
    }
  ];
  


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "32 Seater Bus Rent in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 32 Seater Bus Rent in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Rent 32-seater buses in Pune for weddings, corporate events, and outstation trips. Competitive rates and reliable service!" />
    <meta name="keywords" content="32 seater bus rent in Pune, 32 seater bus on hire for wedding, corporate events, Pune to Mahabaleshwar, Ashtavinayak darshan bus rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 32 Seater Bus Rent in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Discover 32-seater bus rental options in Pune for various occasions, including weddings and corporate events. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/32-seater-bus-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/32-seater-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "32 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/32-seater-bus-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "32 Seater Bus",
                    "description": "Rent a comfortable 32-seater bus for weddings, corporate events, and outstation trips in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-11.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About 32-Seater Bus Rentals in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seatet32onrent;