
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Tempo14seater17seateroninpune() {



  const cardData =
  {
    keyword: ' 14, 17, 20, and 25-Seater Bus Rental in Pune',
    heading: 'Shraddha Travels: 14, 17, 20, and 25-Seater Bus Rental in Pune',
    headingDescription: 'When planning group travel in Pune, Shraddha Travels offers a range of bus options to suit different needs. Our 14, 17, 20, and 25-seater buses are perfect for various occasions, from corporate events to family outings and more. Here’s why our buses are the ideal choice for your travel needs:',
    top: 'Top Reasons to Rent 14, 17, 20, or 25-Seater Buses from Shraddha Travels in Pune',

    topPlaces: [
        {
            title: "1. Versatile Capacity Options",
            description: "Whether you need a 14-seater for a small family trip or a 25-seater for a larger group, we have the right bus size to accommodate your specific needs. This versatility ensures that everyone travels together comfortably, making group travel more convenient and enjoyable."
        },
        {
            title: "2. Cost-Effective Solution",
            description: "Renting a bus is often more economical than arranging multiple vehicles. Not only does this save money on fuel and parking, but it also simplifies logistics for group outings, allowing you to allocate your budget towards activities and experiences instead."
        },
        {
            title: "3. Comfortable and Spacious Interiors",
            description: "Our buses are designed for maximum comfort, featuring spacious seating and ample legroom. With a focus on passenger comfort, you can relax and enjoy the journey, whether it’s a short trip to a local attraction or a longer excursion to a scenic destination."
        },
        {
            title: "4. Professional and Experienced Drivers",
            description: "Our drivers are trained professionals dedicated to providing a safe and smooth travel experience. They possess extensive knowledge of local routes and traffic conditions, ensuring efficient transportation while you sit back and enjoy the ride."
        },
        {
            title: "5. Customizable Itineraries",
            description: "Renting a bus from us gives you the flexibility to create a personalized travel itinerary. You can plan your stops, adjust your route, and include detours to fit your group's preferences, ensuring a unique and tailored travel experience."
        },
        {
            title: "6. Regular Maintenance for Safety",
            description: "We prioritize passenger safety by ensuring our buses undergo regular maintenance and thorough safety checks. This commitment guarantees that our vehicles are in top condition, providing you peace of mind as you travel."
        },
        {
            title: "7. Modern Amenities",
            description: "Our 14, 17, 20, and 25-seater buses come equipped with essential amenities such as air conditioning, charging ports, and sometimes entertainment systems. These modern features enhance your travel experience, keeping passengers comfortable and entertained throughout the journey."
        },
        {
            title: "8. Easy Booking Process",
            description: "Our user-friendly online booking system allows you to reserve your bus quickly and efficiently. This streamlined process saves you time and effort, making planning your trip hassle-free and convenient."
        },
        {
            title: "9. 24/7 Customer Support",
            description: "Our dedicated customer service team is available around the clock to assist you with any inquiries or changes regarding your booking. This commitment to customer support ensures a hassle-free rental experience, no matter the time of day."
        },
        {
            title: "10. Trusted Reputation",
            description: "Shraddha Travels has built a strong reputation for reliability and customer satisfaction. Many clients return for their transportation needs, reflecting our commitment to excellent service and the trust we have earned in the industry."
        }
    ],    
    services: [
        {
            name: "14, 17, 20, 25 Seater Bus Hire in Pune",
            description: "Shraddha Travels offers flexible bus rental solutions in Pune with options ranging from 14-seater to 25-seater buses. Whether you need a bus for a small group or a larger gathering, our fleet is equipped to meet your requirements with comfort and style, ensuring a pleasant journey for all."
        },
        {
            name: "Pune to Shirdi Bus on Rent",
            description: "Travel to the holy town of Shirdi in comfort with our 14, 17, 20, and 25-seater buses. Our rental services ensure a pleasant journey with professional drivers, ample luggage space, and modern amenities, making your pilgrimage or sightseeing trip enjoyable and stress-free."
        },
        {
            name: "Pune to Mahabaleshwar Bus on Rent",
            description: "Explore the scenic beauty of Mahabaleshwar with our 14, 17, 20, and 25-seater buses. Perfect for family trips, corporate outings, or group tours, our buses provide a comfortable travel experience, allowing you to soak in the breathtaking views of this popular hill station."
        },
        {
            name: "Pune to Goa Bus on Rent",
            description: "Planning a trip to the beaches of Goa? Choose our 14, 17, 20, and 25-seater bus rental services for a hassle-free journey. Enjoy the coastal views and vibrant culture with our well-maintained buses, providing comfort and convenience for your travel needs."
        },
        {
            name: "Pune to Mumbai Bus on Rent",
            description: "For your trips between Pune and Mumbai, our 14, 17, 20, and 25-seater buses offer the perfect solution. Ideal for corporate events, group travel, or family visits, our buses ensure a smooth and enjoyable journey between these two major cities."
        },
        {
            name: "Pune to Ashtavinayak Darshan Bus on Rent",
            description: "Embark on a spiritual journey to the Ashtavinayak temples with our 14, 17, 20, and 25-seater buses. Our rental service provides a comfortable and convenient way to visit these sacred sites, complete with amenities to enhance your pilgrimage experience."
        },
        {
            name: "Pune to 5 Jyotirlinga Bus on Rent",
            description: "Travel to the revered Jyotirlinga shrines with our 14, 17, 20, and 25-seater buses. Perfect for large groups or religious tours, our buses offer a comfortable and efficient way to visit these significant temples dedicated to Lord Shiva."
        },
        {
            name: "Pune to 5 Shaktipeeth Bus on Rent",
            description: "Visit the sacred Shaktipeeths with ease by renting one of our 14, 17, 20, or 25-seater buses. Our rental service ensures a comfortable and secure journey to these important pilgrimage sites, making your spiritual trip memorable and hassle-free."
        },
        {
            name: "Pune to Aurangabad Bus on Rent",
            description: "Explore Aurangabad and its historical wonders with our 14, 17, 20, and 25-seater buses. Whether you’re visiting the Ajanta and Ellora Caves or other attractions, our buses provide the perfect solution for group travel, ensuring comfort and convenience throughout your trip."
        },
        {
            name: "14, 17, 20, 25 Seater Bus Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 14, 17, 20, 25 Seater Bus rentals in Pune. We ensure a smooth and enjoyable ride for all our customers, helping you create lasting memories on your travels."
        }
    ],    
    tableData: [
        ['- 14, 17, 20, 25 Seater Bus Hire in Pune', '- Pune to Shirdi 14, 17, 20, 25 Seater Bus on Rent'],
        ['- Pune to Mahabaleshwar 14, 17, 20, 25 Seater Bus on Rent', '- Pune to Goa 14, 17, 20, 25 Seater Bus on Rent'],
        ['- Pune to Mumbai 14, 17, 20, 25 Seater Bus on Rent', '- Pune to Ashtavinayak Darshan 14, 17, 20, 25 Seater Bus on Rent'],
        ['- Pune to 5 Jyotirlinga 14, 17, 20, 25 Seater Bus on Rent', '- Pune to 5 Sadeteen Shaktipeeth 14, 17, 20, 25 Seater Bus on Rent'],
        ['- Pune to Aurangabad 14, 17, 20, 25 Seater Bus on Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for 14, 17, 20, and 25 Seater Bus Rentals in Pune?",
            WhyChoosedescription: "When it comes to renting a bus in Pune, Shraddha Travels stands out as your trusted provider for a comfortable and reliable journey. We specialize in ensuring that your travel needs are met with utmost professionalism and care. Whether you're planning a family outing, a corporate event, or any group travel, our service is designed to deliver comfort, style, and satisfaction."
        },
        {
            WhyChooseheading: "Diverse Fleet of Buses:",
            WhyChoosedescription: "We provide a comprehensive range of bus sizes, including 14, 17, 20, and 25-seater options, ensuring we can accommodate different group sizes and requirements. Whether it's a small family trip or a large corporate gathering, our fleet is designed to meet your specific needs and provide a comfortable travel experience."
        },
        {
            WhyChooseheading: "Comfort and Quality:",
            WhyChoosedescription: "Our buses are equipped with comfortable seating, air-conditioning, and modern amenities like charging ports and entertainment systems. We prioritize passenger comfort to enhance your travel experience, allowing you to relax and enjoy the journey, whether it’s a short ride or a long excursion."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced and well-trained drivers are dedicated to providing safe, reliable transportation. They possess in-depth knowledge of local routes and traffic patterns, ensuring a smooth and timely journey. With their professionalism and courteous service, you can trust that you’re in capable hands."
        },
        {
            WhyChooseheading: "Well-Maintained Vehicles:",
            WhyChoosedescription: "We take pride in our fleet’s maintenance and safety standards. Each bus undergoes rigorous regular inspections and servicing to ensure it’s in excellent condition. This commitment to quality minimizes the risk of breakdowns, providing you with peace of mind during your travels."
        },
        {
            WhyChooseheading: "Flexible Booking Options:",
            WhyChoosedescription: "We understand that every trip is unique, which is why we offer flexible booking options to suit your schedule and needs. Whether you require a bus for just a few hours or several days, we can accommodate your request with ease, making your planning straightforward and stress-free."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "Our competitive rates offer excellent value for money without compromising on quality. We provide transparent pricing with no hidden fees, ensuring you know exactly what to expect. Our goal is to make group travel affordable for everyone."
        },
        {
            WhyChooseheading: "Customizable Travel Solutions:",
            WhyChoosedescription: "We tailor our services to fit your specific requirements, whether you need a bus for a corporate event, family gathering, school trip, or any other occasion. Our flexibility allows us to customize itineraries and services to ensure your travel experience is personalized and meets your expectations."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our dedicated customer service team is available to assist you throughout the booking process and beyond. We pride ourselves on our responsiveness and willingness to address any questions or concerns you might have. Your satisfaction is our top priority, and we’re here to ensure a smooth experience."
        },
        {
            WhyChooseheading: "Timely and Reliable:",
            WhyChoosedescription: "Punctuality is crucial, especially for group travel. We understand that delays can affect your plans, which is why we ensure that our buses adhere to strict schedules. You can rely on us to be on time, allowing you to focus on enjoying your journey."
        },
        {
            WhyChooseheading: "Hassle-Free Experience:",
            WhyChoosedescription: "From the moment you book until the end of your journey, we strive to make the entire process as smooth and hassle-free as possible. Our goal is to provide a seamless travel experience for you and your group, handling all the logistics so you can simply enjoy the ride."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses do you offer for rent?",
        answer: "We offer a range of buses, including 14-seater, 17-seater, 20-seater, and 25-seater options. Each bus is equipped with comfortable seating and air-conditioning."
    },
    {
        question: "How can I book a bus with Shraddha Travels?",
        answer: "You can book a bus by contacting us via phone, email, or through our online booking form. Provide details such as your travel dates, pick-up and drop-off locations, and any special requirements."
    },
    {
        question: "Are there any additional costs I should be aware of?",
        answer: "Our pricing is transparent with no hidden fees. However, additional charges may apply for extra services or extended travel hours. We will provide a detailed quote before finalizing your booking."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies based on how far in advance you cancel. Generally, cancellations made well in advance incur a nominal fee, while last-minute cancellations may involve higher charges. Please contact our customer service for specifics."
    },
    {
        question: "Can I request specific features or amenities?",
        answer: "Yes, you can request specific features or amenities based on availability. We offer various options to cater to different needs, including luxury and standard models."
    },
    {
        question: "Are the drivers experienced and licensed?",
        answer: "All our drivers are experienced, professionally trained, and hold valid licenses. They are familiar with local routes and committed to ensuring a safe and comfortable journey."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, changes can be made to your booking, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss modifications."
    },
    {
        question: "What should I do if I need assistance during my journey?",
        answer: "If you need assistance during your journey, please contact the driver or our customer service team immediately. We are dedicated to addressing any issues promptly."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "To ensure availability and secure your preferred bus, we recommend booking as early as possible. For peak seasons or special events, early booking is advisable to avoid any last-minute issues."
    },
    {
        question: "Are there any restrictions on luggage or other items?",
        answer: "There are standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking."
    }
];



const testimonials = [
    {
        name: "Mr. Ravi S.",
        text: "I recently booked a 25-seater bus from Shraddha Travels for our family reunion, and I couldn't be more satisfied with the service. From the moment we contacted them, their team was incredibly helpful and accommodating. The bus was spacious, clean, and comfortable, making our journey enjoyable. The driver was professional and friendly, ensuring we arrived at our destination on time and safely. I highly recommend Shraddha Travels for anyone looking for reliable and top-notch bus rental services in Pune."
    },
    {
        name: "Miss. Priya M.",
        text: "Our company needed a 20-seater bus for a corporate event, and Shraddha Travels delivered beyond expectations. The booking process was smooth, and the staff was very responsive to our needs. The bus itself was modern and well-maintained, with all the amenities we needed for a comfortable ride. The driver was punctual and handled the route efficiently, allowing us to focus on the event. If you're looking for dependable and high-quality bus rentals in Pune, Shraddha Travels is the way to go!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 14 17 20 25 Seater Bus on in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 14, 17, 20, 25 Seater Bus on Rent in Pune | Affordable Rentals</title>
    <meta name="description" content="Rent 14, 17, 20, and 25 seater buses in Pune for local trips, Shirdi, Mahabaleshwar, Goa, and more. Competitive rates!" />
    <meta name="keywords" content="14 seater bus hire Pune, 17 seater bus rental Pune, 20 seater bus on rent Pune, 25 seater bus Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 14, 17, 20, 25 Seater Bus on Rent in Pune | Affordable Rentals" />
    <meta property="og:description" content="Explore affordable bus rental services in Pune for Shirdi, Mahabaleshwar, and more. Book your bus today!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/14-17-20-25-seater-bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "14, 17, 20, 25 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/14-17-20-25-seater-bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "14, 17, 20, 25 Seater Bus Rental",
                    "description": "Affordable rental services for 14, 17, 20, and 25 seater buses in Pune for local and outstation trips."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-20.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About 14, 17, 20, and 25 Seater Bus Rentals in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Tempo14seater17seateroninpune;