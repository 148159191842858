import React from 'react';
import '../css/ServiceSection.css'; 
import { Helmet } from 'react-helmet';
const services = [
  {
    id: 1,
    imgSrc: '/img/service/Services images-01.jpg',
    title: 'Force Urbaina On Rent',
    description: 'Experience vibrant urban travel with Shraddha Travels. Every journey is exciting, and our exceptional service ensures seamless adventures.',
  },
  {
    id: 2,
    imgSrc: '/img/service/Services images-02.jpg',
    title: 'Tempo Traveller Booking',
    description: 'Travel in comfort and style with our Tempo Traveller booking, perfect for group adventures.',
  },
  {
    id: 3,
    imgSrc: '/img/service/Services images-03.jpg',
    title: 'Outstation Cab Services',
    description: 'Embark on your outstation journey with our premium Bus rentals crafted for comfort and convenience.',
  },
  {
    id: 4,
    imgSrc: '/img/service/Services images-04.jpg',
    title: 'Mini Bus On Rent',
    description: 'Seamless airport transfers with professional drivers and premium vehicles ensuring timely service.',
  },
  {
    id: 5,
    imgSrc: '/img/service/Services images-05.jpg',
    title: 'Bus On Rent',
    description: 'Ultimate group travel with our Bus on Rent service, combining comfort, convenience, and safety for every journey.',
  },
  {
    id: 6,
    imgSrc: '/img/service/Services images-06.jpg',
    title: 'Temple Tours and Pilgrimages',
    description: 'Explore sacred sites with our comprehensive temple tours, enhancing your spiritual experience.',
  },
  {
    id: 7,
    imgSrc: '/img/Keyword/Keyword 1-11.jpg',
    title: 'Bus on Rent for Events in Pune',
    description: 'Make your events in Pune memorable with our premium bus rental services, ensuring smooth and comfortable transportation.',
  },
  {
    id: 8,
    imgSrc: '/img/Keyword/Keyword 3-13.jpg',
    title: 'Pune to 5 Jyotirlinga Darshan Bus Hire',
    description: 'Embark on a spiritual journey with our bus hire service for the 5 Jyotirlinga Darshan from Pune, offering convenience and comfort.',
  },
  {
    id: 9,
    imgSrc: '/img/Keyword/Keyword 1-05.jpg',
    title: 'Bus Hire Services for Weddings in Pune',
    description: 'Ensure seamless wedding transportation with our reliable and luxurious bus hire services in Pune.',
  },
  {
    id: 10,
    imgSrc: '/img/Keyword/Keyword 1-10.jpg',
    title: 'Bus Services for Corporate Events in Pune',
    description: 'Streamline corporate event transportation with our professional bus services, offering punctuality and comfort.',
  },
  {
    id: 11,
    imgSrc: '/img/Keyword/Keyword 4-07.jpg',
    title: 'Pune to Shani Shingnapur Mini Bus on Rent',
    description: 'Visit Shani Shingnapur hassle-free with our mini bus rental service from Pune, tailored for small groups.',
  },
  {
    id: 12,
    imgSrc: '/img/Keyword/Keyword 2 -15.jpg',
    title: 'Bus Hire For Kedarnath Trip',
    description: 'Plan your Kedarnath trip with our bus hire services, ensuring a smooth and spiritually enriching journey.',
  },
];

const ServiceSection = () => {
  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row ">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Services</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
      <section className="service-section">
        <div className="container">
          <div className="row ">
            {services.map((service) => (
              <div key={service.id} className="col-12 col-md-6 col-lg-4">
                <div className="service-card">
                  <div className="service-image">
                    <img src={service.imgSrc} alt={service.title} className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                    {/* <button className="btn">Learn More</button> */}
                    <button className="btn" onClick={() => window.location.href = '/book-taxi'}>Learn More</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceSection;
