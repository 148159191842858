
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforpicnic() {



  const cardData =
  {
    keyword: ' Bus Hire for Picnic in Pune',
    heading: 'Shraddha Travels: Bus Hire for Picnic in Pune',
    headingDescription: 'Planning a picnic in Pune? Let Shraddha Travels make your outing stress-free and enjoyable with our top-notch bus hire services. We offer a diverse fleet of well-maintained and comfortable buses, perfect for group picnics and day trips around Pune. Our reliable and flexible bus hire options ensure that your journey to your favorite picnic spot is as enjoyable as the picnic itself.',
    top: 'Top Picnic Spots in Pune for Your Bus Hire ',

    topPlaces: [
        {
            title: "1. Lonavala",
            description: "A favorite spot for picnics, Lonavala offers stunning views and lush greenery. Key attractions include Bhushi Dam, Tiger’s Leap, and the serene Pawna Lake, making it ideal for nature lovers and adventure seekers. Enjoy scenic hikes, local eateries, and the vibrant flora and fauna that add to the charm of this hill station."
        },
        {
            title: "2. Kamshet",
            description: "Known for its picturesque landscapes and paragliding opportunities, Kamshet is perfect for a relaxing picnic. Enjoy the beautiful hills and scenic surroundings that make for a tranquil getaway. The area is also famous for its adventure sports, providing an adrenaline rush for thrill-seekers."
        },
        {
            title: "3. Mulshi Lake",
            description: "A tranquil retreat away from the city hustle, Mulshi Lake is perfect for a peaceful picnic by the water. The surrounding hills and the lake’s calm waters create a picturesque setting for relaxation. Visitors can indulge in boating, photography, and enjoying the sunset views that make for a memorable experience."
        },
        {
            title: "4. Pawna Lake",
            description: "This artificial reservoir is a great spot for a picnic, with opportunities for boating and camping. Enjoy a day surrounded by nature with panoramic views that enhance your outdoor experience. The area is dotted with several camping sites, making it perfect for an overnight adventure under the stars."
        },
        {
            title: "5. Lavasa",
            description: "A planned city with beautiful lakeside views and modern amenities, Lavasa is ideal for a day trip. Enjoy scenic walks, outdoor activities, and a variety of dining options for a delightful outing. The well-maintained pathways and vibrant architecture provide a unique blend of nature and urban living."
        },
        {
            title: "6. Sinhagad Fort",
            description: "For history enthusiasts, Sinhagad Fort offers a blend of adventure and history. Hike up the fort to enjoy panoramic views of the surrounding landscape, perfect for an active picnic day. Explore the ancient ruins and learn about its historical significance while enjoying delicious local snacks available at the base."
        },
        {
            title: "7. Rajmachi Fort",
            description: "Located near Lonavala, Rajmachi Fort is great for trekking and picnicking. The fort offers spectacular views and is a popular spot for adventure enthusiasts looking for a memorable experience. The trek to the fort is rewarding, with lush greenery and scenic landscapes along the way."
        },
        {
            title: "8. Tungarli Lake",
            description: "This serene lake near Lonavala is a peaceful picnic spot surrounded by hills and greenery, ideal for a relaxed day out in nature. Enjoy leisurely walks around the lake, photography, and birdwatching, making it a perfect escape from the city's hustle."
        },
        {
            title: "9. Pune-Sohrabji Godrej Green Business Centre",
            description: "If you prefer an urban picnic spot with beautiful gardens and greenery, this center offers a peaceful environment for a day out. With well-maintained lawns and themed gardens, it’s an excellent place for families to enjoy a day in nature without leaving the city."
        },
        {
            title: "10. Khadakwasla Dam",
            description: "Located on the outskirts of Pune, Khadakwasla Dam is a popular spot for picnics with scenic views and a relaxing atmosphere. Ideal for a family outing, the dam area offers opportunities for photography, leisurely walks, and enjoying the picturesque sunset, creating a perfect end to your day."
        }
    ],    
    services: [
        {
            name: "Bus Rental Services in Pune",
            description: "Our bus rental services in Pune are designed to meet a wide array of needs. With a diverse fleet that includes luxury buses, minibuses, and tempo travellers, we cater to every occasion—from family outings to corporate events. Our competitive pricing and commitment to customer satisfaction ensure reliable and comfortable transportation solutions tailored just for you."
        },
        {
            name: "Pune to Mahabaleshwar Bus for Picnic",
            description: "Planning a picnic to Mahabaleshwar? Our buses are perfect for a comfortable and enjoyable trip. Equipped with ample seating, air conditioning, and entertainment options, our well-maintained vehicles ensure a smooth ride from Pune to this scenic destination. Enjoy the breathtaking views and fresh air while traveling with your friends and family."
        },
        {
            name: "Pune Darshan Bus Hire for Picnic",
            description: "Explore Pune and its surroundings with our Pune Darshan bus hire service. Ideal for picnics, this service provides a convenient and enjoyable way to visit local attractions such as historic forts, gardens, and cultural sites. Our knowledgeable drivers can also provide insights into the rich heritage of Pune, enhancing your experience."
        },
        {
            name: "Pune to Mysore/Ooty Bus Hire for Picnic",
            description: "For a memorable picnic to Mysore or Ooty, Shraddha Travels offers reliable bus hire services. Travel comfortably in our well-equipped buses, complete with modern amenities. Enjoy the lush greenery and beautiful landscapes as we take you to these popular hill stations, making your journey as enjoyable as the destination itself."
        },
        {
            name: "Bus Rental for Corporate Outing in Pune",
            description: "Our corporate bus rental services are tailored for business groups. Whether it's a team-building event, conference, or corporate outing, we provide professional and comfortable transport solutions. Our experienced drivers ensure punctuality, while our well-maintained buses offer a conducive environment for team discussions and networking."
        },
        {
            name: "Bus Hire for School Picnic in Pimpri Chinchwad",
            description: "Ensure a successful school picnic with our bus hire service in Pimpri Chinchwad. We offer safe and reliable transportation for students and staff, equipped with features like seat belts and spacious interiors. Our priority is to make the outing enjoyable and stress-free, allowing educators to focus on fun activities."
        },
        {
            name: "Bus Hire for School Picnic in Pune",
            description: "For school picnics throughout Pune, Shraddha Travels provides dedicated bus hire services. Our buses are designed to accommodate students comfortably, ensuring a pleasant journey to various picnic locations. We understand the importance of safety and fun, and our team is committed to delivering both."
        },
        {
            name: "Bus Hire for Outstation Picnic in Pune",
            description: "Planning an outstation picnic? Our bus hire services for outstation trips offer comfortable and efficient transportation to destinations beyond Pune. Enjoy a hassle-free journey with our reliable service, which includes experienced drivers who know the best routes and scenic spots along the way."
        },
        {
            name: "Party Minibus Hire Near Me",
            description: "Looking for a minibus for a party? Our party minibus hire service is ideal for group celebrations. With spacious seating, vibrant interiors, and excellent amenities, we ensure your party travels in style and comfort. Celebrate birthdays, anniversaries, or any special occasion with ease as we handle the transportation."
        },
        {
            name: "Pune to Shirdi Bus Hire",
            description: "Travel comfortably to Shirdi with our dedicated bus hire service. Perfect for religious trips and group travel, our buses offer a smooth ride and ample space for all passengers. Experience a stress-free journey as you focus on your spiritual journey, with our reliable service taking care of the logistics."
        },
        {
            name: "Pune to Aurangabad Bus on Rent",
            description: "For a trip from Pune to Aurangabad, our bus rental service provides reliable and comfortable transportation. Explore Aurangabad’s historical sites, including the Ajanta and Ellora Caves, with ease. Our experienced drivers ensure a safe and enjoyable ride, so you can relax and appreciate the cultural richness of the region."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "Shraddha Travels is your trusted partner for all bus rental needs in Pune. Our diverse fleet, including luxury buses, minibuses, and tempo travellers, ensures that we have the right vehicle for every occasion. Whether it’s a local tour or an outstation trip, we are dedicated to providing exceptional service tailored to your requirements."
        },
        {
            name: "Bus Hire for Picnic in Pune for Family",
            description: "For family picnics in Pune, our bus hire service offers comfort and convenience. Enjoy a spacious and comfortable ride with your loved ones as you head to your favorite picnic spots. With ample storage for picnic essentials and friendly drivers, your family outing will be memorable and hassle-free."
        },
        {
            name: "Tempo Traveller on Rent in Pune for Picnic",
            description: "Our tempo travellers are perfect for group picnics. With comfortable seating, air conditioning, and ample space, they offer a great way to travel with family or friends for a fun day out. Enjoy the flexibility of choosing your destination while we take care of your comfort and safety on the road."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune",
            description: "Experience a touch of luxury with our tempo traveller rental service. Ideal for picnics and special occasions, our luxury tempo travellers provide extra comfort and high-end amenities. Whether you're heading to a wedding or a family gathering, travel in style and make a lasting impression."
        },
        {
            name: "Urbania Hire for Picnic in Pune",
            description: "For a premium picnic experience, consider hiring an Urbania. This vehicle offers top-notch comfort and style, featuring spacious interiors and modern amenities. Make your trip to various picnic destinations a memorable one, complete with a relaxing atmosphere and delightful experiences along the way."
        },
        {
            name: "Bus Hire for Picnic in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Hire for Picnic in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today, and let us help you create wonderful memories."
        }
    ],    
    tableData: [
        ['- Bus Rental Services in Pune', '- Pune to Mahabaleshwar Bus for Picnic'],
        ['- Pune Darshan Bus Hire for Picnic', '- Pune to Mysore/Ooty Bus Hire for Picnic'],
        ['- Bus Rental for Corporate Outing in Pune', '- Bus Hire for School Picnic in Pimpri Chinchwad'],
        ['- Bus Hire for School Picnic in Pune', '- Bus Hire for Outstation Picnic in Pune'],
        ['- Party Minibus Hire Near Me', '- Party Minibus Hire Near Me'],
        ['- Pune to Shirdi Bus Hire', '- Pune to Aurangabad Bus on Rent'],
        ['- Shraddha Travels Pune On Rent', '- Bus Hire for Picnic in Pune for Family'],
        ['- Tempo Traveller on Rent in Pune for Picnic', '- Luxury Tempo Traveller on Rent in Pune'],
        ['- Urbania Hire for Picnic in Pune', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Bus Hire for Picnic in Pune?",
            WhyChoosedescription: "Planning a picnic can be a delightful experience, but the right transportation is key to ensuring everything goes smoothly. Shraddha Travels is here to make your picnic outing in Pune as enjoyable and hassle-free as possible. Our commitment to excellence ensures that every aspect of your journey is taken care of, from the moment you book until you return home. We understand the importance of creating memorable experiences, and our services are tailored to meet your unique needs, ensuring that your picnic is not just a trip, but a cherished memory."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Our fleet includes a range of well-maintained buses designed for comfort. From cozy mini buses to luxurious coaches, our vehicles are equipped with comfortable seating, air conditioning, and ample space for your picnic essentials. We prioritize your comfort, ensuring that your group travels together in style and ease, making the journey as enjoyable as the destination."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced drivers are not only skilled at navigating Pune’s routes but also dedicated to providing a safe and smooth journey. They are trained to handle various situations on the road, ensuring that you reach your picnic destination on time and in comfort. With a focus on safety and customer satisfaction, our drivers enhance your travel experience, allowing you to relax and enjoy the journey."
        },
        {
            WhyChooseheading: "Flexible Booking Options:",
            WhyChoosedescription: "We offer a variety of bus sizes and rental options to suit your specific needs. Whether you're planning a family picnic, a corporate outing, or a group adventure, we can accommodate your requirements. Our flexible booking process makes it easy for you to adjust your plans as needed, ensuring that your trip fits seamlessly into your schedule."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "Enjoy transparent and competitive pricing with no hidden fees. We provide detailed quotes that help you plan your budget effectively, ensuring you get excellent value for your money. Our pricing structure is designed to be fair and straightforward, so you can focus on enjoying your picnic without worrying about unexpected costs."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our customer service team is here to assist you throughout the booking process and address any queries you may have. We are committed to providing a seamless and stress-free experience from start to finish. Our knowledgeable staff is always ready to offer recommendations, provide information about destinations, and ensure that your needs are met."
        },
        {
            WhyChooseheading: "Wide Range of Destinations:",
            WhyChoosedescription: "We service various picnic spots around Pune, from scenic lakes and serene hills to historical forts and modern attractions. Let us handle the transportation while you focus on enjoying your day out. Our extensive knowledge of local attractions ensures that you can explore the best that Pune has to offer, making your picnic a fun-filled adventure."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for picnic hire?",
        answer: "We offer a range of buses, including mini buses, standard buses, and luxury coaches. Each bus is equipped with comfortable seating, air conditioning, and ample storage space for picnic supplies, ensuring a pleasant journey for all passengers."
    },
    {
        question: "How do I book a bus for a picnic in Pune?",
        answer: "You can book a bus by contacting us directly through our website or by phone. Provide details about your picnic plans, such as the date, number of passengers, and destination, and we will help you choose the best option for your needs."
    },
    {
        question: "Are there any additional charges besides the quoted price?",
        answer: "Our pricing is transparent and includes all standard charges. We provide detailed quotes so you are aware of the total cost upfront. Any additional services or extra hours may incur additional charges, which will be communicated to you in advance to avoid surprises."
    },
    {
        question: "Can I make changes to my booking after it has been confirmed?",
        answer: "Yes, we allow changes to your booking based on availability. Please contact us as soon as possible to discuss any modifications to your itinerary or bus requirements, and we will do our best to accommodate your requests."
    },
    {
        question: "What if there is an emergency or delay on the day of the picnic?",
        answer: "Our drivers are trained to handle unforeseen circumstances and will ensure your safety and comfort. In case of emergencies or delays, please contact us immediately, and we will assist you promptly with any necessary arrangements."
    },
    {
        question: "Is it possible to hire a bus for a one-day picnic trip?",
        answer: "Yes, we offer flexible rental options, including one-day hires. Whether you need the bus for a few hours or a full day, we can accommodate your schedule to ensure your picnic is enjoyable and stress-free."
    },
    {
        question: "How early should I book a bus for a picnic?",
        answer: "We recommend booking your bus at least a few weeks in advance to ensure availability, especially during peak seasons or popular picnic dates when demand can be high."
    },
    {
        question: "Can I request specific amenities or services on the bus?",
        answer: "Yes, you can request specific amenities or services when making your booking. We will do our best to accommodate your preferences, such as extra luggage space, refreshments, or entertainment options to enhance your picnic experience."
    },
    {
        question: "Are your buses equipped with safety features?",
        answer: "Absolutely. Our buses are regularly maintained and equipped with safety features such as seat belts, first aid kits, and fire extinguishers to ensure a safe journey for all passengers."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies depending on the booking conditions. Please refer to our terms and conditions or contact us for detailed information regarding cancellations and refunds, ensuring you are fully informed before making a booking."
    }
];



const testimonials = [
    {
        name: "Mr. Anil Deshmukh",
        text: "We had an incredible time on our family picnic thanks to Shraddha Travels! The bus we hired was spacious, clean, and perfect for our group. The driver was friendly and punctual, making sure we had everything we needed for a smooth journey. The comfort and convenience provided by Shraddha Travels made our outing truly enjoyable. Highly recommend their bus hire service for anyone planning a picnic in Pune!"
    },
    {
        name: "Miss Rina Patel",
        text: "Our school picnic was a huge success, thanks to Shraddha Travels. The bus we rented was well-maintained and comfortable, and the service was impeccable. The driver was very accommodating and made sure we reached our destination on time. The entire experience with Shraddha Travels was seamless and stress-free. For reliable and efficient bus hire for picnics in Pune, they are the best choice!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Hire for Picnic in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Picnic in Pune | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Explore reliable bus rental services in Pune for picnics, including trips to Mahabaleshwar, Ooty, and more. Perfect for families and groups." />
    <meta name="keywords" content="Bus Hire for Picnic in Pune, Pune to Mahabaleshwar Bus Hire, Corporate Outing Bus Rental, School Picnic Bus Hire, Tempo Traveller on Rent, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Picnic in Pune | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Discover our bus rental services in Pune for family picnics, school outings, and corporate events. Various options available for all group sizes." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-hire-picnic-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/picnic-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire for Picnic in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-hire-picnic-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services for Picnics in Pune",
                    "description": "Affordable bus rental services for picnics in Pune, including trips to Mahabaleshwar, Ooty, and more. Ideal for families and groups."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-14.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) – Bus Hire for Picnic in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforpicnic;