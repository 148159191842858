
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinhinjewadi() {



  const cardData =
  {
    keyword: ' Buses on Rent in Hinjewadi',
    heading: 'Shraddha Travels: Buses on Rent in Hinjewadi',
    headingDescription: 'When you need reliable and comfortable transportation in Hinjewadi, Pune, look no further than Shraddha Travels. Our diverse fleet of buses is designed to cater to various needs, from corporate events to family outings. Discover our top bus rental options in Hinjewadi that promise a seamless and enjoyable travel experience:',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. 32-Seater Luxury Bus",
            description: "Ideal for medium-sized groups, our 32-seater luxury bus offers a high level of comfort with plush seating, air conditioning, and onboard entertainment. It's perfect for corporate functions, weddings, and special events, providing ample space for passengers to relax. With a stylish interior and modern amenities, it ensures a premium travel experience, making every journey enjoyable."
        },
        {
            title: "2. 35-Seater Mini Bus",
            description: "Our 35-seater mini bus provides ample space for slightly larger groups. With comfortable seating, air conditioning, and generous legroom, it’s suitable for school trips, community events, and family gatherings. The bus is designed to ensure a smooth ride, with safety features that prioritize the well-being of passengers, making it an excellent choice for group outings."
        },
        {
            title: "3. 40-Seater Standard Bus",
            description: "For larger groups, the 40-seater standard bus offers a balance of space and comfort. This option is great for group excursions, city tours, or any occasion requiring more seating capacity without compromising on comfort. The bus comes with large windows for scenic views and a spacious interior that allows passengers to socialize or relax during the journey."
        },
        {
            title: "4. 45-Seater Executive Bus",
            description: "Our 45-seater executive bus is designed for those seeking a touch of luxury. With ergonomic seating, refreshments, and modern amenities, this bus is ideal for business trips, team outings, and high-profile events. It features additional storage for luggage and a premium sound system, ensuring a comfortable and sophisticated travel experience for all passengers."
        },
        {
            title: "5. 50-Seater Deluxe Bus",
            description: "For very large groups, our 50-seater deluxe bus offers maximum seating and comfort. Suitable for corporate events, family reunions, or large-scale tours, it provides a spacious and comfortable travel experience. The bus is equipped with the latest safety features, ample luggage space, and a well-maintained interior, ensuring that everyone travels in style and comfort."
        },
        {
            title: "6. Hinjewadi Local Sightseeing Bus",
            description: "Explore Hinjewadi and its surroundings with our local sightseeing bus. This service is perfect for tourists and locals who want to experience the area’s key attractions in comfort and style. The bus is designed for leisure travel, featuring comfortable seating and knowledgeable drivers who can provide insights about local landmarks, ensuring a memorable exploration."
        },
        {
            title: "7. Hinjewadi Darshan Mini Bus",
            description: "Our Hinjewadi Darshan mini bus is ideal for a comprehensive city tour. It provides a comfortable and organized way to visit local landmarks and attractions, making it perfect for guided tours and group excursions. With its compact size, it can easily navigate through city traffic, allowing for a more personalized sightseeing experience."
        },
        {
            title: "8. Corporate Outing Bus in Hinjewadi",
            description: "For business functions and corporate outings, our buses are equipped to meet professional needs. They offer ample space for employees and clients, ensuring a smooth and efficient travel experience. The buses are designed to facilitate team bonding and relaxation, making them perfect for off-site meetings or corporate retreats."
        },
        {
            title: "9. School Trip Bus in Hinjewadi",
            description: "Safety and comfort are our priorities for school trips. Our buses are specifically designed for educational outings, providing a secure and enjoyable journey for students and teachers. Equipped with safety belts, first aid kits, and trained drivers, our buses ensure a worry-free experience, allowing educators to focus on delivering engaging experiences."
        },
        {
            title: "10. Family Gathering Bus in Hinjewadi",
            description: "For family reunions, weddings, or other social gatherings, our family-friendly buses offer comfort and convenience. With spacious interiors, modern features, and enough room for everyone to sit together, they ensure a pleasant travel experience for all. Enjoy quality time with family as you travel to your destination in comfort and style."
        }
    ],    
    services: [
        {
            name: "Bus on Rent Hire in Wakad",
            description: "For groups needing reliable transportation in Wakad, we offer a variety of bus rental options tailored to your needs. Whether you're planning a corporate event, family outing, or school trip, our buses provide comfortable and spacious seating, air conditioning, and modern amenities. Our experienced drivers ensure timely pick-ups and drop-offs, making your journey stress-free."
        },
        {
            name: "Bus on Rent in Hinjewadi for Corporate Outing",
            description: "Organize your corporate outings with ease using our specialized bus rental services in Hinjewadi. Our buses are designed for comfort and professionalism, making them ideal for team-building activities, client meetings, and corporate events. Enjoy features like onboard Wi-Fi, charging ports, and refreshments to enhance your travel experience while fostering team spirit."
        },
        {
            name: "Bus on Rent in Hinjewadi for Outstations",
            description: "Planning a trip to an outstation destination? Our buses in Hinjewadi are perfect for long-distance travel. With options ranging from standard to luxury, you can travel comfortably with ample legroom and storage for luggage. Our well-maintained vehicles ensure a safe journey, allowing you to focus on enjoying your destination without any travel worries."
        },
        {
            name: "Bus Hire for Wedding in Hinjewadi",
            description: "Make your wedding day special with our dedicated bus rental services. We provide stylish and spacious buses for wedding guests, ensuring everyone arrives comfortably and on time. Our team can work with you to create a custom transportation plan that accommodates your schedule and venue locations, making your wedding logistics seamless."
        },
        {
            name: "Bharat Benz 35-Seater Bus on Rent in Hinjewadi",
            description: "Experience top-notch comfort with our Bharat Benz 35-seater bus. Ideal for medium-sized groups, this bus offers modern amenities such as plush seating, climate control, and entertainment options. Whether it's a corporate retreat or a family gathering, you’ll appreciate the luxurious travel experience this vehicle provides, ensuring everyone travels in style."
        },
        {
            name: "Force Urbania Luxury Mini Bus on Rent in Hinjewadi",
            description: "Our Force Urbania luxury mini bus is perfect for those seeking a blend of luxury and functionality. With comfortable seating, high-end features, and ample space for luggage, it’s suitable for both corporate and personal events. Enjoy a premium travel experience with professional drivers who prioritize your safety and comfort throughout the journey."
        },
        {
            name: "Corporate Outing Bus Hire in Hinjewadi",
            description: "Ensure a smooth and enjoyable corporate outing with our specialized bus rental services. Our buses are equipped to meet professional needs, featuring comfortable seating, audiovisual equipment, and refreshments. This service is designed to facilitate networking and collaboration among your team while providing a relaxing atmosphere for travel."
        },
        {
            name: "Tempo Traveller Hire in Hinjewadi",
            description: "For flexible and comfortable group travel, our tempo travellers are an excellent choice. Ideal for both short and long trips, they provide ample space, comfortable seating, and essential amenities. Perfect for family trips, picnics, or corporate excursions, our vehicles are maintained to high standards to ensure a pleasant journey."
        },
        {
            name: "Mini Bus on Rent in Hinjewadi",
            description: "Our mini buses are perfect for small to medium-sized groups. Whether you’re planning a local tour, a special event, or a family gathering, our mini buses offer comfort and flexibility. Equipped with modern amenities and driven by experienced professionals, your group will enjoy a smooth and enjoyable travel experience."
        },
        {
            name: "Bus Hire for Marriage in Hinjewadi",
            description: "For weddings and other special occasions, our bus hire services ensure that your guests travel in comfort and style. We offer a range of buses to suit different needs and preferences, ensuring everyone can arrive at the venue together and on time. Our staff is dedicated to making the logistics of your big day as effortless as possible."
        },
        {
            name: "Bus Hire for Wedding Near Me in Pune",
            description: "Planning a wedding and need transportation for your guests? Our bus hire services are perfect for weddings across Pune. We offer a variety of buses to accommodate different group sizes, ensuring your guests travel comfortably. With customizable itineraries and professional drivers, we ensure a memorable experience for everyone involved."
        },
        {
            name: "14, 17, 25, 32-Seater Bus on Rent in Hinjewadi",
            description: "No matter the size of your group, we have the right bus for you in Hinjewadi. Choose from our 14-seater, 17-seater, 25-seater, and 32-seater buses to meet your travel needs, whether for local events, corporate outings, or family trips. Each bus is well-equipped with necessary amenities for a comfortable ride."
        },
        {
            name: "Bus on Rent in Hinjewadi Phase 1",
            description: "If you’re located in Hinjewadi Phase 1 and need a bus for any occasion, we offer a range of options to suit your requirements. Our services are designed to provide comfort and reliability for local and outstation travel. Our experienced drivers are knowledgeable about the area, ensuring you reach your destination safely and efficiently."
        },
        {
            name: "Bus on Rent in Hinjewadi Phase 2",
            description: "Our bus rental services extend to Hinjewadi Phase 2, offering flexible and convenient transportation options for events, corporate outings, and more. Enjoy a comfortable journey with our well-maintained fleet. Our commitment to customer satisfaction means you can rely on us for punctual service and a hassle-free experience."
        },
        {
            name: "Bus on Rent in Hinjewadi Phase 3",
            description: "For those in Hinjewadi Phase 3, we provide a variety of bus rental services to meet your needs. Whether you’re organizing a local trip or an outstation journey, our buses are equipped to deliver a smooth and pleasant experience. Each trip is managed by our professional drivers who prioritize your safety and comfort."
        },
        {
            name: "Bus on Rent Hire in Wakad",
            description: "Wakad is covered by our extensive bus rental services. Whether you need a bus for a corporate event, family outing, or any other occasion, we provide reliable and comfortable options. Our fleet is regularly serviced, ensuring safety and comfort on every journey, making us a trusted choice for your transportation needs."
        },
        {
            name: "Bus on Rent in Hinjewadi for Outstations",
            description: "For those traveling out of Pune, our Hinjewadi bus rental service offers comfortable and reliable options for outstation trips. Choose from various bus sizes to accommodate your group’s needs, and enjoy a worry-free travel experience with our skilled drivers and well-equipped vehicles."
        },
        {
            name: "Bus on Rent in Baner Aundh",
            description: "Our bus rental services in Baner Aundh cater to a range of needs, from corporate events to personal outings. We offer various bus sizes and types to ensure a comfortable and enjoyable travel experience. With our flexible booking options, planning your trip has never been easier."
        },
        {
            name: "Bus on Rent in Balewadi Pune",
            description: "In Balewadi Pune, we offer flexible and convenient bus rental options for all types of events and trips. Our well-maintained buses ensure a comfortable journey for all passengers, backed by our commitment to exceptional customer service and support."
        },
        {
            name: "Bus on Rent in Hinjewadi Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus on Rent in Hinjewadi. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Bus on Rent Hire in Wakad', '- Bus on Rent in Hinjewadi for Corporate Outing'],
        ['- Bus on Rent in Hinjewadi for Outstations', '- Bus Hire for Wedding in Hinjewadi'],
        ['- Bharat Benz 35-Seater Bus on Rent in Hinjewadi', '- Force Urbania Luxury Mini Bus on Rent in Hinjewadi'],
        ['- Corporate Outing Bus Hire in Hinjewadi', '- Tempo Traveller Hire in Hinjewadi'],
        ['- Mini Bus on Rent in Hinjewadi', '- Bus Hire for Marriage in Hinjewadi'],
        ['- Bus Hire for Wedding Near Me in Pune', '- 14, 17, 25, 32-Seater Bus on Rent in Hinjewadi'],
        ['- Bus on Rent in Hinjewadi Phase 1', '- Bus on Rent in Hinjewadi Phase 2'],
        ['- Bus on Rent in Hinjewadi Phase 3', '- Bus on Rent in Baner Aundh'],
        ['- Bus on Rent in Balewadi Pune', '- Bus on Rent in Hinjewadi for Outstations']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus on Rent in Hinjewadi?",
            WhyChoosedescription: "At Shraddha Travels, we understand that finding the right transportation solution is crucial for any event or trip. Our commitment to quality service and customer satisfaction makes us the preferred choice for bus rentals in Hinjewadi."
        },
        {
            WhyChooseheading: "Wide Range of Buses:",
            WhyChoosedescription: "We offer a diverse fleet of buses, including 14-seater, 17-seater, 25-seater, and 32-seater options. This variety allows us to cater to groups of all sizes, whether you need a small minibus for an intimate gathering or a larger bus for a corporate event or family reunion."
        },
        {
            WhyChooseheading: "Reliable and Comfortable:",
            WhyChoosedescription: "Our buses are well-maintained and equipped with modern amenities, ensuring a comfortable and pleasant journey for all passengers. Features like plush seating, air conditioning, and entertainment options make every ride enjoyable. Punctuality and reliability are our top priorities, so you can trust us to get you to your destination on time."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We provide competitive and transparent pricing for all our bus rental services. Our rates are designed to offer the best value for your money, allowing you to enjoy quality transportation without breaking the bank. Plus, there are no hidden costs—what you see is what you get."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced and professional drivers are well-versed with local routes and committed to providing a safe and smooth travel experience. They prioritize passenger comfort and safety and are trained to assist with any special requests or requirements, ensuring your journey is worry-free."
        },
        {
            WhyChooseheading: "Customizable Services:",
            WhyChoosedescription: "Whether you’re organizing a corporate outing, a family trip, or a local event, we offer customizable bus rental services to suit your specific needs. Our team works closely with you to understand your requirements, helping to create a tailored travel experience that meets your schedule and preferences."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with any queries or issues. Whether you have a question before your trip or need assistance during your journey, we are just a call away to ensure that your bus rental experience is hassle-free and enjoyable."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "Booking a bus with us is simple and straightforward. You can make reservations online through our user-friendly platform or by phone, and we offer flexible options to accommodate your schedule. Our efficient booking process allows you to focus on your plans rather than logistics."
        },
        {
            WhyChooseheading: "Safe and Hygienic:",
            WhyChoosedescription: "We prioritize the safety and hygiene of our vehicles. Each bus is thoroughly cleaned and sanitized before and after every trip, following strict health protocols to ensure a safe environment for all passengers. Your well-being is our top concern."
        },
        {
            WhyChooseheading: "Local Expertise:",
            WhyChoosedescription: "Being based in Hinjewadi, we possess extensive local knowledge and can offer valuable recommendations for your travel needs. Whether you need suggestions for the best routes, nearby attractions, or dining options, our team is here to help you make the most of your journey."
        },
        {
            WhyChooseheading: "Positive Reputation:",
            WhyChoosedescription: "Our long-standing reputation for excellent service and customer satisfaction speaks for itself. With numerous positive testimonials from satisfied clients, we have a track record of delivering exceptional bus rental experiences. Our commitment to quality has made us a trusted name in transportation."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses do you offer for rent in Hinjewadi?",
        answer: "We offer a range of buses including 14-seater, 17-seater, 25-seater, and 32-seater options. Our fleet also includes luxury and executive buses for more specialized needs."
    },
    {
        question: "How can I book a bus with Shraddha Travels?",
        answer: "You can book a bus online through our website or by calling our customer support team. We offer flexible booking options to accommodate your schedule."
    },
    {
        question: "What is the pricing for bus rentals in Hinjewadi?",
        answer: "Our pricing is competitive and depends on factors such as the type of bus, duration of the rental, and distance traveled. We provide transparent pricing with no hidden charges."
    },
    {
        question: "Are your buses equipped with amenities?",
        answer: "Yes, our buses come equipped with modern amenities such as air conditioning, comfortable seating, and entertainment options. Specific amenities may vary depending on the bus type."
    },
    {
        question: "How do you ensure the safety and hygiene of your buses?",
        answer: "We maintain high standards of safety and hygiene. Our buses are regularly cleaned and sanitized, and our drivers follow strict safety protocols to ensure a safe travel experience."
    },
    {
        question: "Can I make changes to my booking after it has been confirmed?",
        answer: "Yes, we offer flexibility with bookings. If you need to make changes, please contact our customer support team as soon as possible, and we will assist you with the necessary adjustments."
    },
    {
        question: "What should I do if there is a problem during the trip?",
        answer: "If you encounter any issues during your trip, please inform the driver immediately. You can also contact our 24/7 customer support team for assistance."
    },
    {
        question: "Are your drivers experienced and professional?",
        answer: "Yes, all our drivers are highly experienced and professional. They are well-trained to ensure a safe and pleasant journey for all passengers."
    },
    {
        question: "Do you provide bus rental services for outstation trips from Hinjewadi?",
        answer: "Yes, we offer bus rental services for outstation trips from Hinjewadi. We can accommodate various distances and destinations as per your requirements."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "We recommend booking your bus as early as possible to secure availability, especially for peak seasons or special events. However, we do accept last-minute bookings subject to availability."
    }
];



const testimonials = [
    {
        name: "Miss. Neha Patil",
        text: "I recently hired a bus from Shraddha Travels for a family outing to Sinhagad Fort, and I couldn’t have been happier! The bus was clean, comfortable, and our driver was very friendly and knowledgeable about the area. It made our trip so much more enjoyable. I highly recommend Shraddha Travels for any bus rental needs!"
    },
    {
        name: "Mr. Amit Deshmukh",
        text: "We used Shraddha Travels for our company’s annual picnic, and it was a fantastic experience. The bus was spacious and well-maintained, and our driver was punctual and professional. I will definitely book again for our next corporate outing!"
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "  Bus on Rent in Hinjewadi Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus on Rent in Hinjewadi | Affordable Bus Rentals</title>
    <meta name="description" content="Reliable bus rental services in Hinjewadi for corporate outings, weddings, and outstation trips." />
    <meta name="keywords" content="Bus on Rent in Hinjewadi, Corporate Bus Hire, Wedding Bus Rental, Tempo Traveller Hire, Mini Bus on Rent in Hinjewadi" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus on Rent in Hinjewadi | Affordable Bus Rentals" />
    <meta property="og:description" content="Affordable bus rental services in Hinjewadi for corporate outings, weddings, and outstation trips." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-on-rent-hinjewadi" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/bus-rent-hinjewadi.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus on Rent in Hinjewadi",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Hinjewadi, Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-on-rent-hinjewadi",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental in Hinjewadi",
                    "description": "Affordable and reliable bus rental services for corporate outings, weddings, and outstation trips in Hinjewadi Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-04.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus on Rent in Hinjewadi – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinhinjewadi;