
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater50onrent() {



  const cardData =
  {
    keyword: ' 50 Seater Bus on Rent in Pune',
    heading: 'Shraddha Travels: 50 Seater Bus on Rent in Pune',
    headingDescription: 'When it comes to large group travel in Pune, Shraddha Travels is your top choice for 50-seater bus rentals. Whether you are planning a corporate event, a school excursion, a family reunion, or a wedding, our 50-seater buses offer the perfect blend of comfort, convenience, and reliability. Here’s why our 50-seater buses stand out and what features make them the ideal choice for your group travel needs.',
    top: 'Top Reasons to Rent a 50-Seater Bus from Shraddha Travels in Pune',

    topPlaces : [
        {
          title: "1. Ideal for Large Groups",
          description: "Our 50-seater bus is specifically designed to accommodate substantial groups, making it perfect for corporate events, school trips, family gatherings, and tourist excursions. Everyone can travel together comfortably, enhancing the group experience. This fosters better interaction and enjoyment among group members, making every journey memorable."
        },
        {
          title: "2. Cost-Effective Travel Option",
          description: "Renting a single 50-seater bus is often more economical than coordinating multiple vehicles. This choice minimizes costs related to fuel, tolls, and parking, allowing you to allocate more resources to your trip activities. Plus, you avoid the hassle of managing several cars and drivers, streamlining your travel plans."
        },
        {
          title: "3. Exceptional Comfort",
          description: "With spacious seating and ample legroom, our buses ensure that passengers enjoy a comfortable ride, whether you're on a short local journey or a longer trip across the state. The ergonomic design of the seats helps prevent fatigue, making your travel experience enjoyable from start to finish."
        },
        {
          title: "4. Skilled and Professional Drivers",
          description: "Our experienced drivers are trained to prioritize safety and comfort. They are familiar with local routes and traffic conditions, ensuring that your journey is smooth and efficient. Their professionalism extends beyond driving; they also provide assistance with luggage and any travel-related queries."
        },
        {
          title: "5. Customizable Itineraries",
          description: "We offer the flexibility to tailor your travel itinerary to fit your group's specific needs. Whether you want to schedule sightseeing stops or adjust your plans on the fly, we can accommodate your requests. This personalized approach allows for a more engaging and fulfilling travel experience."
        },
        {
          title: "6. Stringent Safety Standards",
          description: "Safety is paramount. Our 50-seater buses undergo regular maintenance and thorough safety inspections to ensure they are in optimal condition for your journey, giving you peace of mind. We adhere to all safety regulations and guidelines, ensuring a worry-free ride for all passengers."
        },
        {
          title: "7. Modern Amenities Onboard",
          description: "Our buses are equipped with essential amenities such as air conditioning, entertainment systems, and charging ports, making your travel experience both enjoyable and convenient. Passengers can relax, stay cool, and enjoy their favorite movies or music during the ride."
        },
        {
          title: "8. 24/7 Customer Support",
          description: "Our dedicated customer service team is always available to assist with any inquiries, changes, or special requests regarding your booking, ensuring a seamless rental experience. Whether it's last-minute adjustments or questions about the itinerary, we're here to help around the clock."
        },
        {
          title: "9. Reputation for Reliability",
          description: "Shraddha Travels is known for its dependable service and high customer satisfaction. Many clients return for their travel needs, reflecting our commitment to providing an exceptional rental experience. Our track record of reliability ensures that you can count on us for your transportation needs."
        },
        {
          title: "10. Easy and Efficient Booking Process",
          description: "Our straightforward online booking system makes reserving your 50-seater bus quick and hassle-free, allowing you to focus on planning your itinerary rather than worrying about logistics. With just a few clicks, you can secure your transportation and move on to planning the fun parts of your trip."
        }
      ],      
      services: [
        {
          name: "50 Seater Bus Per KM Rate in Pune",
          description: "Our pricing for 50-seater buses per kilometer in Pune is competitive and transparent. We offer flexible rates based on the distance traveled, ensuring you get the best value for your journey. Whether you’re planning a short trip within Pune or an extended journey, our per-kilometer rates cater to various budgets, allowing you to focus on enjoying your trip without worrying about hidden costs."
        },
        {
          name: "Bus on Rent in Pimpri Chinchwad",
          description: "If you're located in Pimpri Chinchwad and need a 50-seater bus on rent, we have you covered. Our fleet is readily available for both local and outstation travel, ensuring you have reliable transportation right from your doorstep. Experience convenience and comfort as we take care of all your travel needs."
        },
        {
          name: "50 Seater Bus on Rent in Hadapsar",
          description: "For those in Hadapsar looking for a 50-seater bus on rent, Shraddha Travels offers a range of options to meet your needs. From corporate events to family gatherings, our Hadapsar-based rental services ensure convenience and comfort, allowing you to enjoy your special occasions without the stress of transportation logistics."
        },
        {
          name: "50 Seater Coach Hire Prices",
          description: "Our 50-seater coach hire prices are designed to be competitive while delivering high-quality service. We provide detailed quotes based on your specific requirements, ensuring you understand all costs upfront without hidden charges. Our commitment to transparency means you can plan your budget confidently."
        },
        {
          name: "50 Seater Bus Rent Price",
          description: "Understanding the 50-seater bus rent price is essential for budgeting your travel. Our rates are structured to provide exceptional value for money, factoring in the duration of hire, distance traveled, and additional amenities required. We ensure that you get a fair deal tailored to your specific needs."
        },
        {
          name: "50 Seater Coach Bus Hire in Pune",
          description: "When it comes to 50-seater coach bus hire in Pune, Shraddha Travels offers premium service. Our coaches are equipped with modern amenities and maintained to the highest standards, ensuring a comfortable and enjoyable journey. Experience the perfect blend of luxury and functionality for your travel needs."
        },
        {
          name: "50 Seater Bus Hire in Kharadi",
          description: "Residents of Kharadi can benefit from our 50-seater bus hire services. Whether you need a bus for a corporate outing, a wedding, or a school event, we provide reliable and comfortable transportation solutions right in your area. Enjoy hassle-free travel that meets your specific requirements."
        },
        {
          name: "50 Seater AC Bus Per KM Rate",
          description: "Our 50-seater AC bus per kilometer rate offers a cool and comfortable travel experience at an affordable price. Enjoy the benefits of air conditioning during your trip, ensuring a pleasant atmosphere for all passengers, especially during the warmer months."
        },
        {
          name: "Hire 50 Seater Bus on Rent",
          description: "When you decide to hire a 50-seater bus on rent, Shraddha Travels ensures you get a well-maintained vehicle with a professional driver. Our services cater to a variety of travel needs, from local excursions to outstation trips, guaranteeing a smooth and enjoyable journey for all your group outings."
        },
        {
          name: "50 Seater Bus for Hire for Outstation from Pune",
          description: "If you’re planning an outstation trip from Pune, our 50-seater buses are perfect for long-distance travel. We offer comfortable and reliable options to ensure a smooth journey to your destination. Enjoy the scenic views and make lasting memories with your group as we take care of the travel details."
        },
        {
          name: "50 Seater Bus on Rent for Wedding in Pune",
          description: "Make your wedding transportation seamless with a 50-seater bus on rent for your wedding in Pune. Our buses are designed to accommodate large groups comfortably, ensuring your guests travel in style and comfort. Focus on your special day while we handle the logistics for you."
        },
        {
          name: "50 Seater Bus on Rent for Corporate Picnic",
          description: "For corporate picnics, our 50-seater buses provide ample space and comfort. Ensure a pleasant and enjoyable experience for your employees with our reliable and comfortable transportation options. We help make your corporate outings memorable and stress-free."
        },
        {
          name: "50 Seater Bus on Rent for Corporate Event",
          description: "When organizing a corporate event, our 50-seater buses are equipped to handle large groups with ease. We offer flexible rental options to suit your event’s requirements, ensuring smooth and efficient transport for your guests, allowing them to arrive relaxed and ready to participate."
        },
        {
          name: "50 Seater Bus on Rent in Viman Nagar",
          description: "Residents and businesses in Viman Nagar can enjoy our 50-seater bus on rent services. We offer convenient and reliable options for local and outstation travel, ensuring that your group reaches its destination comfortably and on time, without any hassle."
        },
        {
          name: "50 Seater Bus on Rent for Ashtavinayak Darshan",
          description: "For Ashtavinayak Darshan, our 50-seater buses provide comfortable transportation for your pilgrimage. Experience a smooth and hassle-free journey with our well-maintained buses and professional drivers, ensuring your spiritual journey is memorable and serene."
        },
        {
          name: "50 Seater Bus on Rent for Jyotirlinga Darshan",
          description: "Travel in comfort to the Jyotirlinga temples with our 50-seater bus on rent. We offer reliable and spacious transportation options to ensure a serene and enjoyable pilgrimage experience, allowing you to focus on your spiritual needs and reflections."
        },
        {
          name: "50 Seater Bus Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 50 Seater Bus Rent in Pune. We ensure a smooth and enjoyable ride for all our customers, with a dedicated team ready to assist you in planning your travel and answering any inquiries."
        }
      ],      
      tableData: [
        ['- 50-Seater Bus per KM Rate in Pune', '- Bus on Rent in Pimpri Chinchwad'],
        ['- 50 Seater Bus on Rent in Hadapsar', '- 50 Seater Coach Hire Prices'],
        ['- 50 Seater Bus Rent Price', '- 50 Seater Coach Bus Hire in Pune'],
        ['- 50 Seater Bus Hire in Kharadi', '- 50 Seater AC Bus per KM Rate'],
        ['- Hire 50 Seater Bus on Rent', '- 50 Seater Bus for Hire for Outstation from Pune'],
        ['- 50 Seater Bus on Rent for Outstation', '- 50 Seater Bus for Wedding in Pune'],
        ['- 50 Seater Bus on Rent for Corporate Picnic', '- 50 Seater Bus on Rent for Corporate Event'],
        ['- 50 Seater Bus on Rent in Viman Nagar', '- 50 Seater Bus on Rent for Ashtavinayak Darshan'],
        ['- 50 Seater Bus on Rent for Jyotirlinga Darshan', '']
      ],
      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for 50-Seater Bus Rentals in Pune?",
          WhyChoosedescription: "When it comes to 50-seater bus rentals in Pune, Shraddha Travels stands out for several compelling reasons. We offer a comprehensive range of services designed to meet the diverse needs of our clients, whether it's for corporate events, family gatherings, weddings, or outstation trips. Here's why you should choose us for your next journey:"
        },
        {
          WhyChooseheading: "Spacious and Comfortable Buses:",
          WhyChoosedescription: "Our 50-seater buses are designed with passenger comfort as a top priority. They come equipped with ample legroom, plush seating, and modern amenities, ensuring a pleasant journey for all passengers. Whether you're traveling within Pune or heading out for a longer trip, you'll experience a smooth and enjoyable ride."
        },
        {
          WhyChooseheading: "Reliable and Experienced Drivers:",
          WhyChoosedescription: "At Shraddha Travels, we pride ourselves on our professional driving team. Our drivers are highly experienced, licensed, and knowledgeable about both local and outstation routes. Their primary goal is to provide a safe and comfortable journey, ensuring timely arrivals and departures."
        },
        {
          WhyChooseheading: "Well-Maintained Fleet:",
          WhyChoosedescription: "We maintain our 50-seater buses to the highest standards. Each bus undergoes regular servicing and thorough inspections to ensure optimal performance and safety. Our commitment to vehicle maintenance helps minimize the risk of breakdowns and ensures a reliable travel experience."
        },
        {
          WhyChooseheading: "Transparent and Competitive Pricing:",
          WhyChoosedescription: "Our pricing for 50-seater bus rentals is both competitive and transparent. We provide clear and detailed quotes with no hidden fees, allowing you to budget effectively and make informed decisions. Our goal is to offer exceptional value for your investment."
        },
        {
          WhyChooseheading: "Customizable Rental Options:",
          WhyChoosedescription: "Whether you need a bus for a wedding, corporate event, school picnic, or an outstation journey, we offer customizable rental options to suit your specific needs. Our team is dedicated to accommodating your preferences and ensuring a seamless travel experience."
        },
        {
          WhyChooseheading: "Excellent Customer Support:",
          WhyChoosedescription: "From the initial booking to the end of your journey, our customer support team is available to assist you. We prioritize your needs and are committed to providing prompt, courteous, and effective support to address any questions or concerns you may have."
        },
        {
          WhyChooseheading: "Punctual and Reliable Service:",
          WhyChoosedescription: "We understand the importance of timeliness, especially for group travel. Our buses adhere to strict schedules to ensure punctual departures and arrivals. Our goal is to keep your plans on track and provide a hassle-free travel experience."
        },
        {
          WhyChooseheading: "Variety of Amenities:",
          WhyChoosedescription: "Depending on your needs, our 50-seater buses can be equipped with various amenities such as air conditioning, audio systems, and Wi-Fi. We offer a range of options to enhance your travel experience and ensure comfort throughout the journey."
        }
      ]
        }


        const faqData = [
            {
              question: "What is included in the rental of a 50-seater bus?",
              answer: "Our 50-seater bus rental includes comfortable seating, air conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request, depending on the bus model."
            },
            {
              question: "How do I book a 50-seater bus with Shraddha Travels?",
              answer: "Booking a 50-seater bus is simple. Contact us via phone, email, or through our online booking form. Provide details such as your travel dates, pick-up and drop-off locations, and any special requirements, and we’ll assist you with the reservation process."
            },
            {
              question: "Are there any additional charges I should be aware of?",
              answer: "Our pricing is transparent with no hidden fees. However, additional charges may apply for extra services such as extended travel hours or specific requests. We will provide a detailed quote before finalizing your booking."
            },
            {
              question: "What is the cancellation policy for a 50-seater bus rental?",
              answer: "Our cancellation policy depends on the timing of the cancellation. Generally, cancellations made well in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact our customer service for specific details regarding your booking."
            },
            {
              question: "Can I request a specific type of 50-seater bus?",
              answer: "Yes, you can request specific features or types of buses based on availability. We offer a range of options to suit different needs, including luxury and standard models."
            },
            {
              question: "Are the drivers experienced and licensed?",
              answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and dedicated to providing a safe and comfortable travel experience."
            },
            {
              question: "Can I make changes to my booking after it is confirmed?",
              answer: "Yes, changes to your booking can be made, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
            },
            {
              question: "What should I do if I need assistance during my journey?",
              answer: "If you require any assistance during your journey, contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth trip."
            },
            {
              question: "How far in advance should I book a 50-seater bus?",
              answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
            },
            {
              question: "Are there any restrictions on luggage or other items?",
              answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
            }
          ];
          


          const testimonials = [
            {
              name: "Mr. Rajesh Patel",
              text: "We recently used Shraddha Travels for our company’s annual corporate picnic, and I must say the experience was outstanding. We rented a 50-seater bus for our trip from Pune to a nearby resort, and everything was perfect. The bus was spacious, clean, and well-maintained. The driver was punctual, professional, and ensured a smooth ride for everyone. We appreciated the air-conditioned comfort, which made the journey pleasant despite the warm weather. Shraddha Travels provided excellent customer service from start to finish, and I highly recommend them for anyone looking for reliable bus rental services in Pune."
            },
            {
              name: "Ms. Aarti Deshmukh",
              text: "I had the pleasure of working with Shraddha Travels for my daughter’s wedding, where we needed a 50-seater bus to transport guests from Pune to the wedding venue. The service exceeded our expectations in every way. The bus was impeccably clean and equipped with all the necessary amenities to ensure our guests were comfortable. The driver was friendly, experienced, and made sure that the journey was as smooth as possible. The team at Shraddha Travels was very accommodating and helped with all our special requests. I’m grateful for their professionalism and would definitely choose them again for any future events."
            }
          ];
          


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 50-Seater Bus Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 50 Seater Bus on Rent in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Rent 50-seater buses in Pune for weddings, corporate events, and outstation trips. Competitive rates and reliable service!" />
    <meta name="keywords" content="50 seater bus on rent in Pune, 50 seater bus rental, bus hire for corporate events, outstation bus rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 50 Seater Bus on Rent in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Discover 50-seater bus rental options in Pune for weddings, corporate events, and outstation trips. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/50-seater-bus-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/50-seater-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "50 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/50-seater-bus-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "50 Seater Bus",
                    "description": "Rent a comfortable 50-seater bus for weddings, corporate events, and outstation trips in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-14.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About 50-Seater Bus Rentals in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater50onrent;