
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Luxurybusrentalinpune() {



  const cardData =
  {
    keyword: 'Luxury Bus Rental in Pune',
    heading: 'Luxury Bus Rental in Pune: Shraddha Travels',
    headingDescription: 'When it comes to organizing group travel in Pune, Shraddha Travels offers a premier selection of luxury bus rentals designed to meet your every need. Whether you’re planning a corporate event, family gathering, school trip, or a leisurely outing, our luxury buses are equipped to provide the highest level of comfort and style. Below is a comprehensive overview of our luxury bus rental services across various locations in Pune:',
    top: 'Top Reasons to Choose Luxury Bus Rental from Shraddha Travels in Pune',

    topPlaces: [
        {
            title: "1. Unmatched Comfort",
            description: "Our luxury buses are designed with plush seating, ample legroom, and high-quality interiors, ensuring a comfortable travel experience for all passengers. Ideal for long journeys, you can relax and enjoy the ride. With ergonomic designs and soft upholstery, our seats provide the ultimate relaxation, allowing you to arrive at your destination refreshed."
        },
        {
            title: "2. State-of-the-Art Amenities",
            description: "Equipped with modern amenities such as air conditioning, entertainment systems, Wi-Fi, and charging ports, our luxury buses provide everything you need for a pleasant journey. Passengers can enjoy the latest movies, music, or stay connected to the internet while traveling. The charging ports ensure your devices remain powered throughout the trip."
        },
        {
            title: "3. Spacious Seating Arrangements",
            description: "Our buses feature spacious layouts that allow for easy movement within the vehicle. Passengers can enjoy their personal space, making long trips more enjoyable. The wide aisle design ensures smooth accessibility for all travelers, whether you're getting up for a snack or stretching your legs during a break."
        },
        {
            title: "4. Professional Drivers",
            description: "Our experienced drivers are not only trained in safe driving practices but also provide a high level of customer service. They ensure a smooth and pleasant journey while being respectful and courteous. With extensive knowledge of the routes and local traffic conditions, our drivers prioritize passenger safety and comfort."
        },
        {
            title: "5. Customizable Travel Plans",
            description: "We understand that every group has different needs. Our luxury bus rental service allows you to customize your itinerary, including specific stops and destinations that suit your travel plans. Whether you need to make an unplanned detour or include extra sightseeing stops, we accommodate your requests to ensure a memorable trip."
        },
        {
            title: "6. Impeccable Safety Standards",
            description: "Your safety is our priority. Our luxury buses undergo regular maintenance and rigorous safety checks to ensure they are in top condition, giving you peace of mind during your travels. We adhere to all local and national safety regulations, ensuring that your journey is as safe as it is enjoyable."
        },
        {
            title: "7. Ideal for Special Occasions",
            description: "Whether it’s a wedding, corporate event, or a special celebration, our luxury bus rental service adds a touch of elegance to your transportation needs, making every journey memorable. We offer customizable décor options and catering services, allowing you to celebrate in style on the road."
        },
        {
            title: "8. 24/7 Customer Support",
            description: "Our dedicated customer service team is available around the clock to assist you with any inquiries or changes regarding your booking, ensuring a seamless rental experience. From last-minute changes to answering questions about your journey, our support staff is always ready to help."
        },
        {
            title: "9. Positive Customer Feedback",
            description: "Shraddha Travels is known for its commitment to quality and customer satisfaction. Many clients return to us for their travel needs, reflecting our dedication to providing exceptional service. Testimonials from our satisfied customers highlight our professionalism and the delightful experiences they had while traveling with us."
        },
        {
            title: "10. Efficient Booking Process",
            description: "Our user-friendly online booking system makes it easy to reserve your luxury bus quickly and efficiently, allowing you to focus on planning your trip without unnecessary stress. With secure payment options and instant confirmation, you can finalize your travel arrangements in just a few clicks."
        }
    ],    
     services : [
        {
          name: "Luxury Bus Rental in Pimpri Chinchwad",
          description: "For those in Pimpri Chinchwad, Shraddha Travels provides top-tier luxury bus rentals to enhance your group travel experience. Our buses are ideal for corporate functions, family events, or local excursions. Enjoy plush seating, advanced amenities, and a smooth ride that reflects our commitment to quality. With spacious interiors and an emphasis on comfort, you can relax and socialize during your journey."
        },
        {
          name: "Luxury Bus Rental in Kharadi",
          description: "Travel in style and comfort with our luxury buses available in Kharadi. Whether it’s for a corporate meeting, a family celebration, or any special event, our buses are designed to ensure you and your guests travel in utmost comfort and luxury. Equipped with modern features like Wi-Fi, entertainment systems, and refreshments, your journey will be enjoyable and stress-free."
        },
        {
          name: "Luxury Bus On Rent in Yerawada",
          description: "Our luxury bus rental service in Yerawada offers a premium travel experience for all types of group outings. Enjoy a journey filled with comfort, courtesy of our state-of-the-art buses that come with all the modern amenities needed for a pleasant trip. Our dedicated drivers ensure safety and punctuality, allowing you to focus on your event or outing."
        },
        {
          name: "Luxury Bus On Rent in Vishrantwadi",
          description: "In Vishrantwadi, Shraddha Travels offers luxury buses that combine elegance with practicality. Perfect for weddings, corporate events, or any large gathering, our buses are designed to provide a superior travel experience with a focus on comfort and convenience. With customizable itineraries, you can plan your route to suit your schedule."
        },
        {
          name: "14, 17, 20, 25 Seater Luxury Bus on Rent Pune",
          description: "Our range of luxury buses with seating capacities of 14, 17, 20, and 25 is ideal for smaller groups seeking comfort and sophistication. These buses are perfect for intimate events and local trips where a touch of luxury is desired. Each bus is equipped with high-quality interiors, plush seating, and excellent ventilation for a pleasant ride."
        },
        {
          name: "32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad",
          description: "For larger groups in Pimpri Chinchwad, our fleet includes luxury buses with capacities of 32, 35, 40, and 45 seats. These buses are suited for corporate outings, school trips, or family reunions, providing ample space and comfort for all passengers. Enjoy additional amenities such as onboard entertainment and refreshment options."
        },
        {
          name: "Luxury Bus On Rent in Hadapsar Pune",
          description: "If you’re in Hadapsar, Shraddha Travels offers luxury buses designed to deliver a first-class travel experience. Whether you’re attending a wedding, corporate event, or a special occasion, our buses ensure a journey marked by luxury and efficiency. Our professional drivers are trained to cater to your needs, ensuring a seamless travel experience."
        },
        {
          name: "Luxury Bus on Rent for Picnic in Pune",
          description: "Transform your picnic into an extraordinary experience with our luxury bus rentals. Our buses are equipped with all the necessary amenities to ensure a comfortable and enjoyable journey for your group. Enjoy spacious seating and the ability to bring along refreshments, making your outing memorable and fun."
        },
        {
          name: "Mini Luxury Bus Hire in Pune",
          description: "For smaller groups or more personalized gatherings, our mini luxury buses offer an elegant travel solution. Perfect for short trips or local travel, these buses combine luxury with practicality. With comfortable seating and modern amenities, they are ideal for private events or day trips."
        },
        {
          name: "Luxury Bus Rental in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Luxury Bus Rental in Pune. We ensure a smooth and enjoyable ride for all our customers. Our team is ready to assist you with any inquiries, helping you plan the perfect travel experience tailored to your needs."
        }
      ],      
      tableData: [
        ['- Luxury Bus Rental in Pimpri Chinchwad', '- Luxury bus rental in Kharadi'],
        ['- Luxury Bus On Rent in Yerawada, Pune', '- Luxury Bus On Rent in Vishrantwadi, Pune'],
        ['- 14, 17, 20, 25 Seater Luxury Bus On Rent Pune', '- 32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad'],
        ['- Luxury Bus On Rent in Hadapsar Pune', '- Luxury bus on rent for picnic in Pune'],
        ['- Mini Luxury Bus Hire in Pune', '']
      ],      
      whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Luxury Bus Rental in Pune?",
            WhyChoosedescription: "When it comes to luxury bus rentals in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Our commitment to quality service ensures that every trip is enjoyable, whether you're planning a corporate event, family gathering, or school trip. With a focus on safety, comfort, and customer satisfaction, we take pride in being a leading choice for group travel. Here's why Shraddha Travels stands out as the best choice for your luxury bus rental needs:"
        },
        {
            WhyChooseheading: "Spacious and Comfortable Buses:",
            WhyChoosedescription: "Our luxury buses are designed for maximum comfort, featuring spacious interiors, ample legroom, and plush seating. Each bus is equipped with air conditioning to maintain a pleasant atmosphere, ensuring that passengers can relax and enjoy the journey, even on longer trips. Whether you're heading out for a day trip or an extended journey, our buses provide the comfort you need."
        },
        {
            WhyChooseheading: "Professional and Experienced Drivers:",
            WhyChoosedescription: "Our drivers are not just skilled behind the wheel; they are trained to navigate various road conditions and traffic scenarios with ease. Their professionalism ensures that every journey is safe and efficient. They prioritize customer service, being friendly and accommodating while also maintaining a high standard of safety, making your travel experience seamless."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "At Shraddha Travels, we understand the importance of reliability in transportation. Our luxury buses undergo regular servicing and thorough inspections to ensure they are in top condition. This commitment to maintenance means you can trust that your vehicle will perform flawlessly throughout your trip, giving you peace of mind on the road."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We believe that luxury should be accessible. Our competitive pricing offers great value without compromising on quality. With a clear and transparent pricing structure, you can rest assured that there are no hidden fees, allowing you to plan your budget effectively for your group travel."
        },
        {
            WhyChooseheading: "Customizable Travel Solutions:",
            WhyChoosedescription: "Every group has unique travel needs, and we are here to accommodate them. Whether you require specific pick-up and drop-off points, special stops along the way, or tailored itineraries for events, our team works closely with you to create a customized travel plan that fits your requirements perfectly."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our customer service is dedicated to ensuring that every aspect of your booking and travel experience is smooth and enjoyable. From the moment you reach out for a quote to the completion of your journey, our team is available to assist with any questions or changes, making your experience hassle-free."
        },
        {
            WhyChooseheading: "Punctual and Reliable:",
            WhyChoosedescription: "Time is valuable, and we respect that. Shraddha Travels adheres to strict schedules to guarantee timely departures and arrivals. Our focus on punctuality helps you stay on track with your plans, whether it’s for an important meeting, event, or family gathering."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What is included in the rental of a luxury bus?",
        answer: "The rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request."
    },
    {
        question: "How do I book a luxury bus with Shraddha Travels?",
        answer: "You can book a luxury bus by contacting us via phone, email, or by filling out our online booking form with details of your travel dates, pick-up and drop-off locations, and any special requirements."
    },
    {
        question: "Are there any additional charges I should be aware of?",
        answer: "Our pricing is transparent, but additional charges may apply for extra services or special requests. We provide a detailed quote before finalizing your booking."
    },
    {
        question: "What is the cancellation policy for a luxury bus rental?",
        answer: "Cancellation policies vary based on timing. Generally, cancellations made well in advance incur a nominal fee, while last-minute cancellations might involve higher charges. Please contact our customer service for specific details."
    },
    {
        question: "Can I request a specific type of luxury bus?",
        answer: "Yes, you can request specific features or types of buses based on availability. We offer a range of options to suit different needs."
    },
    {
        question: "Are the drivers experienced and licensed?",
        answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and dedicated to providing a safe and comfortable travel experience."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, changes can be made subject to availability and applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
    },
    {
        question: "What should I do if I need assistance during my journey?",
        answer: "If you need any assistance during your journey, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly."
    },
    {
        question: "How far in advance should I book a luxury bus?",
        answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, early booking is advisable."
    },
    {
        question: "Are there any restrictions on luggage or other items?",
        answer: "Standard luggage allowances apply, but special arrangements can be made upon request. Please inform us of any specific needs when making your booking."
    }
];



const testimonials = [
    {
        name: "Mr. Anil Rao",
        text: "Our company recently hired a luxury bus from Shraddha Travels for a corporate event, and it exceeded all our expectations. The bus was top-notch, with plush seating, state-of-the-art amenities, and an impeccable interior. The onboard entertainment system kept everyone engaged throughout the journey, and the spacious seating made for a highly comfortable experience. The driver was professional and ensured that we arrived at our destination on time. Shraddha Travels truly delivered on their promise of luxury and comfort. I highly recommend their luxury bus rental services for any high-end corporate or personal events."
    },
    {
        name: "Miss. Priya Mehta",
        text: "I booked a luxury bus from Shraddha Travels for my wedding transportation needs, and it was an absolute delight. The bus was elegantly furnished with high-quality seating and decor, making it perfect for our special day. The attention to detail was evident, from the cleanliness to the amenities provided. Our guests were extremely impressed with the comfort and luxury of the ride. The service from Shraddha Travels was exceptional, from booking to the actual event. They made sure everything went smoothly, and I couldn’t have asked for a better experience. I highly recommend Shraddha Travels for anyone looking for luxury bus rental in Pune."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Luxury Bus Rental in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Luxury Bus Rental in Pune | Affordable Luxury Bus Rentals</title>
    <meta name="description" content="Discover luxury bus rental services in Pune for all occasions. Affordable rates for family trips, corporate events, and more!" />
    <meta name="keywords" content="Luxury bus rental Pune, luxury bus hire Pimpri Chinchwad, luxury bus on rent in Kharadi, luxury bus for picnic" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Luxury Bus Rental in Pune | Affordable Luxury Bus Rentals" />
    <meta property="og:description" content="Rent luxury buses in Pune for outings, corporate trips, and special events. Book now for the best rates!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/luxury-bus-rental-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/luxury-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Luxury Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/luxury-bus-rental-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Luxury Bus",
                    "description": "Rent luxury buses for corporate events, family trips, and special occasions in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-16.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) For Shraddha Travels:</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Luxurybusrentalinpune;