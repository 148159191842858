
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busbookingforwedding() {



  const cardData =
  {
    keyword: 'Bus Booking for Weddings in Pune',
    heading: 'Shraddha Travels: Bus Booking for Weddings in Pune',
    headingDescription: 'Planning a wedding involves numerous details, and ensuring that your guests have reliable and comfortable transportation is crucial. Shraddha Travels specializes in providing premium bus rental services for weddings in Pune, ensuring that your big day goes off without a hitch. Whether you are hosting a local event or an outstation wedding, we have the perfect transportation solution for you. Discover our range of bus rental options and services below:',
    top: 'Top Features of Our Wedding Bus Rental Services',

    topPlaces: [
        {
            title: "1. Wide Range of Bus Sizes",
            description: "We offer a variety of bus sizes to accommodate different group sizes, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater options. Choose the perfect size for your wedding party and ensure everyone travels comfortably."
        },
        {
            title: "2. Luxury and Comfort",
            description: "Our fleet includes luxury and well-maintained buses designed for maximum comfort. Enjoy plush seating, spacious interiors, and modern amenities to make the journey as enjoyable as the destination."
        },
        {
            title: "3. Air-Conditioned Buses",
            description: "All our buses are equipped with air conditioning to ensure a cool and comfortable ride, especially during warmer months."
        },
        {
            title: "4. Professional Drivers",
            description: "Our experienced and professional drivers are skilled in handling wedding transportation, ensuring a smooth, safe, and timely journey for your guests."
        },
        {
            title: "5. Customizable Packages",
            description: "We offer customizable rental packages to meet your specific needs. Whether you need additional amenities or special arrangements, we can tailor a package to suit your requirements."
        },
        {
            title: "6. Transparent Pricing",
            description: "Enjoy competitive and transparent pricing with no hidden fees. We provide clear and upfront pricing, so you know exactly what to expect."
        },
        {
            title: "7. Punctual Service",
            description: "We prioritize punctuality, ensuring that all transportation schedules are adhered to. Your guests will arrive on time, allowing you to focus on celebrating your special day."
        },
        {
            title: "8. Local and Outstation Rentals",
            description: "Whether your wedding is local or requires outstation travel, we provide reliable transportation solutions for both. Enjoy seamless travel between your wedding venue, reception, and other locations."
        },
        {
            title: "9. Easy Booking Process",
            description: "Booking with Shraddha Travels is straightforward. Contact us via phone, email, or our online booking form, and we’ll handle all the details for you."
        },
        {
            title: "10. Exceptional Customer Support",
            description: "Our dedicated customer support team is available to assist with any queries or issues. We ensure a smooth booking and travel experience from start to finish."
        }
    ],    
    services: [
        {
            name: "Bus Rental for Weddings",
            description: "Shraddha Travels provides a comprehensive range of bus rental options for weddings. Our fleet includes various sizes and styles, from intimate 17-seater buses for small gatherings to larger luxury coaches for grand celebrations. Each vehicle is equipped to ensure a comfortable and enjoyable experience, allowing your guests to travel together and arrive at your wedding venue stress-free."
        },
        {
            name: "Wedding Bus Hire Near Me Pune",
            description: "For convenient wedding bus hire in Pune, Shraddha Travels offers localized services tailored to meet your specific needs. Whether you’re planning a wedding in the heart of the city or in the surrounding areas, our team can help you select the right bus solution that fits your requirements and budget, ensuring seamless transportation for all your guests."
        },
        {
            name: "Shuttle Rental for Weddings in Pune",
            description: "Our shuttle rental services are ideal for transporting guests between the wedding venue, reception, and other key locations. With flexible schedules and reliable vehicles, we ensure that your guests arrive on time and enjoy a hassle-free travel experience. Our friendly drivers are trained to assist your guests and provide a welcoming atmosphere."
        },
        {
            name: "Wedding Party Bus Rental",
            description: "Celebrate in style with our wedding party bus rentals. These luxurious buses come equipped with premium amenities such as comfortable seating, ambient lighting, and sound systems to enhance the celebration. They’re perfect for pre-wedding parties or transporting guests to and from the venue, ensuring that the journey is as memorable as the event itself."
        },
        {
            name: "Wedding Bus Transportation in Pune",
            description: "Our wedding bus transportation services in Pune ensure a seamless experience for your guests from start to finish. We handle all aspects of transportation logistics, including routing and timing, so you can focus on enjoying your special day without worrying about guest travel arrangements."
        },
        {
            name: "Party Bus for Wedding",
            description: "Transform your wedding transportation with our party buses. These vehicles are equipped with festive features such as dance floors, music systems, and mood lighting, creating a fun and lively atmosphere. Perfect for transporting the wedding party or hosting pre-wedding festivities, our party buses ensure a unique and enjoyable travel experience."
        },
        {
            name: "Bus for Wedding Travels",
            description: "Our buses are perfect for various wedding travel needs, including moving guests between multiple locations and providing transportation for pre-wedding events like sangeets or mehndi parties. We ensure a comfortable, well-coordinated journey, allowing everyone to relax and enjoy the celebrations."
        },
        {
            name: "Bus Booking for Wedding",
            description: "Secure your wedding bus with Shraddha Travels for a reliable and stylish transportation solution. Our efficient booking process is designed to be user-friendly, allowing you to select the perfect bus for your wedding day with minimal hassle. Our team will assist you in customizing your booking to fit your specific needs."
        },
        {
            name: "Bus on Rent for Wedding in Pimpri Chinchwad",
            description: "For residents of Pimpri Chinchwad, our specialized wedding bus rental services offer unmatched comfort and reliability. We provide a variety of buses that can accommodate any wedding party size, ensuring that your guests travel together and enjoy their time on the road."
        },
        {
            name: "Bus Rental for Wedding Near Me",
            description: "Find the ideal bus rental for your wedding with our localized services. Shraddha Travels offers convenient options for booking a bus near you, simplifying the planning process. Our team is dedicated to ensuring that your transportation is tailored to your wedding theme and requirements."
        },
        {
            name: "Shraddha Travels Pune",
            description: "As a leading bus rental service provider in Pune, Shraddha Travels is committed to making your wedding transportation seamless and stress-free. Our experienced team ensures that all aspects of your bus rental are handled with professionalism and care, allowing you to focus on creating beautiful memories on your special day."
        },
        {
            name: "AC Bus Booking for Marriage",
            description: "Choose our air-conditioned buses for a cool and comfortable ride on your wedding day. Our AC buses are equipped to maintain a pleasant atmosphere, ensuring that guests remain relaxed and refreshed during their travels, even on hot days."
        },
        {
            name: "Full Bus Booking for Marriage Price",
            description: "We offer competitive pricing for full bus bookings, providing excellent value for your wedding transportation needs. Our pricing is transparent, with no hidden fees, so you can budget accordingly and get the best service for your investment. Contact us for a detailed quote tailored to your specific requirements."
        },
        {
            name: "Pune to Outstation Bus Booking for Marriage",
            description: "For weddings that require travel to outstation locations, Shraddha Travels provides reliable and comfortable buses. Our experienced drivers know the best routes, ensuring that your guests travel in style and comfort, no matter the distance. Enjoy peace of mind knowing your transportation is in capable hands."
        },
        {
            name: "Bus Booking for Marriage Event",
            description: "From ceremonies to receptions, our bus booking services cover all aspects of your marriage event. We specialize in coordinating transportation logistics, so you don’t have to worry about timing or routes. Our goal is to ensure a smooth and enjoyable experience for you and your guests."
        },
        {
            name: "Private Bus Booking for Wedding in Pune",
            description: "For a more personalized experience, consider our private bus booking options. We offer exclusive rentals tailored specifically to your wedding needs, ensuring that you have full control over your transportation arrangements while enjoying a premium travel experience."
        },
        {
            name: "Bus Booking for Wedding in Pimpri Chinchwad",
            description: "Shraddha Travels provides comprehensive bus rental services for weddings in Pimpri Chinchwad. Our range of buses, from standard to luxury, ensures that you can find the perfect fit for your guests, allowing for a smooth and comfortable journey."
        },
        {
            name: "Bus on Rent for Wedding in Karve Nagar",
            description: "Residents of Karve Nagar can benefit from our specialized wedding bus rental services. Our vehicles are well-maintained and equipped with modern amenities to provide comfortable and reliable transportation for your wedding event."
        },
        {
            name: "Luxury Bus on Rent for Wedding in Karve Nagar",
            description: "Experience luxury with our premium bus rentals in Karve Nagar. Our luxury buses are equipped with high-end features such as plush seating, entertainment systems, and more to ensure a stylish and comfortable ride for you and your guests on your special day."
        },
        {
            name: "Wedding Bus Hire in Pimpri Chinchwad",
            description: "Our wedding bus hire services in Pimpri Chinchwad provide reliable and comfortable transportation for your wedding event. We offer a variety of bus sizes to cater to different group sizes, ensuring that your guests travel together and enjoy their experience."
        },
        {
            name: "Wedding Bus Hire in Wadgaon Sheri",
            description: "We offer wedding bus hire services in Wadgaon Sheri, ensuring that your guests travel comfortably to and from your wedding venue. Our well-maintained buses are equipped with all the necessary amenities for a pleasant journey."
        },
        {
            name: "Wedding Bus Hire in Hadapsar",
            description: "Our wedding bus hire services in Hadapsar provide a reliable and stylish transportation solution for your wedding event. With a focus on customer satisfaction, we ensure a smooth journey with our well-equipped buses and professional drivers."
        },
        {
            name: "Wedding Bus Hire in Katraj Pune",
            description: "For weddings in Katraj, Pune, Shraddha Travels offers specialized bus hire services. Our dedicated team ensures that your guests have a comfortable and enjoyable travel experience, with all logistical details managed seamlessly."
        },
        {
            name: "Affordable Mini Bus on Rent in Pune for Wedding",
            description: "For smaller wedding parties, our affordable mini bus rentals provide a cost-effective solution without compromising on comfort. Enjoy reliable and spacious transportation options that cater to your budget and needs."
        },
        {
            name: "Bus Booking for Wedding in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus booking for your wedding in Pune. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Bus Rental for Wedding', '- Wedding Bus Hire Near Me Pune'],
        ['- Shuttle Rental for Wedding in Pune', '- Wedding Party Bus Rental'],
        ['- Wedding Bus Transportation in Pune', '- Party Bus for Wedding'],
        ['- Bus for Wedding Travels', '- Bus Booking for Wedding'],
        ['- Bus on Rent for Wedding in Pimpri Chinchwad', '- Bus Rental for Wedding Near Me'],
        ['- AC Bus Booking for Marriage', '- Full Bus Booking for Marriage Price'],
        ['- Pune to Outstation Bus Booking for Marriage', '- Bus Booking for Marriage Event'],
        ['- Private Bus Booking for Wedding in Pune', '- Bus Booking for Wedding in Pimpri Chinchwad'],
        ['- Bus on Rent for Wedding in Karve Nagar', '- Luxury Bus on Rent for Wedding in Karve Nagar'],
        ['- Wedding Bus Hire in Pimpri Chinchwad', '- Wedding Bus Hire in Wadgaon Sheri'],
        ['- Wedding Bus Hire in Hadapsar', '- Wedding Bus Hire in Katraj Pune']
    ],
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Your Wedding Bus Rental?",
            WhyChoosedescription: "Choosing Shraddha Travels for your wedding bus rental means opting for excellence in both service and comfort. Our commitment to quality is reflected in every aspect of our service. We understand that your wedding day is one of the most important events of your life, and we are dedicated to making the transportation experience as seamless and enjoyable as possible."
        },
        {
            WhyChooseheading: "Experience and Expertise:",
            WhyChoosedescription: "With years of experience in providing wedding transportation, we understand the unique needs of such events and deliver exceptional service accordingly. Our team is well-versed in managing logistics for weddings, ensuring that every detail is attended to so that you can focus on celebrating your special day. Our past experiences have equipped us with insights into common challenges, allowing us to proactively address any potential issues before they arise."
        },
        {
            WhyChooseheading: "Tailored Solutions:",
            WhyChoosedescription: "We customize our services to meet your specific requirements, ensuring that all aspects of transportation are perfectly aligned with your wedding plans. Whether you need transportation for guests, family, or the wedding party, we create a tailored plan that fits your vision and budget. From deciding the pick-up and drop-off points to accommodating special requests, we work closely with you to ensure everything is just right."
        },
        {
            WhyChooseheading: "High-Quality Fleet:",
            WhyChoosedescription: "Our fleet consists of well-maintained, luxury buses that offer the utmost comfort and style, making your guests' travel experience enjoyable. Each vehicle is equipped with modern amenities such as plush seating, air conditioning, and entertainment options, allowing your guests to relax and enjoy the ride. We regularly inspect and maintain our buses to uphold the highest standards of safety and cleanliness, ensuring a pleasant environment for all passengers."
        },
        {
            WhyChooseheading: "Reliability:",
            WhyChoosedescription: "We pride ourselves on our reliability. From punctuality to adherence to your requirements, we ensure everything runs smoothly on your special day. Our drivers are trained to arrive early and are familiar with the best routes, ensuring timely arrivals at all destinations. You can trust us to manage the transportation schedule, allowing you to focus on your wedding festivities without worrying about logistics."
        },
        {
            WhyChooseheading: "Affordability:",
            WhyChoosedescription: "Our transparent pricing and customizable packages make luxury wedding transportation affordable. We provide excellent value without compromising on quality. By offering a variety of options and flexible packages, we help you stay within your budget while ensuring your guests experience a first-class journey. Our goal is to deliver high-quality services that meet your financial needs."
        },
        {
            WhyChooseheading: "Personalized Service:",
            WhyChoosedescription: "We offer personalized service with attention to detail, making sure that every aspect of the transportation is handled with care and professionalism. From your initial inquiry to the last guest's drop-off, our dedicated team is there to assist you. We take the time to understand your specific requirements and preferences, ensuring a tailored experience that reflects your style and needs."
        },
        {
            WhyChooseheading: "Customer Satisfaction:",
            WhyChoosedescription: "Our dedicated customer support team is available to address any concerns and ensure that your experience with us is seamless and stress-free. We prioritize open communication, allowing you to reach out with any questions or adjustments needed. Our commitment to customer satisfaction means we strive to exceed your expectations at every turn."
        },
        {
            WhyChooseheading: "Comprehensive Coverage:",
            WhyChoosedescription: "Whether it’s a local wedding or an outstation event, we offer comprehensive coverage to meet all your transportation needs. Our services extend beyond just transportation; we help manage the entire logistics process, making sure every guest is where they need to be when they need to be there, regardless of the location."
        },
        {
            WhyChooseheading: "Safety and Comfort:",
            WhyChoosedescription: "We prioritize the safety and comfort of your guests, with experienced drivers and well-maintained vehicles that meet all safety standards. Our buses are equipped with seat belts and adhere to all local regulations, ensuring a secure travel environment. We go the extra mile to make sure every journey is comfortable, allowing your guests to enjoy the ride as much as the destination."
        },
        {
            WhyChooseheading: "Flexibility:",
            WhyChoosedescription: "Our flexible booking options and customizable packages allow us to adapt to your changing needs and provide the best possible service for your wedding. Whether plans shift or last-minute adjustments are needed, we are ready to accommodate your requests and ensure everything runs smoothly."
        }
    ]
      }


      const faqData = [
        {
            question: "What types of buses are available for wedding bookings?",
            answer: "We offer a variety of buses, including luxury coaches, mini-buses, and standard buses, to accommodate different guest capacities and comfort levels. Our fleet is designed to meet the needs of any wedding size, ensuring your guests travel in style and comfort."
        },
        {
            question: "How can I book a bus for my wedding?",
            answer: "You can book a bus by visiting our website or calling our customer service. Simply provide details like the date, pickup and drop-off locations, and the number of guests, and our team will assist you in securing your reservation."
        },
        {
            question: "Is there a minimum or maximum number of passengers for booking?",
            answer: "We can accommodate bookings for small groups starting from 20 passengers up to larger groups of 50 or more, depending on the bus type. This flexibility allows us to cater to various wedding party sizes."
        },
        {
            question: "Can I customize the itinerary for my wedding bus?",
            answer: "Absolutely! We offer customizable itineraries to ensure your guests are picked up and dropped off at convenient locations throughout the wedding events. You can create a schedule that suits your wedding timeline perfectly."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "Additional charges may apply for extra stops, late-night service, or specific amenities. We will provide a detailed quote before finalizing your booking, ensuring you have a clear understanding of the total cost."
        },
        {
            question: "Do you provide buses for pre-wedding and post-wedding events?",
            answer: "Yes, we provide bus services for all wedding-related events, including pre-wedding ceremonies, receptions, and post-wedding gatherings. Our aim is to facilitate seamless transportation for your entire wedding experience."
        },
        {
            question: "How far in advance should I book the bus?",
            answer: "We recommend booking at least 2-3 months in advance, especially during peak wedding seasons, to ensure availability. Early booking helps us secure the best options for your special day."
        },
        {
            question: "What happens if there are changes to the booking?",
            answer: "Please contact us as soon as possible if there are any changes. We will do our best to accommodate any adjustments, but some conditions may apply depending on the nature of the changes."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our luxury buses come with comfortable seating, air conditioning, and entertainment options. Additional amenities can be requested based on your needs to enhance your guests' travel experience."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a cancellation policy in place. Please check our terms and conditions for specific details regarding cancellations and refunds to understand any potential fees."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajesh Mehta",
            text: "We recently hired a 35-seater bus from Shraddha Travels for our daughter's wedding. The service was outstanding! The bus was luxurious and comfortable, and the driver was very professional. Everything went smoothly, and our guests were very pleased. Highly recommend their services for any wedding transportation needs!"
        },
        {
            name: "Miss Anjali Patil",
            text: "Shraddha Travels made our wedding transportation so easy. We booked a party bus for our reception, and it was a hit! The bus was beautifully maintained and perfect for our celebration. Their customer service was excellent, and the booking process was hassle-free."
        }
    ];
    

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus booking for wedding in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Booking for Wedding in Pune | Affordable Wedding Bus Rentals</title>
    <meta name="description" content="Reliable bus rental services for weddings in Pune, offering a variety of buses for wedding transportation and events." />
    <meta name="keywords" content="Bus Booking for Wedding in Pune, Wedding Bus Hire, Shuttle Rental for Weddings, Party Bus for Weddings, Affordable Wedding Bus Rentals" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Booking for Wedding in Pune | Affordable Wedding Bus Rentals" />
    <meta property="og:description" content="Hire comfortable buses for wedding transportation in Pune. Services include rentals for all wedding events." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-booking-for-wedding-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/wedding-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Booking for Wedding",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-booking-for-wedding-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Wedding Bus Rental in Pune",
                    "description": "Affordable and reliable bus rental services for wedding transportation and events in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus Booking for Weddings in Pune - Shraddha Travels </h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busbookingforwedding;