
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Minibusorrentinpune() {



  const cardData =
  {
    keyword: ' Mini Bus on Rent in Pune',
    heading: 'Shraddha Travels: Mini Bus on Rent in Pune',
    headingDescription: 'When it comes to group travel in Pune, renting a mini bus from Shraddha Travels offers an unbeatable combination of comfort, convenience, and efficiency. Whether you’re planning a corporate event, family outing, or school trip, our mini buses are designed to cater to your needs with a range of top-notch features. Here’s a detailed look at the top features of our mini buses on rent in Pune:',
    top: 'Top Reasons to Rent a Mini Bus from Shraddha Travels in Pune',

    topPlaces: [
        {
            title: "1. Perfect Size for Groups",
            description: "Our mini buses are ideal for small to medium-sized groups, comfortably accommodating 10 to 20 passengers. They provide the right balance of space without being too large, making them perfect for family outings, corporate events, or school trips."
        },
        {
            title: "2. Cost-Effective Travel Solution",
            description: "Renting a mini bus is often more economical than hiring multiple vehicles. This way, you can save on fuel and parking costs while ensuring everyone travels together."
        },
        {
            title: "3. Comfortable Seating",
            description: "Our mini buses feature comfortable seating arrangements with ample legroom, ensuring a pleasant travel experience for all passengers, whether on a short trip or a longer journey."
        },
        {
            title: "4. Experienced Drivers",
            description: "Our professional drivers are well-trained and knowledgeable about the local routes, prioritizing safety and ensuring a smooth and efficient journey for your group."
        },
        {
            title: "5. Flexible Itineraries",
            description: "Renting a mini bus gives you the flexibility to customize your travel plans. Whether you want to make specific stops or adjust your itinerary on the go, we can accommodate your needs."
        },
        {
            title: "6. Regular Maintenance and Safety Checks",
            description: "Your safety is our priority. Our mini buses undergo routine maintenance and thorough safety inspections, ensuring they are in excellent condition for your journey."
        },
        {
            title: "7. Onboard Amenities",
            description: "Our mini buses are equipped with essential amenities such as air conditioning and charging ports, making your travel more comfortable and convenient."
        },
        {
            title: "8. Easy Booking Process",
            description: "Our user-friendly online booking system allows you to reserve your mini bus quickly and easily, enabling you to focus on planning your itinerary without unnecessary hassle."
        },
        {
            title: "9. 24/7 Customer Support",
            description: "Our dedicated customer service team is available around the clock to assist you with any inquiries or changes to your booking, ensuring a seamless rental experience."
        },
        {
            title: "10. Positive Customer Reviews",
            description: "Shraddha Travels has a strong reputation for reliability and customer satisfaction. Many clients return to us for their travel needs, reflecting our commitment to providing exceptional service."
        }
    ],    
    services: [
        {
            name: "Mini Bus on Rent in Hadapsar, Pune",
            description: "Perfect for local events and gatherings, our mini buses in Hadapsar ensure convenience and comfort. Equipped with modern amenities like air conditioning, comfortable seating, and entertainment systems, they make your travel smooth and enjoyable. Whether it’s a family function, corporate event, or a school trip, our buses provide the perfect solution for hassle-free transportation."
        },
        {
            name: "Mini AC Bus on Rent in Wanowrie, Pune",
            description: "Stay cool and comfortable with our air-conditioned mini buses in Wanowrie. Ideal for maintaining a pleasant temperature, these buses are equipped with plush seating and ample legroom, ensuring a refreshing travel experience regardless of the weather. Perfect for group outings, business trips, or special occasions, our mini AC buses provide a luxurious ride for every passenger."
        },
        {
            name: "Top Mini Bus on Rent in Kothrud, Pune",
            description: "Our top-rated mini buses in Kothrud offer spacious seating and advanced amenities, perfect for family outings and corporate events. With features such as charging ports, audio systems, and generous luggage space, you can enjoy a blend of comfort and convenience on your journey. Trust us to deliver a memorable travel experience, tailored to your group’s needs."
        },
        {
            name: "Mini Bus on Rent in Kharadi, Pune",
            description: "Rent a mini bus in Kharadi for seamless travel to meetings, events, or leisure activities. Our buses provide a comfortable ride with essential features like air conditioning and entertainment options. Whether you're organizing a corporate retreat or a family day out, our mini buses are designed to make your journey enjoyable and stress-free."
        },
        {
            name: "Mini Bus on Rent in Kalyani Nagar, Pune",
            description: "Kalyani Nagar residents can benefit from our mini bus rentals for corporate events and social gatherings. Our vehicles are designed for comfort and reliability, featuring spacious interiors and modern amenities. Whether it’s a business meeting or a birthday party, we ensure tailored transportation that meets your specific needs."
        },
        {
            name: "Mini Bus on Rent in Shivaji Nagar, Pune",
            description: "Our mini buses in Shivaji Nagar provide reliable transportation for all occasions, whether it’s a family event, school outing, or corporate function. With a variety of options to suit your requirements, you can count on us for punctuality, comfort, and professional service throughout your journey."
        },
        {
            name: "Best Mini Bus on Rent in Baner, Pune",
            description: "Experience top-notch service with our mini bus rentals in Baner. Our vehicles are equipped with modern amenities, including air conditioning, plush seating, and onboard entertainment, ensuring a comfortable and enjoyable ride for any occasion. Perfect for weddings, corporate events, or family trips, we cater to all your transportation needs."
        },
        {
            name: "Mini Bus on Rent for Corporate Events in Pune",
            description: "Our mini buses provide a professional and efficient travel solution for corporate events. With comfortable seating and essential amenities, they are perfect for transporting teams and clients. Enjoy peace of mind knowing your group will arrive on time and in style, allowing you to focus on your business objectives."
        },
        {
            name: "Minibus Hire for Wedding Party in Pimpri Chinchwad",
            description: "Celebrate in style with our mini buses in Pimpri Chinchwad. Offering comfort and elegance, our buses ensure a pleasant journey for your guests to and from the wedding venue. With spacious interiors and a focus on luxury, we help make your special day even more memorable."
        },
        {
            name: "Minibus on Rent in Pune for Outstations",
            description: "Travel comfortably to outstation destinations from Pune in our well-equipped mini buses. Designed for longer journeys, our vehicles feature comfortable seating, air conditioning, and entertainment options, allowing you to relax and enjoy the scenery as you travel."
        },
        {
            name: "Pune to Shirdi Mini Bus on Rent",
            description: "Travel comfortably from Pune to Shirdi with our mini bus rentals, ensuring a smooth and serene journey for your pilgrimage or visit. Equipped with all necessary amenities, our buses provide a peaceful environment, allowing you to focus on your spiritual journey."
        },
        {
            name: "Mini Bus on Rent in Pune for Family",
            description: "Our mini buses provide ample space and comfort for family outings or reunions. Enjoy a smooth and enjoyable trip with your loved ones in our well-maintained vehicles, designed to enhance your travel experience with features that cater to family needs."
        },
        {
            name: "Mini Bus on Rent in Pune for Family with Driver",
            description: "Opt for our mini bus rentals with a professional driver for a hassle-free travel experience. Enjoy your family trip while we handle the driving and navigation, ensuring your journey is safe, comfortable, and stress-free."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient mini bus rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Mini Bus on Rent in Hadapsar, Pune', '- Mini AC Bus on Rent in Wanowrie, Pune'],
        ['- Top Mini Bus on Rent in Kothrud, Pune', '- Mini Bus on Rent in Kharadi, Pune'],
        ['- Mini Bus on Rent in Kalyani Nagar, Pune', '- Mini Bus on Rent in Shivaji Nagar'],
        ['- Best Mini Bus on Rent in Baner, Pune', '- Mini Bus on Rent for Corporate Events in Pune'],
        ['- Minibus Hire for Wedding Party in Pimpri Chinchwad', '- Minibus on Rent in Pune for Outstations'],
        ['- Pune to Shirdi Mini Bus on Rent', '- Mini Bus on Rent in Pune for Family'],
        ['- Mini Bus on Rent in Pune for Family with Driver', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Mini Bus on Rent in Pune?",
            WhyChoosedescription: "When it comes to renting a mini bus in Pune, Shraddha Travels is the top choice for individuals and groups seeking reliability, comfort, and excellent service. Here’s why:"
        },
        {
            WhyChooseheading: "Comfortable and Spacious Vehicles:",
            WhyChoosedescription: "Our mini buses are designed to provide maximum comfort with spacious seating and ample legroom. Whether you're planning a family outing, corporate event, or school trip, our mini buses ensure a pleasant journey for all passengers."
        },
        {
            WhyChooseheading: "Modern Amenities:",
            WhyChoosedescription: "We offer mini buses equipped with the latest amenities such as air-conditioning, high-quality sound systems, and entertainment options to make your travel experience enjoyable. Our vehicles are regularly maintained to ensure they are in pristine condition."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our drivers are skilled professionals with extensive experience in navigating Pune and its surroundings. They are committed to providing a safe and smooth journey, ensuring timely arrivals and departures."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "At Shraddha Travels, we offer competitive and transparent pricing for mini bus rentals. We provide detailed quotes with no hidden charges, making sure you get the best value for your money."
        },
        {
            WhyChooseheading: "Customized Solutions:",
            WhyChoosedescription: "We understand that every trip is unique. Whether you need a mini bus for a corporate event, wedding, or a simple city tour, we offer customizable packages to fit your specific needs and preferences."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "From the moment you book with us until the end of your journey, our customer service team is dedicated to assisting you with any queries or special requests. We strive to make your experience as smooth and hassle-free as possible."
        },
        {
            WhyChooseheading: "Punctual and Reliable:",
            WhyChoosedescription: "We value your time and ensure that our mini buses adhere to strict schedules. Our commitment to punctuality means you can rely on us to get you to your destination on time, every time."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "Our fleet of mini buses is regularly inspected and maintained to ensure optimal performance. Cleanliness and safety are our top priorities, providing you with a worry-free travel experience."
        },
        {
            WhyChooseheading: "Easy Booking Process:",
            WhyChoosedescription: "Booking a mini bus with Shraddha Travels is simple and convenient. You can reach us via phone, email, or through our online booking system. Our team will handle all the details to make your booking process seamless."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Safety is our highest priority. All our mini buses are equipped with safety features such as seat belts, fire extinguishers, and first-aid kits. Our drivers are trained to handle any situation that might arise, ensuring a safe journey for everyone."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What is included in the rental of a mini bus?",
        answer: "Our mini bus rental includes comfortable seating, air-conditioning, and the services of a professional driver. Additional amenities like entertainment systems and Wi-Fi may be available upon request."
    },
    {
        question: "How do I book a mini bus with Shraddha Travels?",
        answer: "You can book a mini bus by contacting us directly via phone or email, or by using our online booking form. Provide us with details such as your travel dates, pick-up and drop-off locations, and any special requirements."
    },
    {
        question: "Are there any additional charges I should be aware of?",
        answer: "Our pricing is transparent with no hidden fees. However, additional charges may apply for extended travel hours or special requests. We will provide a detailed quote before finalizing your booking."
    },
    {
        question: "What is the cancellation policy for a mini bus rental?",
        answer: "Our cancellation policy varies based on the timing of the cancellation. Typically, cancellations made well in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact our customer service for specific details regarding your booking."
    },
    {
        question: "Can I request specific features or types of mini buses?",
        answer: "Yes, you can request specific features or types of mini buses based on availability. We offer a range of options to suit different needs, including luxury and standard models."
    },
    {
        question: "Are the drivers experienced and licensed?",
        answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and are dedicated to providing a safe and comfortable travel experience."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, changes to your booking can be made, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
    },
    {
        question: "What should I do if I need assistance during my journey?",
        answer: "If you require any assistance during your journey, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth trip."
    },
    {
        question: "How far in advance should I book a mini bus?",
        answer: "To secure your preferred mini bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
    },
    {
        question: "Are there any restrictions on luggage or other items?",
        answer: "There are standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
    }
];




const testimonials = [
    {
        name: "Mr. Rajesh Sharma",
        feedback: "I recently hired a mini bus from Shraddha Travels for a corporate team outing, and I couldn’t be happier with the service. The booking process was straightforward, and the customer support team was very helpful in accommodating our specific needs. The mini bus was clean, well-maintained, and very comfortable, making our journey enjoyable. Our driver was punctual, professional, and navigated the city efficiently, ensuring we arrived on time. I highly recommend Shraddha Travels for anyone looking for reliable and comfortable mini bus rentals in Pune!"
    },
    {
        name: "Miss. Anita Desai",
        feedback: "Shraddha Travels made our family reunion a breeze with their excellent mini bus rental service. We rented a mini bus for a weekend trip to the outskirts of Pune, and it was perfect for our group of 20. The bus was spacious and equipped with air-conditioning, which was great for our comfort. The driver was friendly and ensured that our trip went smoothly. The entire experience was hassle-free, and I appreciate how accommodating Shraddha Travels was with our last-minute booking. I will definitely use their services again for future events!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Mini Bus On Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Mini Bus On Rent in Pune | Affordable Mini Bus Rentals</title>
    <meta name="description" content="Rent mini buses in Pune for various occasions, including corporate trips, family outings, and events. Enjoy competitive rates and excellent service!" />
    <meta name="keywords" content="Mini bus on rent, Mini bus hire in Pune, Minibus hire with driver for outstation, Mini bus rentals for corporate trip, Small bus rental service in Pune, Minibus rental near me in Pune, Mini Bus Rent Per Km, Mini coach hire in Pune, 12 seater mini bus on rent Pune, Mini bus for Lonavala picnic from Pune, Mini bus hire for event in Pune, Pune to Goa mini bus, Mini bus hire in Pimpri Chinchwad, Mini bus hire in Kharadi Pune, Minibus hire for wedding party in Pune, Mini Bus on rent in Hinjewadi, Mini bus on rent in Baner, Mini bus on rent in Pune near me, Luxury mini bus on rent in Pune near me, Mini bus on rent in Pune for family, 13 15 17 20 24 27 Seater Mini Bus on Rent in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Mini Bus On Rent in Pune | Affordable Mini Bus Rentals" />
    <meta property="og:description" content="Discover mini bus rental services in Pune for corporate trips, family outings, and events. Competitive pricing and excellent service!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/mini-bus-on-rent-in-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/mini-bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Mini Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/mini-bus-on-rent-in-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus",
                    "description": "Comfortable mini bus on rent for various occasions in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='\images\keyword-base\Keyword base images-01.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About Mini Bus on Rent in Pune – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Minibusorrentinpune;