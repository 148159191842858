
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetogoa() {



  const cardData =
  {
    keyword: 'Pune to Goa Bus Hire',
    heading: 'Pune to Goa Bus Hire: Shraddha Travels',
    headingDescription: 'When it comes to unforgettable getaways, few places rival the allure of Goa. Known for its stunning beaches, vibrant culture, and rich history, Goa is the perfect destination for travelers seeking relaxation and adventure. With Shraddha Travels, your journey from Pune to Goa is made seamless and enjoyable with our reliable bus hire services. Our comfortable buses ensure that you arrive at your destination refreshed and ready to explore the enchanting beauty of Goa.',

    topPlaces: [
        {
          title: "1. Baga Beach",
          description: "Baga Beach is one of the most popular beaches in Goa, known for its lively atmosphere, water sports, and beach shacks. It's the perfect spot for sunbathing, parasailing, and enjoying the vibrant nightlife. The beach is lined with shacks offering delicious food and drinks, making it a great place to relax after a day of activities."
        },
        {
          title: "2. Calangute Beach",
          description: "Adjacent to Baga, Calangute Beach is known for its picturesque scenery and family-friendly environment. The beach is dotted with shacks serving delicious Goan cuisine, making it a great place to relax and enjoy the sun. Families can enjoy various water sports, and the nearby shops offer souvenirs and beachwear."
        },
        {
          title: "3. Fort Aguada",
          description: "This historic fort, built in the 17th century, offers stunning views of the Arabian Sea. Visitors can explore the well-preserved lighthouse and learn about Goa's colonial past while enjoying the breathtaking scenery. The fort’s strategic location also makes it a perfect spot for photography, especially during sunset."
        },
        {
          title: "4. Dudhsagar Waterfalls",
          description: "Located on the border of Goa and Karnataka, Dudhsagar Waterfalls is one of the tallest waterfalls in India. A trip here involves a thrilling jeep ride through the forest, culminating in the mesmerizing sight of cascading water. Visitors can also enjoy trekking opportunities and swimming in the natural pools below the falls."
        },
        {
          title: "5. Anjuna Beach",
          description: "Anjuna Beach is famous for its vibrant flea market, nightlife, and stunning sunsets. It’s a great spot for those looking to immerse themselves in Goa’s eclectic culture and meet fellow travelers. The beach hosts various events and parties, particularly during the tourist season, attracting a diverse crowd."
        },
        {
          title: "6. Basilica of Bom Jesus",
          description: "A UNESCO World Heritage Site, the Basilica of Bom Jesus is one of the most important religious sites in Goa. The church, known for its baroque architecture, houses the remains of St. Francis Xavier and attracts thousands of visitors each year. Its serene ambiance and intricate details make it a must-visit for history and architecture enthusiasts."
        },
        {
          title: "7. Palolem Beach",
          description: "Palolem Beach is a serene, crescent-shaped beach that offers a tranquil escape from the bustling tourist spots. It's perfect for swimming, sunbathing, and enjoying a laid-back atmosphere. The beach is also known for its eco-friendly shacks and yoga retreats, making it a popular choice for those seeking relaxation."
        },
        {
          title: "8. Chapora Fort",
          description: "Famous for its stunning panoramic views and the iconic “Dil Chahta Hai” scene, Chapora Fort is a must-visit for history buffs and photography enthusiasts. The fort provides a great vantage point to admire the beauty of the surrounding coastline. Exploring the ruins also offers a glimpse into Goa's rich history."
        },
        {
          title: "9. Spice Plantations",
          description: "A visit to Goa isn't complete without experiencing its spice plantations. Tour the lush estates, learn about the various spices grown here, and enjoy a traditional Goan meal prepared with fresh ingredients. Many plantations offer guided tours, allowing you to understand the cultivation process and the importance of spices in Goan cuisine."
        },
        {
          title: "10. Dudhsagar Spice Plantation",
          description: "This plantation offers guided tours where you can learn about the spices that define Goan cuisine. Enjoy a walk through the estate, and savor a delicious buffet meal featuring local flavors. The plantation also provides a unique opportunity to see the diverse flora and fauna of the region, enhancing your overall experience."
        }
      ],      
      services: [
        {
          name: "Pune to Goa 17 Seater Bus on Rent",
          description: "Traveling with a small group? Our 17-seater buses are designed for a comfortable and spacious ride from Pune to Goa. With plush seating, ample legroom, and plenty of luggage space, these buses are perfect for family trips, corporate outings, or group tours. Enjoy a smooth journey while taking in the beautiful scenery along the way."
        },
        {
          name: "Pune to Goa Urbania Bus Hire",
          description: "Experience luxury and comfort with our Urbania buses for your trip from Pune to Goa. These buses are equipped with premium features such as reclining seats, entertainment systems, and climate control, providing a sophisticated travel experience. Ideal for special occasions, weddings, or high-end travel needs, the Urbania ensures that you travel in style."
        },
        {
          name: "Pune to Goa AC Bus on Rent",
          description: "Stay cool and comfortable on your journey with our AC buses. Perfect for traveling during peak summer or for those who simply prefer a climate-controlled environment, our AC buses guarantee a pleasant trip from Pune to Goa. Enjoy refreshing air conditioning while you relax or socialize with your travel companions."
        },
        {
          name: "14, 17, 25, 32 Seater on Rent for Pune to Goa",
          description: "We offer a range of bus sizes tailored to fit your group's needs for your trip to Goa. Whether you need a cozy 14-seater for a close-knit family gathering or a spacious 32-seater for a larger group, we have the perfect option. Each bus is designed with comfort in mind, featuring comfortable seating and adequate luggage space to ensure a relaxing journey."
        },
        {
          name: "35, 40, 45, 50 Seater on Rent for Pune to Goa",
          description: "For larger groups, our 35-seater, 40-seater, 45-seater, and 50-seater buses are ideal for accommodating everyone comfortably. These spacious buses come equipped with modern amenities, ensuring a pleasant journey for family gatherings, corporate events, or group vacations. Enjoy the journey as much as the destination with our reliable services."
        },
        {
          name: "Pune to Goa Bus Package for Trip from Pune",
          description: "Our bus packages for trips from Pune to Goa are designed for a hassle-free travel experience. These packages include round-trip services, flexible schedules, and optional guided tours. Additional amenities, such as refreshments and entertainment, can be arranged to enhance your travel experience and make your trip memorable."
        },
        {
          name: "Pune to Goa Bus Hire for Picnic",
          description: "Planning a picnic in Goa? Our bus hire services are tailored specifically for picnic trips, offering comfortable and spacious vehicles to transport your group to and from your picnic destination. Enjoy a relaxed and enjoyable outing with our dedicated picnic packages, which include everything from fun activities to snacks and drinks."
        },
        {
          name: "Pune to Goa Bharat Benz Hire for Picnic",
          description: "Experience the luxury of Bharat Benz buses for your picnic trip to Goa. Known for their superior comfort and style, our Bharat Benz fleet will make your picnic journey as enjoyable as the destination itself. With spacious interiors and modern amenities, your group will travel in utmost comfort and sophistication."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "Shraddha Travels provides reliable and convenient vehicle rentals for trips from Pune. Our extensive fleet includes a variety of vehicles, and our professional service ensures a seamless travel experience. We cater to all your rental needs, whether it's a family trip, corporate event, or a weekend getaway."
        },
        {
          name: "32 Seater Bus on Rent in Pune",
          description: "Our 32-seater buses are perfect for medium-sized groups traveling to Goa. Designed for comfort and convenience, these buses feature comfortable seating and ample space for luggage, making them ideal for family trips, corporate outings, or group tours. Enjoy the journey with friends and family while we take care of the driving."
        },
        {
          name: "Pune to Goa Tempo Traveller on Rent",
          description: "For a more compact and flexible option, consider our Tempo Travellers for your journey from Pune to Goa. Ideal for smaller groups, these vehicles provide a comfortable travel experience with modern amenities. Enjoy the convenience of a more personal vehicle while exploring the beautiful landscapes of Goa."
        },
        {
          name: "Pune to Goa Mini Bus on Rent",
          description: "Our mini buses are an excellent choice for groups looking for a more intimate and manageable travel option. Perfect for trips to Goa, these mini buses provide a comfortable atmosphere, allowing everyone to relax and enjoy the ride together. With easy access and a cozy environment, they make group travel simple and enjoyable."
        },
        {
          name: "13 Seater Urbania on Rent for Goa Trip",
          description: "Opt for our 13-seater Urbania buses for a premium travel experience. Equipped with luxury features such as plush seating, climate control, and entertainment systems, these buses are perfect for groups seeking both comfort and style on their trip to Goa. Travel in elegance while enjoying all the comforts of home."
        },
        {
          name: "Book Your Pune to Goa Bus Hire with Shraddha Travels",
          description: "Choose Shraddha Travels for your Pune to Goa journey and enjoy a comfortable, convenient, and enjoyable travel experience. With a range of options to suit different group sizes and needs, our reliable services ensure that your trip to Goa is exceptional. Our team is dedicated to making your travel experience as smooth as possible, from booking to arrival."
        },
        {
          name: "17 Seater Urbania on Rent for Goa Trip",
          description: "Travel in style and comfort with our 17-seater Urbania rental for your Goa trip. Ideal for medium-sized groups, the Urbania offers luxurious amenities and ample space, making your journey from Pune to Goa enjoyable and stress-free. Relax and unwind as you enjoy the beautiful views along the way."
        },
        {
          name: "Pune to Goa Mini Bus on Rent",
          description: "For a more compact group, our mini buses are the perfect choice for travel from Pune to Goa. Designed for comfort and convenience, these mini buses provide an enjoyable journey experience. With easy access and a friendly atmosphere, your group can relax and enjoy the adventure together."
        },
        {
          name: "Pune to Goa Bus Tour Packages",
          description: "Explore Goa with our comprehensive bus tour packages from Pune. Our packages include transportation, guided tours, and customizable itineraries to fit your group’s preferences. Experience the best of Goa’s attractions, beaches, and local culture, all while enjoying the convenience of our well-organized services."
        },
        {
          name: "Bus Hire on Rent for Pune to Goa",
          description: "Our bus hire services for travel from Pune to Goa offer comfort and reliability. Choose from various bus sizes to accommodate your group, and enjoy a hassle-free journey to the beaches and attractions of Goa. Our experienced drivers ensure safety and punctuality, allowing you to relax and enjoy your trip."
        },
        {
          name: "Pune to Goa Bus Hire on Rent",
          description: "Rent a bus for your trip from Pune to Goa with Shraddha Travels. Our fleet includes well-maintained buses specifically designed for long-distance travel. With comfortable seating and modern amenities, your group will enjoy a pleasant ride as you head to Goa’s stunning beaches and vibrant nightlife."
        },
        {
          name: "Pune to Goa Bus Hire on Rent Contact Number",
          description: "For quick and easy booking of your bus hire from Pune to Goa, contact us at Shraddha Travels. Our contact number is available for immediate assistance, and our friendly team is ready to help you secure the perfect vehicle for your trip, ensuring a smooth and enjoyable travel experience."
        },
        {
          name: "Pune Goa Bus Package Tour",
          description: "Our Pune Goa bus package tour provides a complete solution for your journey, including transportation, itinerary planning, and optional guided services. Enjoy a well-organized tour that covers all the major attractions in Goa while relaxing in our comfortable buses, making your trip both memorable and hassle-free."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "Shraddha Travels is your go-to provider for vehicle rentals from Pune to Goa. We offer a variety of options including Urbania rentals, mini buses, and full-sized buses to suit your travel needs. Our commitment to service excellence ensures that your journey is not only comfortable but also enjoyable, with attention to detail in every aspect of your travel."
        },
        {
          name: "Pune to Goa Bus Hire Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Goa Bus Hire. Our dedicated team is ready to assist you with your booking, ensuring that you have all the information you need for a smooth and enjoyable ride. Experience the convenience and reliability of our services today!"
        }
      ],      
      tableData: [
        ['- Pune to Goa 17 Seater Bus on Rent', '- Pune to Goa Urbania Bus Hire'],
        ['- Pune to Goa AC Bus on Rent', '- 14, 17, 25, 32 Seater on Rent for Pune to Goa'],
        ['- 35, 40, 45, 50 Seater on Rent for Pune to Goa', '- Pune to Goa Bus Package for Trip from Pune'],
        ['- Pune to Goa Bus Hire for Picnic', '- Pune to Goa Bharat Benz Hire for Picnic'],
        ['- Shraddha Travels Pune On Rent', '- 32 Seater Bus on Rent in Pune'],
        ['- Pune to Goa Tempo Traveller on Rent', '- Pune to Goa Mini Bus on Rent'],
        ['- 13 Seater Urbania on Rent for Goa Trip', '- 17 Seater Urbania on Rent for Goa Trip'],
        ['- Pune to Goa Mini Bus on Rent', '- Pune to Goa Bus Tour Packages'],
        ['- Bus Hire on Rent for Pune to Goa', '- Pune to Goa Bus Hire on Rent'],
        ['- Pune to Goa Bus Hire on Rent Contact Number', '- Pune Goa Bus Package Tour']
      ],
      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Us for Pune to Goa Bus Hire – Shraddha Travels?",
          WhyChoosedescription: "When it comes to traveling from Pune to Goa, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Our commitment to customer satisfaction and seamless travel experiences sets us apart in the industry. Here’s why you should choose us for your bus hire:"
        },
        {
          WhyChooseheading: "Diverse Fleet of Vehicles:",
          WhyChoosedescription: "At Shraddha Travels, we offer a wide range of vehicles for your Pune to Goa journey, including 17-seater buses, Urbania buses, AC buses, and larger options like 35-seater and 50-seater buses. Our fleet is versatile, accommodating various group sizes and preferences, ensuring that everyone travels in comfort and style. Whether you need a compact vehicle for a small family trip or a spacious bus for a large corporate event, we have the perfect solution."
        },
        {
          WhyChooseheading: "Comfort and Convenience:",
          WhyChoosedescription: "Our buses are designed with comfort in mind. Equipped with amenities such as air conditioning, ample legroom, and plush seating, we create an inviting atmosphere for your journey. In addition, our buses come with entertainment options to keep everyone engaged, making your travel experience enjoyable. We understand that long journeys can be tiring, which is why we focus on providing a relaxing environment to help you arrive refreshed."
        },
        {
          WhyChooseheading: "Experienced and Professional Drivers:",
          WhyChoosedescription: "Our drivers are experienced and well-versed with the route from Pune to Goa, familiar with the best stops along the way. They prioritize safety and efficiency, ensuring you reach your destination on time and in comfort. Our drivers are not just navigators; they are your travel companions, always ready to assist with any requests and ensure a smooth ride. Their professionalism and courteous demeanor enhance your overall travel experience."
        },
        {
          WhyChooseheading: "Flexible and Customizable Packages:",
          WhyChoosedescription: "We offer customizable bus hire packages tailored to your specific requirements. Whether you're planning a family vacation, corporate outing, or picnic, our flexible packages can be adjusted to meet your schedule, budget, and travel preferences. You can choose the duration, routes, and any additional services, ensuring you get the most out of your travel experience. This flexibility allows us to cater to unique group dynamics and needs."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we provide competitive pricing with no hidden charges. Our transparent pricing structure ensures you get the best value for your money, with detailed quotes based on your travel needs. We believe that quality service should be accessible, so we work hard to provide affordable options without compromising on comfort or safety. Enjoy exceptional service that fits within your budget."
        },
        {
          WhyChooseheading: "Exceptional Customer Service:",
          WhyChoosedescription: "Our dedicated customer service team is available to assist you throughout the booking process and during your journey. We take pride in our responsiveness and commitment to resolving any inquiries or concerns promptly. Your satisfaction is our priority, and we strive to make your experience seamless and enjoyable from the moment you book until you reach your destination. Our team is always just a call away, ready to assist you."
        },
        {
          WhyChooseheading: "Reliable and Punctual:",
          WhyChoosedescription: "We pride ourselves on reliability and punctuality. Our buses are maintained to the highest standards, and our drivers adhere to strict schedules to ensure timely departures and arrivals. We understand that time is valuable, especially when traveling for events or vacations. That’s why we go the extra mile to ensure you stay on track, allowing you to focus on enjoying your trip without the stress of delays."
        }
      ]      
  }


  const faqData = [
    {
      question: "What types of buses are available for hire from Pune to Goa?",
      answer: "We offer a variety of buses for your trip from Pune to Goa, including 17-seater, 32-seater, 35-seater, 40-seater, and 50-seater options. Additionally, we provide Urbania buses, AC buses, and tempo travellers to suit different group sizes and preferences."
    },
    {
      question: "How can I book a bus from Pune to Goa with Shraddha Travels?",
      answer: "Booking is simple! You can contact us via phone or email to discuss your requirements. Our customer service team will assist you with availability, pricing, and booking details to ensure a smooth reservation process."
    },
    {
      question: "Are the buses equipped with air conditioning?",
      answer: "Yes, we offer both AC and non-AC buses. For a comfortable journey, especially during warm weather, you can choose our AC buses which are equipped with climate control systems."
    },
    {
      question: "What amenities are provided on the bus?",
      answer: "Our buses come with various amenities such as comfortable seating, ample legroom, air conditioning (for AC buses), and sometimes onboard entertainment. The specific amenities may vary based on the type of bus you choose."
    },
    {
      question: "Is there a minimum or maximum number of passengers required?",
      answer: "We accommodate various group sizes. From small groups needing a 17-seater to large groups requiring a 50-seater, we have options to fit your needs. For specific requirements, please contact us for detailed information."
    },
    {
      question: "Are there any additional charges?",
      answer: "Our pricing is transparent with no hidden charges. The cost is based on factors such as the type of bus, distance, and duration of hire. We provide detailed quotes, so you are aware of the total cost before confirming your booking."
    },
    {
      question: "Can I customize my bus hire package?",
      answer: "Yes, we offer customizable packages to suit your travel needs. Whether you need specific pickup and drop-off points or have special requirements for your trip, we can tailor the package accordingly."
    },
    {
      question: "What should I do if there is a delay or issue during the trip?",
      answer: "Our customer service team is available to assist you with any issues that may arise during your trip. If you encounter any problems, please contact us immediately, and we will address them promptly."
    },
    {
      question: "How far in advance should I book the bus?",
      answer: "We recommend booking as early as possible to ensure availability, especially during peak travel seasons. Early booking also allows you to secure the best rates and options for your trip."
    },
    {
      question: "What safety measures are in place for the journey?",
      answer: "We prioritize safety and ensure that all our buses are regularly maintained and inspected. Our drivers are experienced and trained to handle various road conditions, ensuring a safe and secure journey for all passengers."
    }
  ];
  

  const testimonials = [
    {
        name: "Mr. Rajesh Sharma",
        text: "Our family trip from Pune to Goa with Shraddha Travels was nothing short of exceptional. We hired a 35-seater bus, and the experience was fantastic from start to finish. The bus was clean, comfortable, and well-maintained, with ample space for everyone to relax. The driver was professional and ensured we had a smooth journey. The booking process was straightforward, and the customer service team was very accommodating. Highly recommend Shraddha Travels for anyone planning a trip to Goa!"
    },
    {
        name: "Miss Priya Desai",
        text: "I recently organized a corporate outing from Pune to Goa and chose Shraddha Travels for the bus hire. We opted for a 50-seater AC bus, and it was the perfect choice for our large group. The bus was equipped with modern amenities, and the air conditioning was a blessing in the warm weather. The service was impeccable, with the driver being punctual and courteous throughout the trip. The whole experience exceeded our expectations, and I would definitely use Shraddha Travels again for future trips."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Goa Bus Hire Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Goa Bus Hire | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Book reliable bus services for your journey from Pune to Goa. Choose from various options including mini buses, urbania, and luxury coaches." />
    <meta name="keywords" content="Pune to Goa Bus Hire, 17 Seater Bus on Rent, Urbania Bus Hire, AC Bus on Rent, Mini Bus on Rent, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Goa Bus Hire | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Explore Goa with our affordable bus hire services from Pune. Perfect for family trips, picnics, and group outings." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-goa-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/goa-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Goa Bus Hire",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-goa-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services for Pune to Goa",
                    "description": "Comfortable and affordable bus rental services from Pune to Goa, including options for group travel and picnics."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -14.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune to Goa Bus Hire – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetogoa;