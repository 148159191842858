
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinhadapsar() {



  const cardData =
  {
    keyword: 'Bus on Rent in Hadapsar',
    heading: 'Shraddha Travels: Bus on Rent in Hadapsar',
    headingDescription: 'When it comes to reliable and comfortable bus rental services in Hadapsar, Shraddha Travels is your go-to choice. Our extensive fleet and dedication to quality ensure that every journey is smooth and enjoyable. Whether you are planning a corporate event, family trip, school excursion, or any other group activity, we have the perfect bus to meet your needs. Here’s a look at the top bus rental options we offer in Hadapsar:',

    topPlaces: [
        {
            title: "1. 14-Seater Mini Bus",
            description: "Perfect for smaller groups or short trips, our 14-seater mini buses offer a cozy and comfortable ride. Ideal for family outings, small corporate meetings, or local travel, these mini buses provide flexibility and convenience. With comfortable seating and sufficient luggage space, they ensure a pleasant journey whether you're heading to a nearby picnic spot or a business meeting."
        },
        {
            title: "2. 17-Seater Mini Bus",
            description: "For slightly larger groups, our 17-seater mini buses are an excellent choice. These buses provide additional space and comfort, making them suitable for school trips, group tours, and other medium-sized gatherings. Equipped with modern amenities, they enhance the travel experience, allowing passengers to relax and enjoy the journey together."
        },
        {
            title: "3. 32-Seater Bus",
            description: "Our 32-seater buses are ideal for medium-sized groups needing more room. With comfortable seating and ample storage space, these buses are perfect for corporate events, team outings, and family vacations. The spacious interior allows for easy movement, and the large windows offer great views of the scenery as you travel."
        },
        {
            title: "4. 35-Seater Bus",
            description: "If you need a bit more space, our 35-seater buses are a great option. These buses offer extra comfort and are well-suited for larger group events, including inter-city travel and extended tours. Designed for comfort, they come with adjustable seating and air conditioning to keep everyone relaxed throughout the journey."
        },
        {
            title: "5. 40-Seater Bus",
            description: "Our 40-seater buses are designed for larger groups requiring ample seating and comfort. They are perfect for school excursions, corporate trips, and other significant events where space and comfort are a priority. With features like onboard restrooms and entertainment systems in select models, your journey will be enjoyable from start to finish."
        },
        {
            title: "6. 45-Seater Bus",
            description: "For very large groups, our 45-seater buses provide spacious seating and modern amenities. These buses are ideal for major events, large family gatherings, and corporate outings that require a high level of comfort and convenience. The bus is designed for easy boarding and disembarking, ensuring a hassle-free experience for all passengers."
        },
        {
            title: "7. 50-Seater Bus",
            description: "Our 50-seater buses are the largest in our fleet, offering maximum space and comfort. They are perfect for big events such as weddings, large corporate events, and other large group activities where comfort and room are essential. With extra-large storage compartments, you won’t have to worry about luggage, and everyone can enjoy a smooth ride."
        },
        {
            title: "8. Luxury AC Bus",
            description: "For those seeking an upscale travel experience, our luxury AC buses are equipped with premium features, including air conditioning, plush seating, and entertainment options. Ideal for long journeys and special occasions, these buses ensure a luxurious and comfortable ride. Passengers can enjoy refreshments and a relaxing atmosphere, making every trip feel like a mini getaway."
        },
        {
            title: "9. Urbania Bus",
            description: "Experience top-tier comfort with our Urbania buses, which feature advanced amenities and a stylish design. These buses are perfect for high-end travel needs and are ideal for corporate events or luxury group outings. With spacious interiors and the latest technology, they create an environment conducive to both relaxation and productivity."
        },
        {
            title: "10. Tempo Traveller",
            description: "Our tempo travellers offer a compact yet comfortable option for smaller groups. They are ideal for short trips and provide flexibility and ease of maneuverability, making them suitable for local excursions and day trips. With comfortable seating and a variety of seating arrangements, they can cater to different preferences, ensuring everyone enjoys the ride."
        }
    ],    
    services: [
        {
            name: "Mini Bus on Rent in Magarpatta City-Hadapsar",
            description: "For small group travel or local trips, our mini buses available in Magarpatta City-Hadapsar offer a perfect blend of comfort and practicality. These buses are equipped with comfortable seating, air conditioning, and ample storage space, making them ideal for family outings, business trips, or social events. Enjoy a smooth ride while exploring the vibrant surroundings of Hadapsar."
        },
        {
            name: "Urbania Bus on Rent in Hadapsar",
            description: "Experience luxury and style with our Urbania bus rental in Hadapsar. Designed for comfort and sophistication, the Urbania features plush seating, modern amenities, and state-of-the-art entertainment systems. This bus is an excellent choice for corporate events, special occasions, and group outings, ensuring you travel in elegance and comfort."
        },
        {
            name: "Corporate Bus Hire in Hadapsar Pune",
            description: "For businesses in Hadapsar, our corporate bus hire service provides reliable transportation for meetings, conferences, and team-building events. Our well-equipped buses ensure a professional atmosphere, complete with comfortable seating and amenities. Let us handle your transportation needs, allowing your team to focus on what matters most."
        },
        {
            name: "14, 17, 25, 32 Seater on Rent in Hadapsar-Magarpatta",
            description: "Choose from our range of 14, 17, 25, and 32-seater buses for various group sizes in Hadapsar-Magarpatta. Each bus is designed to offer comfort and convenience for local trips, events, and any situation where group travel is essential. Enjoy spacious interiors and a smooth ride for any occasion."
        },
        {
            name: "35, 40, 45, 50 Seater Bus Hire in Hadapsar",
            description: "For larger groups, we offer buses ranging from 35 to 50 seats. Our spacious buses are perfect for family gatherings, corporate events, or school trips, providing ample room and comfort for all passengers. Experience worry-free travel as we ensure that your group arrives at their destination safely and on time."
        },
        {
            name: "Mini Bus on Rent in Hadapsar",
            description: "Our mini bus rental service in Hadapsar offers a convenient option for group travel within the city. With comfortable seating and reliable service, our mini buses are perfect for local or nearby travel needs, including airport transfers, day trips, and social outings. Enjoy a hassle-free journey tailored to your group’s requirements."
        },
        {
            name: "Bus for Wedding Near Me Hadapsar",
            description: "Make your wedding day seamless with our bus hire service for weddings near Hadapsar. We provide stylish and comfortable buses to transport guests, ensuring they arrive on time and in comfort. Our experienced drivers handle logistics, allowing you to focus on celebrating your special day without stress."
        },
        {
            name: "Bus Rental for Corporate Events in Hadapsar",
            description: "Our bus rental services for corporate events in Hadapsar include transportation solutions tailored to your business needs. Whether you need executive transport for top management or large group shuttles for team outings, we offer reliable and professional services to enhance your corporate image."
        },
        {
            name: "Hire AC Luxury Bus on Rent in Magarpatta City",
            description: "For a luxurious travel experience, hire an AC luxury bus from Shraddha Travels in Magarpatta City. Our luxury buses are equipped with top-notch amenities, including spacious seating, premium audio-visual systems, and climate control, ensuring a comfortable and enjoyable journey for any special event or trip."
        },
        {
            name: "Tempo Traveller on Rent in Hadapsar",
            description: "Our tempo traveller rental service in Hadapsar is perfect for medium-sized groups. With comfortable seating and modern amenities, our tempo travellers are suitable for local excursions or outstation trips. Experience reliable service and a smooth journey, making every trip memorable."
        },
        {
            name: "Mini Bus on Rent in Magarpatta Hadapsar",
            description: "Available for both local and outstation trips, our mini buses in Magarpatta Hadapsar provide a convenient and comfortable travel solution. Ideal for various group sizes and purposes, our well-maintained buses ensure a pleasant ride whether you're exploring the city or heading out for a day trip."
        },
        {
            name: "Bus on Rent in Magarpatta City",
            description: "For any travel needs in Magarpatta City, Shraddha Travels offers bus rentals tailored to your requirements. Our diverse fleet includes options for local trips, special events, and more, providing flexibility and comfort for all your group travel needs."
        },
        {
            name: "Bus Hire for Corporate Company in Pune",
            description: "Our bus hire services are designed to meet the needs of corporate companies in Pune. With options to accommodate different group sizes, we ensure professional and efficient transport for business purposes, enhancing your team's travel experience and promoting punctuality."
        },
        {
            name: "AC and Non-AC Monthly Bus Rental Services",
            description: "Shraddha Travels provides flexible monthly bus rental services, both AC and non-AC, to cater to your ongoing transportation needs. Ideal for long-term projects, regular corporate travel, or other continuous requirements, our rental options offer convenience and reliability."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "Shraddha Travels is your trusted partner for vehicle rentals in Pune. Our diverse fleet includes mini buses, Urbania, tempo travellers, and luxury buses, ensuring you have the perfect vehicle for any occasion in Hadapsar and beyond. Count on us for professional service and a seamless travel experience."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Mini Bus On Rent in Magarpatta City-Hadapsar', '- Urbania Bus on Rent in Hadapsar'],
        ['- Corporate Bus Hire in Hadapsar Pune', '- 14, 17, 25, 32 Seater on Rent in Hadapsar Magarpatta'],
        ['- 35, 40, 45, 50 Seater Bus Hire in Hadapsar', '- Mini Bus on Rent in Hadapsar'],
        ['- Bus for Wedding Near Me Hadapsar', '- Bus Rental for Corporate Events in Hadapsar'],
        ['- Hire AC Luxury Bus on Rent in Magarpatta City', '- Tempo Traveller on Rent in Hadapsar'],
        ['- Mini Bus on Rent in Magarpatta Hadapsar', '- Bus On Rent in Magarpatta City'],
        ['- Bus Hire For Corporate Company in Pune', '- AC and Non-AC Monthly Bus Rental Services'],
        ['- Shraddha Travels Pune On Rent', '- Mini Bus on Rent in Hadapsar'],
        ['- Tempo Traveller on Rent in Hadapsar', '- Force Urbania on Rent in Hadapsar']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for 'Bus on Rent in Hadapsar'?",
            WhyChoosedescription: "At Shraddha Travels, we pride ourselves on providing exceptional bus rental services in Hadapsar. Our commitment to quality and customer satisfaction sets us apart in the industry. With years of experience, we ensure that every journey is memorable and enjoyable. Here's why you should choose us for your bus rental needs:"
        },
        {
            WhyChooseheading: "Diverse Fleet of Vehicles:",
            WhyChoosedescription: "We offer a wide range of buses, from small mini-buses perfect for intimate gatherings to large 50-seater coaches designed for big groups. This diversity allows us to cater to various travel needs, whether you’re organizing a family reunion, corporate event, or a school trip. Every vehicle is meticulously maintained to ensure safety and reliability."
        },
        {
            WhyChooseheading: "Top-Notch Comfort and Amenities:",
            WhyChoosedescription: "Our buses are equipped with modern amenities such as plush seating, air conditioning, and entertainment options like music systems and TV screens. We understand the importance of comfort during travel, which is why we strive to create a pleasant environment for all passengers, allowing you to relax and enjoy the journey."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our highly trained drivers are not only skilled but also possess extensive knowledge of local routes and traffic conditions. They are committed to ensuring safe, smooth, and courteous service throughout your trip. Their professionalism contributes to a stress-free travel experience, allowing you to focus on your event."
        },
        {
            WhyChooseheading: "Transparent and Competitive Pricing:",
            WhyChoosedescription: "We believe in providing clear, upfront pricing with no hidden fees. Our rates are designed to be competitive while still reflecting the quality of service we offer. We provide detailed quotes to help you understand exactly what you’re paying for, ensuring there are no surprises along the way."
        },
        {
            WhyChooseheading: "Reliable and Punctual Service:",
            WhyChoosedescription: "Timeliness is key to a successful trip, and our well-maintained buses adhere to strict schedules. We prioritize your time by ensuring that our vehicles are ready to go when you are, allowing you to reach your destination promptly and without stress."
        },
        {
            WhyChooseheading: "Exceptional Customer Support:",
            WhyChoosedescription: "Our customer support team is always available to assist with bookings and inquiries. From your initial contact to the end of your journey, we are here to provide guidance and support, ensuring that your experience is seamless and stress-free. We value your feedback and strive to improve our services continuously."
        },
        {
            WhyChooseheading: "Flexibility and Customization:",
            WhyChoosedescription: "We offer customizable rental packages to fit your specific needs, whether it’s for corporate events, family outings, or special occasions. Our team works with you to understand your requirements, providing tailored solutions that ensure every detail is taken care of."
        },
        {
            WhyChooseheading: "Safety and Cleanliness:",
            WhyChoosedescription: "We prioritize safety and hygiene, with our buses undergoing regular maintenance checks and thorough cleaning after each use. Our commitment to providing a secure and hygienic travel environment gives you peace of mind as you travel with us."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "Booking with us is easy and hassle-free. You can contact us directly via phone or use our user-friendly online booking system for quick reservations. Our goal is to make the process as convenient as possible so you can focus on your travel plans."
        },
        {
            WhyChooseheading: "Proven Track Record:",
            WhyChoosedescription: "With years of experience in the bus rental industry, Shraddha Travels has built a proven track record of delivering excellent services. Our long list of satisfied customers and positive testimonials reflect our dedication to quality and customer service, making us a trusted choice for your travel needs."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for rent in Hadapsar?",
        answer: "We offer a range of buses including 14-seater mini-buses, 17-seater mini-buses, 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater buses. Additionally, we provide luxury AC buses, Urbania buses, and tempo travellers."
    },
    {
        question: "How do I book a bus with Shraddha Travels?",
        answer: "You can book a bus by contacting us directly through our phone number or email. Alternatively, you can use our online booking system on our website for convenient reservations."
    },
    {
        question: "Are there any hidden charges?",
        answer: "No, we provide clear and upfront pricing with no hidden charges. Our quotes are detailed, so you know exactly what you’re paying for."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "We recommend booking your bus as early as possible to ensure availability, especially during peak seasons or for large groups. However, we accommodate last-minute bookings whenever possible."
    },
    {
        question: "What should I do if I need to cancel or modify my booking?",
        answer: "Please contact our customer support team as soon as possible if you need to cancel or modify your booking. We will assist you with the necessary changes according to our cancellation and modification policy."
    },
    {
        question: "Are the buses equipped with air conditioning?",
        answer: "Yes, all our buses are equipped with air conditioning for your comfort. We also offer luxury AC buses for an upgraded experience."
    },
    {
        question: "What amenities are provided on the buses?",
        answer: "Amenities vary depending on the type of bus. Standard features include comfortable seating, air conditioning, and sometimes entertainment options. Luxury and Urbania buses offer additional amenities for a premium experience."
    },
    {
        question: "How do you ensure the safety of passengers?",
        answer: "Our buses undergo regular maintenance and safety checks to ensure they are in excellent condition. Our drivers are trained and experienced, and we adhere to strict safety standards."
    },
    {
        question: "Can I customize the rental package for my trip?",
        answer: "Yes, we offer customizable rental packages to fit your specific needs. Contact us to discuss your requirements, and we will tailor our services to meet them."
    },
    {
        question: "What areas do you serve for bus rentals?",
        answer: "We provide bus rental services primarily in Hadapsar and surrounding areas. For trips beyond these areas, please contact us to discuss your travel needs and arrangements."
    }
];


const testimonials = [
    {
        name: "Miss Priya Deshmukh",
        text: "Our company recently used Shraddha Travels for a large corporate event in Hadapsar, and I couldn’t be happier with the service we received. The 45-seater bus was impeccably clean, well-maintained, and equipped with all the necessary amenities to ensure a comfortable journey for our team. The driver was punctual, professional, and extremely knowledgeable about the local routes. The booking process was straightforward, and the staff at Shraddha Travels were very accommodating to our specific needs. I highly recommend their bus rental services for any corporate or large group event!"
    },
    {
        name: "Mr. Rajesh Patel",
        text: "I recently organized a family reunion and chose Shraddha Travels for our bus rental needs. We hired a 32-seater bus for our trip in Hadapsar, and the experience was fantastic. The bus was spacious, clean, and comfortable, which made our journey enjoyable. The driver was courteous and ensured that we reached our destinations safely and on time. The customer service was exceptional, with prompt responses and helpful assistance throughout the booking process. I will definitely use Shraddha Travels again for future trips and highly recommend them for their excellent service and reliability."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus on Rent in Hadapsar Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus on Rent in Hadapsar | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Explore reliable bus rental services in Hadapsar for corporate events, weddings, and local trips. Options include mini buses and luxury coaches." />
    <meta name="keywords" content="Bus on Rent in Hadapsar, Mini Bus Rental, Urbania Bus Hire, Corporate Bus Hire, Tempo Traveller on Rent, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus on Rent in Hadapsar | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Discover our bus rental services in Hadapsar for corporate events, weddings, and more. Affordable options available for all group sizes." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-rent-hadapsar" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/hadapsar-bus-rent.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus on Rent in Hadapsar",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Hadapsar",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-rent-hadapsar",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Hadapsar",
                    "description": "Affordable bus rental services in Hadapsar, ideal for corporate events, weddings, and local trips. Various options available."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-03.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Bus on Rent in Hadapsar for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinhadapsar;