import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Enquiry = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        setLoading(true);
        setError('');
        setSuccess('');

        try {
            // Web3Forms API submission
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    access_key: '72ab7bbe-ccb3-49c5-88fe-b05e95d4986a',  // Replace with your Web3Forms API key
                    name: formData.get('name'),
                    email: formData.get('email'),
                    phone: formData.get('phone'),
                    bus_location: formData.get('bus_location'),
                    days: formData.get('days'),
                    pick_up_point: formData.get('pick_up_point'),
                    Buses: formData.get('Buses'),
                    date: formData.get('date'),
                    message: formData.get('message'),
                }),
            });

            const result = await response.json();

            if (result.success) {
                setSuccess('Your message has been sent successfully!');
                event.target.reset();
            } else {
                setError('Sorry, something went wrong. Please try again later.');
            }
        } catch (err) {
            setError('Sorry, something went wrong. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
                <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
                <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />
                <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
                <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
                <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />
            </Helmet>

            <section id="about" className="jarallax text-light ">
                <div className="center-y relative text-center ">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-12 text-center">
                                <h1 className="text-white">Enquiry</h1>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="news-details-section fix section-padding">
                <div className="container">
                    <div className="row container">
                        <div className="col-8">
                            <div className="row">
                                <div className="de-item-list mb30">
                                    <h3 className="text-white">Do you have any enquiry?</h3>
                                    <form 
                                        name="contactForm" 
                                        id="contact_form" 
                                        className="form-border" 
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="name" className="text-muted small">Your Full Name</label>
                                                    <input 
                                                        name="name" 
                                                        id="name" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Your Full Name" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="email" className="text-muted small">Your Email</label>
                                                    <input 
                                                        type="email" 
                                                        name="email" 
                                                        id="email" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Your Email" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="phone" className="text-muted small">Your Phone</label>
                                                    <input 
                                                        type="tel" 
                                                        name="phone" 
                                                        id="phone" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Your Phone" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="Bus_location" className="text-muted small">Bus</label>
                                                    <input 
                                                        name="Bus_location" 
                                                        id="Bus_location" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Bus Name" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="days" className="text-muted small">Number of Days</label>
                                                    <input 
                                                        name="days" 
                                                        id="days" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Number of Days" 
                                                        required 
                                                        type="number" 
                                                        min="1" 
                                                        max="100"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="pick_up_point" className="text-muted small">Pick Up Point</label>
                                                    <input 
                                                        name="pick_up_point" 
                                                        id="pick_up_point" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Pick Up Point" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="Buses" className="text-muted small">Select Buses</label>
                                                    <select 
                                                        name="Buses" 
                                                        id="Buses" 
                                                        className="border py-2 form-control form-control-lg rounded-0" 
                                                        required
                                                    >
                                                        <option value="" disabled>Select Buses</option>
                                                        <option value="Swift Dzire (4 + 1 Seater)">Swift Dzire (4 + 1 Seater)</option>
                                                        <option value="Kia Carens(6 + 1 Seater)">Kia Carens(6 + 1 Seater)</option>
                                                        <option value="Ertiga (6 + 1 Seater)">Ertiga (6 + 1 Seater)</option>
                                                        <option value="Tavera (6 + 1 Seater)">Tavera (6 + 1 Seater)</option>
                                                        <option value="Innova (6 + 1 Seater)">Innova (6 + 1 Seater)</option>
                                                        <option value="13 Seater(13 Seater)">13 Seater(13 Seater)</option>
                                                        <option value="17 Seater(17 Seater)">17 Seater(17 Seater)</option>
                                                        <option value="Innova Crysta(6 + 1 Seater)">Innova Crysta(6 + 1 Seater)</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="date" className="text-muted small">Date of Journey</label>
                                                    <input 
                                                        type="date" 
                                                        name="date" 
                                                        id="date" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        required 
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <div className="form-group position-relative">
                                                    <label htmlFor="message" className="text-muted small">Message</label>
                                                    <textarea 
                                                        name="message" 
                                                        id="message" 
                                                        rows="5" 
                                                        className="form-control form-control-lg rounded-0" 
                                                        placeholder="Your Message" 
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="g-recaptcha" data-sitekey="your-recaptcha-site-key-here"></div>

                                        <div id="submit" className="mt20 text-center py-3 px-5">
                                            <button 
                                                type="submit" 
                                                className="btn-main mt-2 py-2 px-3 border-0 text-balck"
                                                disabled={loading}
                                            >
                                                {loading ? 'Submitting...' : 'Submit Now'}
                                            </button>
                                        </div>
                                    </form>

                                    {success && <div className="success">{success}</div>}
                                    {error && <div className="error">{error}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Enquiry;
