
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Corporattravelsinpune() {



  const cardData =
  {
    keyword: ' Corporate Travels in Pune',
    heading: 'Shraddha Travels: Corporate Travels in Pune',
    headingDescription: 'When it comes to corporate travel, comfort, reliability, and professionalism are paramount. At Shraddha Travels, we specialize in providing top-notch transportation solutions tailored to meet the unique needs of corporate clients in Pune. Whether you are organizing team outings, client meetings, or corporate events, our services are designed to ensure a smooth and efficient travel experience.',
    top: 'Top Places for Corporate Travels in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Pune IT Park (Hinjewadi)",
            description: "Hinjewadi is a major IT hub in Pune, home to numerous technology companies and corporate offices. Known for its modern infrastructure and vibrant work environment, this area attracts professionals from all over. Our corporate travel services cater to employees needing timely transportation to and from this bustling area, ensuring a comfortable commute with minimal stress."
        },
        {
            title: "2. Pune Camp Area",
            description: "The Camp area is recognized for its central location and vibrant commercial environment. It’s a popular spot for business meetings, corporate events, and networking opportunities. Shraddha Travels provides reliable transport services to and from this key business district, helping professionals navigate the city with ease and efficiency."
        },
        {
            title: "3. Magarpatta City",
            description: "Magarpatta City is a well-planned business and residential district with numerous IT firms and corporate offices. This area combines modern living with a strong business ecosystem. Our fleet is equipped to handle the needs of employees and executives traveling to this major business center, ensuring a smooth and professional experience."
        },
        {
            title: "4. Pune Cantonment",
            description: "This area hosts various business establishments and is well-connected to other parts of the city. With its strategic location, it serves as a hub for numerous industries. Shraddha Travels offers efficient travel solutions for corporate clients needing access to this strategically important location, ensuring they arrive on time and ready for their engagements."
        },
        {
            title: "5. Koregaon Park",
            description: "Koregaon Park is not only a popular residential area but also a hub for corporate offices and business meetings. Its lush greenery and upscale ambiance make it an attractive location for professionals. Our services ensure that those traveling to this upscale district experience hassle-free commutes, allowing them to focus on their work and meetings."
        },
        {
            title: "6. Viman Nagar",
            description: "Known for its proximity to Pune Airport and several business parks, Viman Nagar is a key location for corporate travel. This area features a mix of corporate offices and retail spaces, making it a lively spot for professionals. Our transport solutions include airport transfers and travel within this dynamic area, catering to the needs of business travelers."
        },
        {
            title: "7. Baner",
            description: "Baner is an emerging business district with numerous corporate offices and tech parks. This area is rapidly developing and is becoming increasingly important for the IT and corporate sectors. Shraddha Travels provides specialized transport services for professionals working in and around this growing business hub, ensuring timely and comfortable commutes."
        },
        {
            title: "8. Aundh",
            description: "Aundh is home to several multinational companies and business centers. Its well-planned infrastructure makes it a convenient location for corporate activities. Our corporate travel services ensure that executives and employees have comfortable and punctual transportation to this important location, allowing them to maximize productivity."
        },
        {
            title: "9. Kalyani Nagar",
            description: "This area is known for its corporate offices and business establishments, offering a mix of work and leisure options. Kalyani Nagar is increasingly becoming a preferred location for companies looking to set up operations in Pune. Our services cater to professionals traveling to Kalyani Nagar, offering comfortable and reliable transport options tailored to their needs."
        },
        {
            title: "10. Pimple Saudagar",
            description: "A rapidly developing area with a growing number of corporate offices, Pimple Saudagar requires dependable transportation solutions. The area is attracting more businesses, making efficient travel increasingly important. Shraddha Travels provides tailored solutions to meet the needs of businesses and their employees in this area, ensuring seamless and stress-free commutes."
        }
    ],    
    services: [
        {
            name: "Travel Agents for Corporate in Pune",
            description: "As leading travel agents for corporate clients in Pune, Shraddha Travels specializes in providing tailored transportation solutions. We understand the unique challenges faced by businesses, ensuring your trips are not just efficient but also comfortable. Our team is dedicated to managing every aspect of corporate travel, from logistics to on-ground support, allowing you to focus on your core business activities."
        },
        {
            name: "Corporate Travel Company in Pune",
            description: "Shraddha Travels stands out as a premier corporate travel company in Pune, offering a wide range of services to meet the unique needs of businesses. Our offerings include customized travel itineraries, shuttle services for employee commutes, and dedicated transportation for corporate events. We pride ourselves on our attention to detail, ensuring that every journey is executed flawlessly, enhancing your overall travel experience."
        },
        {
            name: "Corporate Travel Company in Pimpri Chinchwad",
            description: "For businesses in Pimpri Chinchwad, our corporate travel company provides dedicated services designed to cater to your specific needs. We offer tailored transportation solutions that ensure smooth and efficient travel for your team. Our services include timely pickups and drop-offs, ensuring your employees arrive at their destinations relaxed and on schedule, thereby boosting productivity."
        },
        {
            name: "Bus on Rent for Corporate in Katraj",
            description: "If you’re looking for a bus on rent for corporate events in Katraj, Shraddha Travels has you covered. Our buses are ideal for office outings, team-building exercises, and other corporate functions. Each vehicle is equipped with modern amenities and spacious seating, ensuring a comfortable ride for all participants. Our experienced drivers prioritize safety and punctuality, making your corporate outings stress-free."
        },
        {
            name: "Bus Hire for Corporate Party in Pune",
            description: "Planning a corporate party and need transportation? Our bus hire service in Pune ensures that your event is a hit. With comfortable and spacious buses, we provide a hassle-free solution for transporting your team to and from the venue. Our service includes flexible scheduling and accommodating last-minute changes, ensuring that your team can focus on enjoying the event."
        },
        {
            name: "Bus Hire for Corporate Team Outing in Pune",
            description: "For corporate team outings in Pune, Shraddha Travels offers excellent bus hire options. Our well-maintained buses are perfect for group travel, ensuring that your team enjoys a comfortable and enjoyable outing. We facilitate various activities, from sightseeing to team-building exercises, making your outing memorable and effective for team bonding."
        },
        {
            name: "Bus Hire for Corporate Team Picnic in Pune",
            description: "Make your corporate team picnic memorable with our bus hire services. We offer reliable and comfortable transportation to various picnic spots around Pune. Our dedicated team will assist you in planning the logistics, ensuring that everything runs smoothly from start to finish, so your team can focus on enjoying their day out."
        },
        {
            name: "Pune to Mahabaleshwar Bus Hire for Office Team Picnic",
            description: "Organizing an office team picnic to Mahabaleshwar? Shraddha Travels provides efficient bus hire services for a smooth journey. Our buses are equipped with comfortable seating and amenities, allowing your team to travel in style. We take care of the route planning and timing, ensuring that everyone arrives at the destination ready for a day of relaxation and fun."
        },
        {
            name: "Bus on Rent for Corporate in Hadapsar",
            description: "Our bus rental services in Hadapsar cater to corporate needs, providing reliable and comfortable transportation for business events, meetings, and team-building activities. We understand the demands of corporate schedules and offer flexible rental options to accommodate your needs, ensuring your team arrives on time and in good spirits."
        },
        {
            name: "Bus on Rent for Corporate in Hinjewadi",
            description: "For businesses in Hinjewadi, Shraddha Travels offers exceptional bus rental services tailored to corporate needs. Whether you need transport for an office event or regular employee commutes, our buses provide the comfort and reliability you require. Our local knowledge ensures that we navigate the best routes, avoiding delays and enhancing your travel experience."
        },
        {
            name: "Bus on Rent for Corporate in Kharadi",
            description: "Shraddha Travels provides top-quality bus rental services for corporate clients in Kharadi. Our buses are ideal for corporate travel, offering a comfortable and efficient solution for your business transportation needs. We prioritize customer satisfaction and flexibility, allowing you to adjust your bookings as needed to suit changing corporate schedules."
        },
        {
            name: "Bus on Rent for Corporate in IT Park Pune",
            description: "Located in the IT Park Pune area? Our bus rental services cater specifically to corporate clients in this sector. We offer reliable transport solutions to ensure smooth and professional travel for your team. With our experienced drivers and well-maintained vehicles, we facilitate timely arrivals to meetings and events, helping to foster a productive work environment."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your corporate travel needs in Pune, Shraddha Travels offers unparalleled service and reliability. From bus rentals for team outings to regular corporate commutes, we have the right solution for your business. Our dedicated customer service team is always available to assist you in planning and executing your travel arrangements flawlessly."
        },
        {
            name: "Top Corporate Travel in Pune",
            description: "As a top provider of corporate travel solutions in Pune, Shraddha Travels is dedicated to offering the best in transportation services. Our commitment to quality ensures that your business travel is handled with care and professionalism. We understand the significance of each trip and aim to provide a seamless experience that exceeds your expectations."
        },
        {
            name: "Travel Agency in Pune for Bus",
            description: "Looking for a reliable travel agency in Pune for bus rentals? Shraddha Travels offers comprehensive bus rental services to meet a variety of needs, from corporate events to team outings and more. Our extensive fleet and dedicated support staff ensure that you receive the highest level of service tailored to your specific requirements."
        },
        {
            name: "Corporate Travels in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Corporate Travels in Pune. We ensure a smooth and enjoyable ride for all our customers, with a focus on meeting the specific needs of corporate clients. Our team is ready to provide you with all the information you need for a seamless travel experience."
        }
    ],    
    tableData: [
        ['- Travel Agents For Corporate in Pune', '- Corporate travel company in Pune'],
        ['- Corporate Travel Company in Pimpri Chinchwad', '- Bus On Rent For Corporate in Katraj'],
        ['- Bus Hire for Corporate Party in Pune', '- Bus Hire for Corporate Team Outing in Pune'],
        ['- Bus Hire for Corporate Team Picnic in Pune', '- Pune to Mahabaleshwar Bus Hire for Office Team Picnic'],
        ['- Bus On Rent For Corporate in Hadapsar', '- Bus On Rent for Corporate in Hinjewadi'],
        ['- Bus On Rent for Corporate in Kharadi', '- Bus On Rent for Corporate in IT Park Pune'],
        ['- Shraddha Travels Pune On Rent', '- Top Corporate Travel in Pune'],
        ['- Travel Agency in Pune for Bus', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Corporate Travels in Pune?",
            WhyChoosedescription: "When it comes to corporate travel in Pune, we stand out for our commitment to professionalism and reliability. Our team ensures timely and efficient transportation, meeting the high standards expected by corporate clients. We understand the unique demands of business travel and strive to provide a seamless experience from start to finish."
        },
        {
            WhyChooseheading: "Professionalism and Reliability:",
            WhyChoosedescription: "Our dedicated team understands the importance of punctuality and consistently delivers professional services that our corporate clients can depend on. We value your time and make it our mission to ensure that every journey is executed flawlessly, allowing you to focus on your business priorities."
        },
        {
            WhyChooseheading: "Comfortable Fleet:",
            WhyChoosedescription: "We offer a range of well-maintained vehicles, from executive sedans to spacious coaches, each equipped with modern amenities for a comfortable journey. Our fleet is regularly updated to include the latest models, ensuring a pleasant and enjoyable travel experience for all passengers."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our knowledgeable drivers are well-trained and prioritize safety and comfort, ensuring a smooth travel experience throughout Pune. With extensive experience navigating the city's routes, our drivers provide a level of expertise that enhances your journey, allowing you to relax and focus on your work."
        },
        {
            WhyChooseheading: "Customized Solutions:",
            WhyChoosedescription: "We provide flexible travel solutions tailored to your specific needs, whether for regular office commutes or special event transportation. Our team works closely with you to understand your requirements and design a transportation package that fits your schedule and budget."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "Our transparent and competitive pricing ensures you receive value-for-money services without any hidden charges. We believe in providing high-quality transportation at a fair price, making it easier for businesses to manage their travel budgets effectively."
        },
        {
            WhyChooseheading: "24/7 Support:",
            WhyChoosedescription: "Our customer service team is available around the clock to assist with any queries or changes, ensuring a seamless travel experience. Whether you need last-minute adjustments or have specific requests, our dedicated support team is just a call away to provide immediate assistance."
        },
        {
            WhyChooseheading: "Safety and Hygiene:",
            WhyChoosedescription: "We prioritize the safety and hygiene of our passengers with regularly cleaned and sanitized vehicles, adhering to all safety protocols. In today's environment, we go the extra mile to ensure that our vehicles meet stringent health guidelines, providing peace of mind for you and your team during every journey."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of vehicles are available for corporate travel?",
        answer: "We offer a range of vehicles, including executive sedans, luxury coaches, and shuttle buses, to accommodate different corporate travel needs."
    },
    {
        question: "How can I book corporate travel services with Shraddha Travels?",
        answer: "You can book our services by contacting us via phone, email, or through our website. Provide details about your travel requirements, and we will assist you in planning and booking."
    },
    {
        question: "Are there any additional charges for corporate travel services?",
        answer: "Our pricing is transparent and includes all standard charges. Any additional services or extended hours may incur extra fees, which will be communicated to you in advance."
    },
    {
        question: "Can I customize the travel itinerary for my corporate trip?",
        answer: "Yes, we offer customized solutions to meet your specific travel needs. You can discuss your requirements with us, and we will create a tailored itinerary to suit your schedule."
    },
    {
        question: "What measures do you take to ensure passenger safety?",
        answer: "We prioritize passenger safety by maintaining our vehicles to high standards, employing experienced drivers, and adhering to safety protocols. Our vehicles are also regularly cleaned and sanitized."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, changes to your booking can be made based on availability. Please contact us as soon as possible to discuss any modifications."
    },
    {
        question: "How far in advance should I book corporate travel services?",
        answer: "We recommend booking as early as possible to ensure availability and to allow time for any special arrangements or requirements."
    },
    {
        question: "Do you provide transportation for corporate events?",
        answer: "Yes, we offer transportation services for corporate events, including conferences, seminars, and team-building activities. We can handle both large groups and individual transfers."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies depending on the booking conditions. Please refer to our terms and conditions or contact us for detailed information on cancellations and refunds."
    },
    {
        question: "Are there options for airport transfers?",
        answer: "Yes, we provide airport transfer services as part of our corporate travel solutions. We can arrange pickups and drop-offs to and from Pune Airport."
    }
];

const testimonials = [
    {
        name: "Mr. Vikram Mehta",
        text: "Our company recently used Shraddha Travels for a corporate event, and the experience was exceptional. The professionalism and efficiency displayed by their team were top-notch. The bus was immaculate and well-equipped, making our team’s journey both comfortable and enjoyable. The driver was punctual and courteous, ensuring everything went smoothly. We’ll definitely choose Shraddha Travels for all our future corporate travel needs in Pune!"
    },
    {
        name: "Miss Aditi Sharma",
        text: "Shraddha Travels provided outstanding service for our company’s team-building trip. The bus was not only comfortable and spacious but also featured all the amenities we needed. The entire process, from booking to the actual trip, was seamless and stress-free. Their attention to detail and commitment to quality made a significant difference in our overall experience. I highly recommend Shraddha Travels for corporate travel in Pune!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Corporate Travels in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Corporate Travels in Pune | Affordable & Reliable Services</title>
    <meta name="description" content="Explore top corporate travel services in Pune. We offer bus rentals for team outings, picnics, and corporate events across the city." />
    <meta name="keywords" content="Corporate Travels in Pune, Corporate Travel Agents, Bus on Rent for Corporate, Team Outings, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Corporate Travels in Pune | Affordable & Reliable Services" />
    <meta property="og:description" content="Discover our corporate travel services in Pune, including bus rentals for team outings and corporate events. Customized packages available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/corporate-travels-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/corporate-travel.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Corporate Travels in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/corporate-travels-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Corporate Travel Services in Pune",
                    "description": "Reliable corporate travel services including bus rentals for team outings, picnics, and corporate events across Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -10.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions for Corporate Travels in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Corporattravelsinpune;