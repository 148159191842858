import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const newsData = [
    {
        id: 1,
        category: 'Konkan Tour',
        date: '20 Mar, 2023',
        title: 'Konkan: A symphony of waves, winds, and the whispers of the sea.',
        image: '/img/konkan.jpg',
    },
    {
        id: 2,
        category: 'Himachal Pradesh',
        date: '19 Mar, 2023',
        title: 'In the embrace of the Himalayas, discover the serene beauty of Himachal Pradesh.',
        image: '/img/himachal.jpg',
    },
    {
        id: 3,
        category: 'Ujjain',
        date: '18 Mar, 2023',
        title: 'Ujjain: A sacred journey through time, where the stars align and eternal spirituality reigns.',
        image: '/img/ujjain.jpg',
    },
    {
        id: 4,
        category: 'Ashtavinayak Darshan',
        date: '17 Mar, 2023',
        title: 'Embark on the divine Ashtavinayak Darshan, a spiritual journey through Maharashtra’s eight sacred temples.',
        image: '/img/ashtavinayak.jpg',
    },
    {
        id: 5,
        category: '12 Jyotirlinga Darshan',
        date: '16 Mar, 2023',
        title: 'Experience the power of devotion with the 12 Jyotirlinga Darshan across India.',
        image: '/img/Keyword/Keyword 4-04.jpg',
    },
    {
        id: 6,
        category: 'All India Bus Hire Services from Pune',
        date: '15 Mar, 2023',
        title: 'Reliable and affordable all-India bus hire services starting from Pune for every journey.',
        image: '/img/Keyword/Keyword 4-06.jpg',
    },
    {
        id: 7,
        category: 'Tempo Traveller On Rent in Pune',
        date: '14 Mar, 2023',
        title: 'Comfortable and convenient Tempo Traveller rentals in Pune for family and group trips.',
        image: '/img/Keyword/Keyword image-01.jpg',
    },
    {
        id: 8,
        category: 'Shirdi',
        date: '13 Mar, 2023',
        title: 'Shirdi: Seek blessings at the abode of Sai Baba, a journey of peace and devotion.',
        image: '/img/Keyword/Keyword image-07.jpg',
    },
    {
        id: 9,
        category: 'Mahabaleshwar',
        date: '12 Mar, 2023',
        title: 'Mahabaleshwar: Escape to the lush greenery and serene landscapes of this hill station.',
        image: '/img/Keyword/Keyword image-02.jpg',
    },
];

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

const NewsSection = () => {
    return (
        <section className="news-section pt_100 pb_65">
            <div className="auto-container">
                <div className="sec-title centred mb_50">
                    <h2 className="darkcolor" style={{ color: '#661247' }}>Our Latest Tours</h2>
                </div>
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {newsData.map((news) => (
                        <div key={news.id} className="news-block-one" style={{ border: '3px solid #661247' }}>
                            <div className="inner-box">
                                <figure className="image-box">
                                    <a href={news.link}>
                                        <img src={news.image} alt={news.title} />
                                    </a>
                                </figure>
                                <div className="lower-content">
                                    <ul className="post-info mb_15">
                                        <li>
                                            <a href={news.link}>{news.category}</a>
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href={news.link}>{news.title}</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default NewsSection;
