import React from 'react';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const blogData = [
   
    {
        title: "Mini Bus on Rent in Pune",
        description: "Explore cost-effective and comfortable mini bus rental services in Pune for group travels and events.",
        image: "/img/Keyword/Keyword image-04.jpg",
        link: "#"
    },
    {
        title: "Force Urbania on Rent for Ashtavinayak Darshan",
        description: "Enjoy a spiritual journey with our Force Urbania rentals designed for convenience and comfort during your Ashtavinayak Darshan.",
        image: "/img/Keyword/Keyword image-22.jpg",
        link: "#"
    },
    {
        title: "Pune to Shirdi Mini Bus Package",
        description: "Book a mini bus package from Pune to Shirdi and experience a smooth and memorable pilgrimage trip.",
        image: "/img/Keyword/Keyword 2 -02.jpg",
        link: "#"
    },
    {
        title: "Pune to Mumbai Darshan Bus on Rent",
        description: "Discover Mumbai’s iconic spots with our affordable and reliable bus rental services from Pune.",
        image: "/img/Keyword/Keyword 4-02.jpg",
        link: "#"
    },
    {
        title: "Luxury Bus Rental in Pune",
        description: "Travel in style and comfort with our premium luxury bus rental options for corporate and personal events.",
        image: "/img/Keyword/Keyword image-16.jpg",
        link: "#"
    },
    {
        title: "Tempo Traveller Hire in Pune",
        description: "Hire tempo travellers in Pune for local and outstation trips, ensuring a comfortable and budget-friendly journey.",
        image: "/img/Keyword/Keyword image-22.jpg",
        link: "#"
    },
    {
        title: "Bus Hire for Outstation in Pune",
        description: "Plan your outstation trips with ease using our reliable and spacious bus rental services in Pune.",
        image: "/img/Keyword/Keyword 1-13.jpg",
        link: "#"
    },
    {
        title: "Bus Hire for School Trips in Pune",
        description: "Ensure safety and convenience for school trips with our well-maintained and spacious buses available for rent.",
        image: "/img/Keyword/Keyword 1-01.jpg",
        link: "#"
    },
    {
      title: "Why Choose Shraddha Travels for Your Travel Needs?",
      description: "At Shraddha Travels, we prioritize your comfort and safety, ensuring an unforgettable travel experience across Pune and beyond.",
      image: "/img/blog.webp",
      link: "#"
  }
];

      
    return (
        <div>
            <Helmet>
                <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
                <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
                <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

                {/* Open Graph tags */}
                <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
                <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
                <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

                {/* Schema markup */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Tempo Traveller on Rent in Pune",
                        "provider": {
                            "@type": "Organization",
                            "name": "Shraddha Travels",
                            "url": "https://www.shraddhatravels.com",
                            "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                            "sameAs": [
                                "https://www.facebook.com/shraddhatravels",
                                "https://twitter.com/shraddhatravels",
                                "https://www.instagram.com/shraddhatravels"
                            ]
                        },
                        "areaServed": "Pune",
                        "offers": {
                            "@type": "Offer",
                            "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                            "priceCurrency": "INR",
                            "price": "Variable",
                            "itemOffered": {
                                "@type": "Product",
                                "name": "Tempo Traveller Rental Services in Pune",
                                "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>

            <section id="about" className="jarallax text-light">
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="text-white">Blog</h1>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="row g-4 justify-content-center container">
                    {blogData.map((blog, index) => (
                        <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                            <div className="news-card-items mt-0">
                                <div className="news-image">
                                    <img src={blog.image} alt={blog.title} />
                                </div>
                                <div className="news-content">
                                    <div className="news-cont">
                                        <h3>
                                            <a href={blog.link} style={{ color: '#6A1349' }}>
                                                {blog.title}
                                            </a>
                                        </h3>
                                        <p>{blog.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Blog;
