
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Travelagentsinpune() {



  const cardData =
  {
    keyword: ' Travel Agents in Pune for Mini Bus Hire',
    heading: 'Shraddha Travels: Travel Agents in Pune for Mini Bus Hire',
    headingDescription: 'When you need a mini bus rental in Pune, Shraddha Travels stands out as a premier choice. Whether you are planning a corporate outing, a family event, or a school trip, our mini bus hire services offer a range of features designed to ensure comfort, convenience, and reliability. Here is why our mini bus rentals are the best option for your transportation needs:',
    top: 'Top places to visit in Pune with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Shaniwar Wada",
            description: "A historical fortification, Shaniwar Wada was the seat of the Peshwas of the Maratha Empire. Built in 1732, it showcases stunning architecture with intricate wooden carvings and massive stone walls. The evening light and sound show narrates Maratha history, depicting the valor and glory of the empire, making it a popular tourist spot for both history buffs and families."
        },
        {
            title: "2. Aga Khan Palace",
            description: "This majestic palace is closely linked to India’s freedom movement as it served as a prison for Mahatma Gandhi and other leaders during the Quit India Movement. Its beautiful Italianate architecture, lush gardens, and tranquil atmosphere make it a peaceful place to visit. The palace also houses a museum with photographs and memorabilia from the freedom struggle, offering a glimpse into India's rich history."
        },
        {
            title: "3. Sinhagad Fort",
            description: "Located on a hill, this ancient fort offers breathtaking views of the surrounding landscapes and the Pune cityscape. Sinhagad Fort has significant historical importance, associated with several battles and Maratha legends. Popular among trekkers, it features well-marked trails and provides a chance to explore its ruins, enjoy local snacks at the base, and witness stunning sunsets."
        },
        {
            title: "4. Pataleshwar Cave Temple",
            description: "A rock-cut temple from the 8th century, Pataleshwar Temple is dedicated to Lord Shiva. Carved out of a single rock, the temple features intricate carvings and a peaceful atmosphere that attracts visitors seeking both history and tranquility. It’s an excellent example of ancient rock-cut architecture and a perfect spot for meditation and reflection."
        },
        {
            title: "5. Osho Ashram",
            description: "Located in Koregaon Park, this ashram is a global meditation and wellness center founded by Osho, attracting people from all over the world. It offers various meditation techniques, workshops, and wellness programs in a serene environment. The lush gardens, peaceful ambiance, and daily meditation sessions make it a perfect retreat for those seeking peace and spiritual growth."
        },
        {
            title: "6. Dagdusheth Halwai Ganapati Temple",
            description: "One of Pune’s most revered temples, this shrine is dedicated to Lord Ganesha and is famous for its stunning architecture and vibrant festivals. The temple attracts devotees from all over, especially during Ganesh Chaturthi, when elaborate celebrations take place. The intricate decorations, the grand idol of Ganesha, and the spiritual atmosphere make it a must-visit for both devotees and tourists."
        },
        {
            title: "7. Raja Dinkar Kelkar Museum",
            description: "This museum houses an extensive collection of Indian artifacts, showcasing textiles, weapons, sculptures, and everyday items from various periods of Indian history. Founded by Dr. Dinkar Kelkar, the museum provides a deep insight into India’s cultural heritage through its diverse exhibits, including the famous 'Mastani Mahal' and various art forms that reflect the rich artistic traditions of the country."
        },
        {
            title: "8. Khadakwasla Dam",
            description: "A scenic spot located on the outskirts of Pune, Khadakwasla Dam is a popular weekend getaway. The dam and surrounding areas offer a peaceful environment, perfect for picnics and relaxing by the water. Visitors can enjoy boating, photography, and stunning views of the sunset, along with opportunities for birdwatching, especially during the monsoon season when the landscape becomes lush and vibrant."
        },
        {
            title: "9. Parvati Hill",
            description: "Offering panoramic views of Pune, Parvati Hill is a popular trekking spot that provides a refreshing escape from the city's hustle and bustle. It is home to several temples, including the Parvati Temple dedicated to Lord Shiva, which features beautiful architecture and serene surroundings. The hill is also known for its historic significance and offers a peaceful environment for those looking to connect with nature and spirituality."
        },
        {
            title: "10. Pashan Lake",
            description: "A tranquil spot for nature lovers and birdwatchers, Pashan Lake is ideal for morning or evening walks. The lake becomes a birdwatcher’s paradise during the migratory season, attracting various species of birds. The scenic beauty, coupled with the sound of chirping birds and rustling leaves, creates a perfect backdrop for relaxation, photography, and enjoying nature's tranquility."
        }
    ],    
    services: [
        {
            name: "Tour Agencies in Pune",
            description: "Pune boasts a variety of tour agencies dedicated to curating exceptional travel experiences. Whether you’re interested in local explorations, outstation adventures, or unique travel themes like cultural immersions or eco-tourism, Pune’s tour agencies are well-equipped to handle all aspects of your journey. Their knowledgeable staff can assist with customized itineraries, ensuring you explore Pune's rich heritage, culinary delights, and natural wonders."
        },
        {
            name: "Bus Hire Agency in Pune",
            description: "Finding a reliable bus hire agency in Pune is essential for a stress-free group travel experience. Our bus hire services cater to gatherings of all sizes, from small family outings to large corporate events. Each bus in our fleet is meticulously maintained for safety and comfort, and our professional drivers are trained to ensure a smooth journey. With flexible booking options and competitive rates, we make group travel enjoyable and hassle-free."
        },
        {
            name: "Tourist Agency in Pune",
            description: "Specializing in tailored travel solutions, tourist agencies in Pune provide a one-stop shop for your travel needs. From crafting detailed itineraries to managing bookings for hotels, transport, and guided tours, these agencies handle every detail to ensure a seamless travel experience. Whether you're planning a weekend getaway or an extended holiday, their local expertise will help you uncover hidden gems and must-visit attractions."
        },
        {
            name: "Travel Agents in Pune",
            description: "Travel agents in Pune are dedicated professionals who specialize in planning travel that suits your unique preferences and budget. They take the hassle out of travel arrangements, whether for leisure, business, or pilgrimage. With access to exclusive deals and insider knowledge, they can recommend the best destinations, accommodations, and activities, ensuring you get the most value for your money while enjoying a memorable trip."
        },
        {
            name: "Pune Tours and Travels Agents",
            description: "Discover the beauty and history of Pune with the help of local tours and travels agents. They offer diverse packages tailored to your interests, from historical tours and cultural experiences to nature excursions. With their guidance, you can navigate Pune’s attractions efficiently, ensuring you maximize your time exploring this vibrant city while creating unforgettable memories."
        },
        {
            name: "Bus Hire Agents in Pune",
            description: "Our bus hire agents in Pune are dedicated to providing you with the perfect vehicle for any occasion. Whether you need transportation for corporate events, school trips, or family gatherings, our agents will work with you to find the right solution. With a variety of bus sizes and styles available, you can count on us for comfort and convenience, allowing your group to travel together easily and enjoyably."
        },
        {
            name: "Best Traveller Bus on Rent in Pune",
            description: "When it comes to group travel, choosing the best traveller bus on rent in Pune is crucial for a comfortable journey. Our fleet includes high-end traveller buses equipped with modern amenities like reclining seats, air conditioning, and entertainment systems. Perfect for longer trips, these buses ensure that every passenger enjoys a luxurious travel experience while making memories together."
        },
        {
            name: "Urbania Bus on Rent in Pune for Corporate Outing",
            description: "For corporate outings, renting an Urbania bus in Pune combines elegance and comfort. These premium buses are designed with spacious interiors and modern features, making them ideal for team-building events, business meetings, or client transportation. With a focus on professionalism, our Urbania buses provide an environment conducive to work or relaxation while traveling."
        },
        {
            name: "Travel Agents in Pimpri Chinchwad",
            description: "Travel agents in Pimpri Chinchwad are known for their local expertise and personalized service. They cater to a diverse clientele, helping travelers plan everything from business trips to leisure vacations. With their extensive knowledge of local attractions and accommodations, they can create customized itineraries that enhance your travel experience while addressing your specific needs."
        },
        {
            name: "Pune Travel Agency Contact Number",
            description: "Having the contact number of a reliable Pune travel agency is essential for quick assistance with travel plans and bookings. Whether you need help with itineraries, hotel reservations, or local tours, our agency is just a call away, ready to support your travel needs and provide valuable insights for your journey."
        },
        {
            name: "Bus Hire for Events Agency",
            description: "If you're organizing an event, our bus hire for events agency can help ensure that your transportation is seamless and efficient. From weddings to corporate gatherings, we provide tailored solutions to accommodate your guests comfortably. Our experienced drivers and well-maintained vehicles guarantee that your attendees arrive on time and in style, allowing you to focus on the event itself."
        },
        {
            name: "Bus Hire for Corporate Events & Tours",
            description: "Our bus hire services for corporate events and tours are designed with professionalism and comfort in mind. We offer a fleet of buses equipped with modern amenities, ensuring a smooth travel experience for your corporate group. From transporting employees to team-building retreats to facilitating client meetings, we prioritize your needs to enhance your corporate travel experience."
        },
        {
            name: "Bus Booking for Corporate Events",
            description: "Bus booking for corporate events requires careful planning and coordination. Our dedicated team provides timely and efficient bus booking solutions, managing all logistics to ensure your corporate events run smoothly. With our expertise, you can focus on the business aspect while we handle the transportation needs of your attendees."
        },
        {
            name: "Bus on Hire for Pune to Mahabaleshwar Package Tour",
            description: "Experience the scenic beauty of Mahabaleshwar with our bus on hire service from Pune. Ideal for package tours, our buses are equipped to provide a pleasant journey, featuring comfortable seating and essential amenities. Whether you're planning a day trip or an extended stay, our service ensures you reach this popular hill station relaxed and ready to explore."
        },
        {
            name: "Tour Operators in Pune",
            description: "Tour operators in Pune excel at managing travel itineraries for a variety of destinations. Their expertise encompasses everything from planning trips and arranging accommodations to coordinating activities and excursions. With a focus on delivering memorable travel experiences, they help you navigate all aspects of your journey, ensuring you make the most of your time."
        },
        {
            name: "Best Tours and Travel Company in PCMC",
            description: "For top-notch travel services in PCMC, the best tours and travel company offers comprehensive solutions tailored to your travel needs. They handle everything from local tours to international travel arrangements, ensuring reliability and efficiency at every step. With their attention to detail, you can relax and enjoy your journey knowing that everything is taken care of."
        },
        {
            name: "Best Tours and Travel Company in Pune",
            description: "In Pune, the best tours and travel company is committed to providing exceptional service for all your travel requirements. Their offerings include customized tour packages, transportation arrangements, and accommodation solutions, all designed to create a seamless travel experience. With their local knowledge and expertise, they help you discover Pune and beyond in comfort and style."
        },
        {
            name: "Travel Agents in Pune for Mini Bus Hire Contact Number",
            description: "For prompt and efficient mini bus hire, contact Shraddha Travels at +91 8087847191. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all customers. Experience the convenience of our reliable service and travel comfortably with your group."
        }
    ],    
    tableData: [
        ['- Tour Agencies in Pune', '- Bus Hire Agency in Pune'],
        ['- Travel Agents in Pune', '- Pune Tours and Travels Agents'],
        ['- Bus Hire Agents in Pune', '- Best Traveller Bus on Rent in Pune'],
        ['- Urbania Bus on Rent in Pune for Corporate Outing', '- Travel Agents in Pimpri Chinchwad'],
        ['- Pune Travel Agency Contact Number', '- Bus Hire for Events Agency'],
        ['- Bus Booking for Corporate Events', '- Bus on Hire for Pune to Mahabaleshwar Package Tour'],
        ['- Tour Operators in Pune', '- Bus Hire for Corporate Events & Tours'],
        ['- Tourist Agency in Pune', '- Best Tours and Travel Company in PCMC'],
        ['- Best Tours and Travel Company in Pune', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Travel Agents in Pune for Mini Bus Hire?",
            WhyChoosedescription: "When it comes to hiring a mini bus in Pune, Shraddha Travels stands out as a reliable provider. We ensure your travel experience is comfortable, safe, and tailored to your needs. Here's why you should choose us for your mini bus hire:"
        },
        {
            WhyChooseheading: "Extensive Fleet:",
            WhyChoosedescription: "We offer a diverse range of mini buses to accommodate different group sizes and travel needs, whether for corporate events, family gatherings, or school trips."
        },
        {
            WhyChooseheading: "Exceptional Comfort:",
            WhyChoosedescription: "Our mini buses are designed with passenger comfort in mind, featuring plush seating, air conditioning, and modern amenities for a pleasant travel experience."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "Punctuality and reliability are our top priorities, ensuring that your mini bus is always on time and ready for your journey."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We offer competitive rates for our mini bus hire services without compromising quality, with a transparent pricing structure for excellent value."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced drivers are well-trained and knowledgeable about Pune’s routes and prioritize safety and customer satisfaction."
        },
        {
            WhyChooseheading: "Customized Solutions:",
            WhyChoosedescription: "We provide personalized solutions tailored to your specific trip needs, whether for a special event or a leisurely outing."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our dedicated customer service team assists you at every step, ensuring a seamless and hassle-free experience from booking to the end of your journey."
        },
        {
            WhyChooseheading: "Well-Maintained Vehicles:",
            WhyChoosedescription: "All our mini buses undergo regular maintenance and safety checks, ensuring they are clean, reliable, and in optimal condition for your trips."
        },
        {
            WhyChooseheading: "Flexible Booking Options:",
            WhyChoosedescription: "We offer flexible booking options to accommodate your schedule, whether for a few hours or several days."
        },
        {
            WhyChooseheading: "Commitment to Safety:",
            WhyChoosedescription: "Safety is our top priority, with our mini buses equipped with essential safety features and drivers adhering to all safety regulations."
        }
    ]
      }


      const faqData = [
        {
            question: "What types of mini buses are available for hire?",
            answer: "We offer a range of mini buses, including 14-seater, 17-seater, 20-seater, and 25-seater capacities. Each bus is equipped with comfortable seating and modern amenities to suit your travel needs."
        },
        {
            question: "How can I book a mini bus with Shraddha Travels?",
            answer: "Booking a mini bus is simple. You can contact us via phone or email, or use our online booking system. Our travel agents will assist you in choosing the right vehicle and finalizing the details of your booking."
        },
        {
            question: "What are the payment options for mini bus hire?",
            answer: "We accept various payment methods including cash, credit/debit cards, and online transfers. Payment details will be discussed and finalized during the booking process."
        },
        {
            question: "Are the mini buses equipped with air conditioning?",
            answer: "Yes, all our mini buses are equipped with air conditioning to ensure a comfortable ride for all passengers, regardless of the weather."
        },
        {
            question: "Can I customize my mini bus hire package?",
            answer: "Absolutely. We offer customizable packages to meet your specific needs. Whether you require additional amenities or special arrangements, our travel agents will work with you to tailor the package to your preferences."
        },
        {
            question: "Are the drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced, professional, and well-trained. They are familiar with local routes and committed to providing a safe and pleasant journey."
        },
        {
            question: "What should I do if there is a problem during the journey?",
            answer: "Our customer service team is available to assist you at any time. If you encounter any issues during your journey, please contact us immediately so we can address the situation promptly."
        },
        {
            question: "How far in advance should I book a mini bus?",
            answer: "We recommend booking as early as possible to ensure availability, especially during peak times or for special events. Early booking helps us accommodate your preferred vehicle and schedule."
        },
        {
            question: "Are there any additional charges or hidden fees?",
            answer: "No, we believe in transparency. All costs will be clearly outlined in your booking agreement, and there will be no hidden fees. If there are any additional charges, they will be communicated to you upfront."
        },
        {
            question: "What happens if I need to cancel or modify my booking?",
            answer: "Cancellation and modification policies will be explained at the time of booking. We strive to be flexible and accommodate changes, but please note that there may be certain conditions and fees depending on the timing of your request."
        }
    ];
    

    const testimonials = [
        {
            name: "Mr. Mandeep Singh",
            text: "I recently used Shraddha Travels for a corporate event and was thoroughly impressed with their mini bus hire service. The Urbania mini bus we rented was immaculate, with plush seating and top-notch amenities. Our team of 17 enjoyed a comfortable and smooth ride, which was crucial for a long journey from Pune to Mumbai. The driver was professional and well-acquainted with the route, ensuring we arrived on time without any hassle. The booking process was straightforward, and the pricing was transparent with no hidden charges. Shraddha Travels has certainly set a high standard for group transportation in Pune. Highly recommended for anyone needing reliable and luxurious mini bus rentals."
        },
        {
            name: "Ms. Neha Ratotiya",
            text: "I booked a mini bus from Shraddha Travels for a family reunion, and the experience was outstanding. The Force Urbania mini bus we hired was spacious and comfortable, perfect for our 13-seater requirement. The air-conditioning worked perfectly, keeping us cool throughout the trip. The entertainment system was a hit with the kids, making the journey enjoyable for everyone. The driver was punctual and courteous, adding to the overall excellent service. What stood out the most was the transparency in pricing and the seamless booking process. If you’re looking for a top-notch mini bus hire service in Pune, Shraddha Travels is the way to go!"
        }
    ];
    

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "  Travel Agents in Pune for Mini Bus Hire Contact Number:",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Travel Agents in Pune for Mini Bus Hire</title>
    <meta name="description" content="Explore top travel agents in Pune for mini bus hire. Best rates for corporate events, tours, and packages!" />
    <meta name="keywords" content="Travel agents in Pune, Mini bus hire Pune, Pune tours and travels, Bus hire agency Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Travel Agents in Pune for Mini Bus Hire" />
    <meta property="og:description" content="Find the best travel agents in Pune for mini bus hire, corporate outings, and event bookings. Contact us today!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/travel-agents-in-pune-mini-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/minibus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Mini Bus Hire in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/travel-agents-in-pune-mini-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus Hire Services",
                    "description": "Hire mini buses in Pune for corporate events, tours, and packages with competitive rates."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-21.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Travel Agents in Pune for Mini Bus Hire - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Travelagentsinpune;