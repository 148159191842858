import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Testimonial data
const testimonialsData = [
    {
        id: 1,
        name: 'Miss. Priya Shah',
        image: 'https://via.placeholder.com/100?text=Priya',
        rating: 5,
        text: '“I had a fantastic experience with Shraddha Travels for our trip to Mahabaleshwar. The driver was punctual and friendly, making the journey comfortable. Highly recommend!”',
    },
    {
        id: 2,
        name: 'Mr. Rahul Mehta',
        image: 'https://via.placeholder.com/100?text=Rahul',
        rating: 5,
        text: '“Shraddha Travels made our Ujjain trip memorable! Our driver was professional and guided us to all the best spots. A hassle-free experience!”',
    },
    {
        id: 3,
        name: 'Mrs. Anjali Kulkarni',
        image: 'https://via.placeholder.com/100?text=Anjali',
        rating: 5,
        text: '“The service was excellent! The car was clean, and the driver ensured a smooth ride for our family trip to Lonavala.”',
    },
    {
        id: 4,
        name: 'Mr. Rajesh Patel',
        image: 'https://via.placeholder.com/100?text=Rajesh',
        rating: 4,
        text: '“I appreciate the professionalism of Shraddha Travels. Our trip to Shirdi was comfortable, and the driver was very cooperative.”',
    },
    {
        id: 5,
        name: 'Miss. Nidhi Tiwari',
        image: 'https://via.placeholder.com/100?text=Nidhi',
        rating: 5,
        text: '“A seamless experience! The booking process was easy, and our Pune to Goa journey was enjoyable.”',
    },
    {
        id: 6,
        name: 'Mr. Sameer Rao',
        image: 'https://via.placeholder.com/100?text=Sameer',
        rating: 5,
        text: '“The driver was knowledgeable and helped us discover hidden gems during our visit to the Konkan region. Fantastic service!”',
    },
    {
        id: 7,
        name: 'Mrs. Kavita Mishra',
        image: 'https://via.placeholder.com/100?text=Kavita',
        rating: 5,
        text: '“Shraddha Travels exceeded our expectations for our weekend getaway to Lavasa. Will definitely book again!”',
    },
    {
        id: 8,
        name: 'Mr. Deepak Verma',
        image: 'https://via.placeholder.com/100?text=Deepak',
        rating: 5,
        text: '“Affordable and reliable! The driver was courteous, and our trip to Nashik was very smooth.”',
    },
    {
        id: 9,
        name: 'Miss. Sneha Desai',
        image: 'https://via.placeholder.com/100?text=Sneha',
        rating: 4,
        text: '“Great service overall! Our journey to Mumbai was comfortable, and the driver was very professional.”',
    },
    {
        id: 10,
        name: 'Mr. Vishal Sharma',
        image: 'https://via.placeholder.com/100?text=Vishal',
        rating: 5,
        text: '“Highly satisfied with Shraddha Travels for our Aurangabad tour. The car was clean, and the service was punctual!”',
    },
];


const TestimonialSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="testimonial-section pt_100 pb_90 py-5">
            <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)' }}></div>
            <div className="auto-container">
                <div className="sec-title centred mb_50">
                    <span className="sub-title">Testimonials</span>
                    {/* <h2>Love from our Clients</h2> */}
                </div>
                <Slider {...settings}>
                    {testimonialsData.map(testimonial => (
                        <div className="testimonial-block-one" key={testimonial.id}>
                            <div className="inner-box">
                                <div className="author-box">
                                    <h3 className='text-white'>{testimonial.name}</h3>
                                </div>
                                <p>{testimonial.text}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* CSS styles */}
            <style jsx>{`
                .testimonial-section {
                    overflow: hidden; /* Prevents horizontal scroll */
                    width: 100%;
                }

                .auto-container {
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 0 15px; /* Prevents horizontal overflow */
                    box-sizing: border-box;
                }

                .testimonial-block-one {
                    background: white;
                    border-radius: 8px;
                    padding: 20px;
                    margin: 0 10px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease;
                }

                .testimonial-block-one:hover {
                    transform: scale(1.03);
                }

              

                .sec-title h2 {
                    font-size: 2rem;
                    color: #333;
                }

                @media (max-width: 768px) {
                    .sec-title h2 {
                        font-size: 1.5rem;
                    }
                }

            `}</style>
        </section>
    );
};

export default TestimonialSection;
