
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Monthlybushireservice() {



  const cardData =
  {
    keyword: 'Monthly Bus Hire Service in Pune',
    heading: 'Shraddha Travels: Monthly Bus Hire Service in Pune',
    headingDescription: 'Shraddha Travels offers comprehensive monthly bus hire services in Pune, providing convenient and cost-effective solutions for corporate transportation, school trips, group travel, and more. With our extensive fleet of modern buses and a team of experienced drivers, we ensure that your monthly travel needs are met with comfort, reliability, and flexibility. Whether you need transportation for employees, students, or frequent trips, Shraddha Travels is here to serve you with the highest level of professionalism and convenience.',
    top: 'Top Monthly Bus Hire Services by Shraddha Travels',

    topPlaces: [
        {
            title: "1. Corporate Employee Transportation",
            description: "Shraddha Travels specializes in monthly bus hire services for corporate offices in Pune. Our buses are ideal for daily employee commutes, ensuring safe, punctual, and comfortable travel between work locations and employee residences. We understand the importance of reliability in corporate transport, so we focus on providing a seamless experience that enhances employee productivity."
        },
        {
            title: "2. School and College Bus Rentals",
            description: "Schools and colleges in Pune rely on Shraddha Travels for monthly bus hire services. We provide well-maintained buses for daily student transportation, ensuring safety, comfort, and timely pickups and drop-offs. Our drivers are trained to handle young passengers with care, making every journey to and from school a safe and pleasant experience."
        },
        {
            title: "3. Corporate Shuttle Service",
            description: "For businesses in Pune with frequent travel needs, our corporate shuttle service offers a convenient monthly hire option. We provide a dedicated bus to shuttle employees between offices, branches, and client locations within the city. This service helps reduce the stress of commuting, allowing employees to arrive refreshed and ready for work."
        },
        {
            title: "4. Monthly Bus Hire for Industrial Plants",
            description: "Shraddha Travels offers specialized monthly bus hire services for factories and industrial plants in Pune. Our buses are equipped to transport workers from various locations to industrial sites, ensuring timely and efficient transport. We understand the logistical challenges faced by industrial operations, and our reliable service helps maintain productivity."
        },
        {
            title: "5. IT Park and Tech Company Transport",
            description: "Shraddha Travels provides tailored monthly bus services for IT parks and technology companies in Pune. Our buses come with modern amenities like Wi-Fi and comfortable seating, making long commutes easier for tech professionals. We aim to create a stress-free travel environment, allowing staff to catch up on work or relax during their journey."
        },
        {
            title: "6. Outstation Monthly Bus Rentals",
            description: "If your organization requires regular outstation trips from Pune, we offer monthly bus hire services that cater to long-distance travel. Our buses are ideal for companies with branch offices or frequent outstation meetings, ensuring that employees travel comfortably and arrive on time. We handle all logistics so you can focus on your business."
        },
        {
            title: "7. Tourist Guide and Tour Operator Monthly Bus Rentals",
            description: "For travel agencies and tour operators, Shraddha Travels offers a convenient monthly bus hire service. We provide reliable buses that meet your tourism business needs, whether for city tours or outstation excursions. Our experienced drivers are familiar with popular routes and can assist with itineraries, enhancing the overall travel experience for your clients."
        },
        {
            title: "8. Monthly Bus Rentals for Religious Organizations",
            description: "Religious groups and organizations in Pune can benefit from our monthly bus hire services. We offer safe and comfortable buses for frequent pilgrimages, temple visits, or religious events. Our focus on punctuality and comfort ensures that all participants can concentrate on their spiritual journey without transportation worries."
        },
        {
            title: "9. Wedding and Event Transport",
            description: "Shraddha Travels provides monthly bus hire for wedding planners and event organizers. Our buses ensure hassle-free guest transportation for multiple events across Pune. We understand the importance of timing and coordination in events, and our team works closely with organizers to facilitate smooth transportation for guests, allowing everyone to enjoy the celebrations."
        },
        {
            title: "10. Monthly Bus Hire for Sports Teams and Academies",
            description: "For sports teams, academies, and fitness centers in Pune, Shraddha Travels offers buses on a monthly rental basis. We have the right bus for transporting teams to practice sessions, tournaments, or fitness camps. Our drivers are punctual and understand the needs of athletes, ensuring a smooth journey to help them perform at their best."
        }
    ],    
    services: [
        {
            name: "35, 36, 40, 54, and 55 Seater Bus Hire in Pune",
            description: "Looking for a bus that perfectly fits your group size? We offer a diverse range of buses, including 35, 36, 40, 54, and 55-seater options to cater to various travel needs in Pune. Whether it’s for a family outing, corporate event, or a large group tour, our buses provide comfort and reliability."
        },
        {
            name: "35, 36, 40, 47, 54, and 55 Seater Company Bus Rental Service in Pune",
            description: "Our company bus rental services in Pune include a variety of options: 35, 36, 40, 47, 54, and 55-seater buses. Ideal for corporate events, employee transport, and team-building activities, we ensure a smooth and professional experience with our well-maintained fleet."
        },
        {
            name: "Bus on Rent in Pune with Shraddha Travels",
            description: "Shraddha Travels offers an extensive range of buses on rent in Pune. Whether you need a small shuttle or a large charter bus, we have the right vehicle for your needs. Our competitive rates and exceptional service make us a top choice for bus rentals in Pune."
        },
        {
            name: "Monthly Bus Hire for Corporate Employees",
            description: "Streamline your employee transportation with our monthly bus hire services. Perfect for corporate clients, our buses offer a convenient and cost-effective solution for daily commutes and business-related travel."
        },
        {
            name: "35 Seater Bharat Benz Bus on Rent",
            description: "Experience the ultimate in comfort with our 35-seater Bharat Benz buses. Designed for efficiency and luxury, these buses provide a smooth ride for group travel. Ideal for corporate trips, family gatherings, and more."
        },
        {
            name: "Luxury Bus on Rent in Pune",
            description: "For those seeking an upscale travel experience, our luxury buses on rent in Pune offer top-tier amenities and exceptional comfort. Perfect for high-profile events, corporate functions, or special occasions."
        },
        {
            name: "57 Seater AC Bharat Benz on Rent in Pune",
            description: "Our 57-seater AC Bharat Benz buses are perfect for larger groups looking for air-conditioned comfort and spacious seating. Ideal for long-distance travel and large-scale events, these buses offer a premium travel experience."
        },
        {
            name: "Monthly Bus on Rent in Kharadi IT Park",
            description: "If you need a reliable transportation solution for your employees at Kharadi IT Park, our monthly bus rental service is the answer. We provide punctual and comfortable bus services to ensure smooth commutes for your team."
        },
        {
            name: "Shraddha Travels Pune on Rent",
            description: "For all your bus rental needs in Pune, Shraddha Travels provides top-quality vehicles and services. Whether it’s a luxury bus, a corporate shuttle, or a large group transport, we ensure a hassle-free and enjoyable experience. Contact us for competitive rates and personalized service."
        },
        {
            name: "Monthly Bus Hire Service in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Monthly Bus Hire Service in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
    tableData: [
        ['- 35, 36, 40, 54, 55 Seater Bus Hire in Pune', '- 35, 36, 40, 47, 54, 55 Seater Company Bus Rental Service in Pune'],
        ['- Bus on Rent in Pune with Shraddha Travels', '- Monthly Bus Hire for Corporate Employees'],
        ['- 35 Seater Bharat Benz Bus on Rent', '- Luxury Bus on Rent in Pune'],
        ['- 57 Seater AC Bharat Benz on Rent in Pune', '- Monthly Bus on Rent in Kharadi IT Park'],
        ['- Shraddha Travels Pune on Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Monthly Bus Hire Service in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we provide exceptional monthly bus hire services tailored to your needs. With a commitment to quality, safety, and customer satisfaction, we ensure that every aspect of your travel experience is seamless and enjoyable. Here's why you should choose us for your travel requirements:"
        },
        {
            WhyChooseheading: "Comprehensive Fleet:",
            WhyChoosedescription: "We offer a diverse range of buses, from comfortable minibuses to spacious luxury coaches, capable of accommodating various group sizes and travel requirements. Whether you need a small vehicle for an intimate gathering or a larger coach for corporate events, our fleet has the perfect solution for every occasion."
        },
        {
            WhyChooseheading: "Reliable and Punctual Service:",
            WhyChoosedescription: "Our well-maintained buses and committed drivers ensure timely transportation, meeting your travel needs on schedule every time. We understand that time is valuable, and we pride ourselves on our punctuality, allowing you to plan your itinerary with confidence."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our highly trained and licensed drivers are familiar with Pune’s routes, providing safe and courteous service for a smooth journey. With extensive experience in handling various road conditions, they prioritize passenger safety and comfort, making your trip as pleasant as possible."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We offer flexible monthly rental packages that can be tailored to fit your specific needs, whether for daily commutes, occasional trips, or special event transport. Our team will work closely with you to design a package that aligns with your schedule and budget, ensuring maximum convenience."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Equipped with modern amenities such as air conditioning, comfortable seating, and entertainment options, our buses prioritize passenger comfort for a pleasant travel experience. We strive to create an inviting atmosphere, allowing you to relax and enjoy the journey."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We provide competitive pricing for our monthly bus hire services, offering cost-effective solutions without compromising on quality. Our transparent pricing model ensures that you know exactly what you’re paying for, with no hidden fees or surprises."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with bookings and address any queries or concerns. Whether you need help planning your itinerary or require immediate assistance during your trip, we're just a call away."
        },
        {
            WhyChooseheading: "Safety and Maintenance:",
            WhyChoosedescription: "We conduct regular maintenance checks on all our buses, ensuring they are in top condition and equipped for safe travel. Our stringent safety protocols mean you can travel with peace of mind, knowing that your safety is our top priority."
        },
        {
            WhyChooseheading: "Flexible Booking Options:",
            WhyChoosedescription: "Our booking process is straightforward and flexible, making it easy to arrange your transport needs with minimal hassle. Whether you need to book a bus for a specific date or an ongoing monthly arrangement, we accommodate your requirements with ease."
        },
        {
            WhyChooseheading: "Customer Satisfaction:",
            WhyChoosedescription: "We are committed to high-quality service, ensuring that every journey is a positive experience for our clients. Your feedback is invaluable to us, and we continuously strive to improve our services based on your needs and preferences."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for monthly hire?",
        answer: "We offer a variety of buses, including 18-seater minibuses, 32-seater deluxe buses, and 50-seater luxury coaches. Our fleet is designed to accommodate different group sizes and travel needs, ensuring that we have the perfect vehicle for every occasion."
    },
    {
        question: "How do I book a monthly bus hire service?",
        answer: "You can book our monthly bus hire service easily through our website or by contacting our customer support team. We provide a straightforward booking process and can create a customized quote based on your specific requirements."
    },
    {
        question: "What is included in the monthly bus hire package?",
        answer: "Our monthly bus hire package includes the bus, driver services, fuel, and toll taxes. We can also arrange additional services, such as extra stops or special requests, to enhance your travel experience."
    },
    {
        question: "Can I customize the monthly bus hire package?",
        answer: "Yes, we offer customizable packages to meet your specific needs. You can adjust the service to include particular routes, schedules, and amenities based on your preferences, making your travel experience as tailored as possible."
    },
    {
        question: "Are your buses equipped with amenities?",
        answer: "Absolutely! Our buses are equipped with modern amenities such as air conditioning, comfortable seating, and entertainment systems. We prioritize passenger comfort to ensure a pleasant travel experience for everyone."
    },
    {
        question: "How do you ensure the safety of passengers?",
        answer: "We conduct regular maintenance checks on all our buses and adhere to strict safety protocols. Our drivers are trained to follow safety standards, ensuring a secure travel experience for all passengers."
    },
    {
        question: "What are your pricing options for monthly bus hire?",
        answer: "Pricing varies based on the type of bus, the duration of the hire, and any additional services required. For a customized quote that reflects your specific needs, please contact us directly."
    },
    {
        question: "Can I make changes to my booking?",
        answer: "Yes, you can make changes to your booking by contacting us in advance. We strive to accommodate modifications based on availability and your requirements, ensuring flexibility in your travel plans."
    },
    {
        question: "Do you offer services for special events?",
        answer: "Yes, our monthly bus hire services can be used for special events, including corporate functions, weddings, and group outings. We can tailor our services to meet the unique needs of your event, ensuring a seamless experience."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a flexible cancellation policy. If you need to cancel or reschedule your booking, please contact us as soon as possible. Cancellation terms may vary depending on the package and booking conditions, so it's best to check with us."
    }
];


const testimonials = [
    {
        name: "Mr. Arvind Sharma",
        text: "We’ve been using Shraddha Travels for our monthly bus hire service in Pune for the past six months, and the experience has been outstanding. Their buses are always punctual, clean, and well-maintained, making our daily employee commutes smooth and stress-free. The staff is professional and responsive, handling all our requests with efficiency. Our employees appreciate the comfort and reliability of the service, which has significantly improved our overall transportation logistics. I highly recommend Shraddha Travels for any company looking for dependable and high-quality bus hire services."
    },
    {
        name: "Miss Sneha Patel",
        text: "Shraddha Travels has been our go-to provider for monthly bus hire in Pune, and we couldn’t be happier with their service. Their buses are top-notch, and the drivers are courteous and professional. The flexibility and reliability of their service have greatly benefited our company, especially during peak hours and busy seasons. The consistent quality and attention to detail make them a standout choice for corporate transportation. We look forward to continuing our partnership with Shraddha Travels and would recommend them to any organization in need of a reliable bus rental service."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Monthly Bus Hire Service in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Monthly Bus Hire Service in Pune | Affordable Corporate Solutions</title>
    <meta name="description" content="Discover our Monthly Bus Hire Service in Pune, offering a variety of seating options for corporate and group travel. Reliable and affordable." />
    <meta name="keywords" content="Monthly Bus Hire Service in Pune, 35 Seater Bus Rental, Corporate Bus Hire, Luxury Bus on Rent, Bus on Rent in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Monthly Bus Hire Service in Pune | Affordable Corporate Solutions" />
    <meta property="og:description" content="Explore our Monthly Bus Hire Services in Pune for corporate employees, with a variety of buses available for your needs." />
    <meta property="og:url" content="https://www.shraddhatravels.com/monthly-bus-hire-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/monthly-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Monthly Bus Hire Service in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/monthly-bus-hire-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Monthly Bus Hire Services in Pune",
                    "description": "Reliable and affordable monthly bus hire services in Pune, ideal for corporate employees and group travel."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Monthly Bus Hire Service in Pune for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Monthlybushireservice;