
import React from 'react';
import BusRatesTable from './BusRatesTable';
import { Helmet } from 'react-helmet';

const Packages = () => {
  return (
   <div>
    <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

<section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Packages</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
<BusRatesTable/>

<div className="rules-guidelines container">
  <h2>Rules and Guidelines:</h2>
  <ul className="rules-list">
    <li>- Full toll, parking, passenger tax, GST, and the cost of special permits are to be paid by the party.</li>
    <li>- Taxes of other states (outside Maharashtra) are to be paid by the party.</li>
    <li>- The time and meter readings for parking will be noted before and after the journey.</li>
    <li>- For a trip, the average daily distance allowed is 300 km; different rates apply for the Mumbai-Mahabaleshwar route (Mumbai package allows 350 km).</li>
    <li>- Provide a list of passengers' names and travel destinations and submit it to the office at least four days before departure.</li>
    <li>- The bus service will be available from 6:00 AM to 12:00 midnight.</li>
    <li>- If the bus usage extends beyond 12:00 midnight, an additional charge of Rs. 400/- will apply for each subsequent hour until 2:00 AM, after which the entire 300 km rate will be charged for the next day.</li>
    <li>- In case of using the bus with air conditioning, an extra charge of Rs. 500/- per hour will apply.</li>
    <li>- While booking the bus, 50% of the total amount along with the security deposit must be paid at least 2 days in advance before the trip.</li>
    <li>- If a booking is suddenly canceled, 50% of the total amount must be paid as a cancellation fee.</li>
    <li>- The given rates are based on the current diesel prices (Rs. 100.00 per liter) and are subject to change according to the current R.T.O. taxes.</li>
    <li>- The bus toll will be deducted through FASHTAG, and you will be responsible for paying the entire toll amount during the payment process.</li>
    <li>- We strive to provide the best services, including clean and well-maintained vehicles and responsible drivers who do not consume alcohol.</li>
    <li>- There are no hidden or extra charges.</li>
  </ul>

  <h2>नियम व अटी</h2>
  <ul className="rules-list">
    <li>- संपूर्ण टोल, पार्किंग, प्रवासी कर, जी.एस.टी. आणि स्पेशल परमिटचा खर्च पार्टीने द्यावयाचा आहे.</li>
    <li>- इतर राज्यांचे कर (महाराष्ट्राबाहेरील) पार्टीने भरावयाचा आहे.</li>
    <li>- वेळ व मीटर रिडींग पार्किंग ते पार्किंग असेल.</li>
    <li>- ट्रीपसाठी प्रति दिवस ३०० कि.मी. अॅव्हरेज राहील / मुंबई-महावळेश्वरचे दर वेगळे राहतील.</li>
    <li>- प्रवाशांच्या नावांची यादी व प्रवासाची ठिकाणे व्यवस्थित नमूद करून किमान ४ दिवस अगोदर ऑफिसमध्ये जमा करावी.</li>
    <li>- बस वापरण्याचा कालावधी पहाटे ६.०० ते रात्री १२.०० पर्यंत राहील.</li>
    <li>- जर रात्री १२.०० नंतर बसचा वापर झाल्यास पुढील प्रत्येक तासाला रू. ४००/- आकार पडेल.</li>
    <li>- ए.सी. बस पार्किंग असतांना बसचा ए.सी. चालू केल्यास प्रत्येक तासाला रु.५००/- वेगळा चार्ज द्यावा लागेल.</li>
    <li>- बस बुक करतेवेळेस ५०% अनामत व उर्वरित रक्कम बस ट्रीपला जाण्याच्या २ दिवस अगोदर भरावयाची आहे.</li>
    <li>- अचानक बुकिंग कॅन्सल केल्यास संपूर्ण रकमेच्या ५०% रकमेची भरावी लागेल.</li>
    <li>- वरील दिलेले दर हे सध्या चालू स्थीतीतील डिझेलच्या किंमतीनुसार आहेत.</li>
    <li>- बसचा टोल FASHTAG वरून कापला जाईल त्यामुळे तुम्हाला संपूर्ण पेमेंट प्रक्रियेदरम्यान टोल शुल्काची रक्कम भरावी लागेल.</li>
    <li>- आम्ही आमचे सर्वोत्तम देण्याचा प्रयत्न करतो.</li>
    <li>- कोणतेही छुपे अतिरिक्त शुल्क नाही.</li>
  </ul>
</div>

   </div>
  );
};

export default Packages;
