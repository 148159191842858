
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function TempoTravelleronrentinpimpri() {



  const cardData =
  {
    keyword: 'Tempo Traveller on Rent in Pimpri Chinchwad ',
    heading: 'Shraddha Travels: Tempo Traveller on Rent in Pimpri Chinchwad ',
    headingDescription: 'Are you planning a family trip, corporate outing, or group tour in Pimpri Chinchwad? Look no further! Shraddha Travels offers the best Tempo Traveller on Rent in Pimpri Chinchwad, designed to provide comfort, convenience, and affordability. Whether you’re traveling within the city or to nearby destinations, our well-maintained tempo travellers are perfect for making your journey enjoyable and hassle-free.',
    top: 'Top Places to Visit in Pimpri Chinchwad with Shraddha Travels',

    topPlaces: [
      {
        title: "1. Talwarkar Gymnastics and Swimming Complex",
        description: "This facility offers top-notch gymnastics training and a swimming pool, making it popular among sports enthusiasts. It's an excellent place for both children and adults to learn and participate in various athletic activities, promoting fitness and a healthy lifestyle."
      },
      {
        title: "2. Mahalunge Balewadi Sports Complex",
        description: "A modern sports facility that hosts a variety of events and training programs. With a focus on promoting sports culture in the region, it features a stadium and courts for multiple sports, making it a hub for athletes and sports lovers to come together and hone their skills."
      },
      {
        title: "3. Shivaji Udyan",
        description: "A beautiful urban park that serves as a green oasis in the city. It boasts well-maintained gardens, walking paths, and play areas for children, providing a peaceful environment for relaxation and recreation. It's an ideal spot for families and individuals seeking a break from the hustle and bustle of urban life."
      },
      {
        title: "4. PCMC Museum",
        description: "This museum is dedicated to preserving the cultural heritage of the region. It features a variety of exhibits, including artifacts and historical displays, offering insights into the area's history and development. Visitors can explore the rich cultural narrative and learn about the local traditions that shape the community."
      },
      {
        title: "5. ISKCON Temple",
        description: "A stunning temple that offers a serene atmosphere for worship and reflection. Known for its beautiful architecture and vibrant festivals, the temple attracts devotees and visitors seeking spiritual solace. It’s a place where one can immerse themselves in devotion and enjoy the peaceful surroundings."
      },
      {
        title: "6. Siddhivinayak Mandir",
        description: "A revered temple dedicated to Lord Ganesha, Siddhivinayak Mandir is known for its intricate carvings and peaceful ambiance. Many devotees visit to seek blessings, especially during festivals, making it a significant spiritual site in the community. The temple’s artistic beauty and tranquil environment enhance the experience for all visitors."
      },
      {
        title: "7. Kharadi Hills",
        description: "Ideal for nature lovers and adventure seekers, Kharadi Hills provides opportunities for trekking and nature walks. The area offers breathtaking views and a chance to connect with nature, making it a perfect getaway for those looking to escape the city and explore the great outdoors."
      },
      {
        title: "8. E-Square Multiplex",
        description: "A premier entertainment destination, E-Square features the latest films, comfortable seating, and a variety of dining options. It’s the perfect spot for a family outing or a casual hangout with friends, offering a complete entertainment experience with options for food and fun."
      },
      {
        title: "9. Bhosari Gaon",
        description: "A lively area known for its local markets and vibrant community life. Bhosari Gaon offers a glimpse into everyday life in Pimpri Chinchwad, with street food stalls and local handicrafts to explore. It's an excellent place for those interested in experiencing local culture and flavors."
      },
      {
        title: "10. Pimpri Chinchwad Science Park",
        description: "An innovative educational space designed to inspire curiosity and learning. The park features interactive science exhibits that engage children and families, making it an enjoyable and informative destination for school trips and family outings alike."
      }
    ],    
    services: [
      {
        name: "Tempo Traveller on Rent in Pimpri Chinchwad",
        description: "Shraddha Travels offers Tempo Traveller on Rent in Pimpri Chinchwad for all types of group travel. Whether you're planning a family trip, corporate outing, or pilgrimage, our well-maintained tempo travellers ensure a comfortable and safe journey. With seating options ranging from 9 to 24 passengers, we provide the flexibility to choose a vehicle that best suits your group's size and requirements. Our tempo travellers are equipped with modern amenities such as air-conditioning, comfortable seating, and ample luggage space, making your travel experience seamless and enjoyable."
      },
      {
        name: "17 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
        description: "Looking for a spacious and comfortable vehicle for your group of 17 passengers? Our 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad is the ideal choice. Perfect for corporate groups, family outings, or large gatherings, this tempo traveller comes with air-conditioning, luxury seats, and ample legroom to ensure a relaxing journey. With experienced drivers and well-maintained vehicles, Shraddha Travels promises a smooth ride to any destination from Pimpri Chinchwad."
      },
      {
        name: "13 Seater Tempo Travellers On Rent in Pimpri Chinchwad",
        description: "The 13 Seater Tempo Traveller on Rent in Pimpri Chinchwad is perfect for mid-sized groups. Whether you're heading to a wedding, family function, or religious tour, this vehicle provides comfort and convenience. With modern amenities like a music system, LED lights, and air-conditioning, your journey will be comfortable and entertaining. Our tempo travellers are ideal for local and outstation trips, ensuring a hassle-free travel experience."
      },
      {
        name: "9, 12, 14, 17, 20, 24 Seater Tempo Traveller on Rent in Pune",
        description: "Shraddha Travels offers a variety of seating options to cater to groups of all sizes. Whether you're looking for a 9, 12, 14, 17, 20, or 24 Seater Tempo Traveller on Rent in Pune, we have the right vehicle for your needs. These tempo travellers are perfect for everything from family trips to corporate tours, featuring spacious interiors, air-conditioning, and professional drivers. Enjoy a smooth ride and reach your destination comfortably with our reliable tempo traveller services."
      },
      {
        name: "17 Seater Tempo Traveller on Rent in Baner",
        description: "Are you located in Baner and in need of a comfortable travel solution for your group? Shraddha Travels offers a 17 Seater Tempo Traveller on Rent in Baner to make your trip stress-free. This vehicle is perfect for long-distance trips, corporate outings, and family vacations. With luxury seating, ample legroom, and powerful air-conditioning, your journey will be as comfortable as possible. Book today for a convenient and enjoyable group travel experience."
      },
      {
        name: "Tempo Traveller Hire in Hinjewadi",
        description: "For those located in Hinjewadi, Shraddha Travels provides Tempo Traveller Hire in Hinjewadi to make your group travel easy and convenient. Whether you're heading out for a corporate trip or a family vacation, our tempo travellers come in various seating options to accommodate your group size. All vehicles are air-conditioned, equipped with entertainment systems, and driven by experienced professionals to ensure a smooth and secure journey."
      },
      {
        name: "Tempo Traveller Hire in Aundh, Pune",
        description: "If you're based in Aundh and looking for reliable group transport, Shraddha Travels offers Tempo Traveller Hire in Aundh, Pune. Our tempo travellers are perfect for group outings, pilgrimages, and weddings. With spacious interiors, reclining seats, and air-conditioning, our tempo travellers provide the perfect combination of comfort and style for your next journey."
      },
      {
        name: "Pimpri Chinchwad to Shirdi Tempo Traveller on Rent",
        description: "Planning a religious trip to Shirdi from Pimpri Chinchwad? Shraddha Travels offers Pimpri Chinchwad to Shirdi Tempo Traveller on Rent for a comfortable and hassle-free journey. Our tempo travellers are ideal for group pilgrimages, offering ample space, comfortable seating, and experienced drivers who are well-versed with the route. Enjoy a peaceful trip to Shirdi in our well-maintained tempo travellers."
      },
      {
        name: "Tempo Traveller On Rent in Bhosari",
        description: "Shraddha Travels provides Tempo Traveller on Rent in Bhosari for local and outstation trips. Whether you're heading out for a wedding, corporate event, or group tour, our tempo travellers are perfect for accommodating 9 to 24 passengers. With features like air-conditioning, ample luggage space, and luxury seats, our vehicles ensure a comfortable and safe ride for your group."
      },
      {
        name: "25 Seater Bus on Rent in Pune",
        description: "For larger groups, Shraddha Travels offers a 25 Seater Bus on Rent in Pune. Ideal for corporate events, school trips, or family functions, this bus provides spacious seating, air-conditioning, and ample storage for luggage. With an experienced driver at the wheel, you can enjoy your journey knowing you're in safe hands. Book a 25-seater bus today for a smooth and enjoyable group travel experience in Pune."
      },
      {
        name: "25 Seater Tempo Traveller on Rent in Pune Contact Number",
        description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 25 Seater Tempo Traveller on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
      }
    ],    
    tableData: [
      ['- Tempo Traveller on Rent in Pimpri Chinchwad', '- 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
      ['- 13 Seater Tempo Travellers On Rent in Pimpri Chinchwad', '- 9, 12, 14, 17, 20, 24 Seater Tempo Traveller on Rent in Pune'],
      ['- 17 Seater Tempo Traveller on Rent in Baner', '- Tempo Traveller Hire in Hinjewadi'],
      ['- Tempo Traveller Hire in Aundh, Pune', '- Pimpri Chinchwad to Shirdi Tempo Traveller on Rent'],
      ['- Tempo Traveller On Rent in Bhosari', '- 25 Seater Bus on Rent in Pune']
    ],    
    whychoose: [
      {
        WhyChooseheading: "Why Choose Shraddha Travels for Tempo Traveller on Rent in Pimpri Chinchwad?",
        WhyChoosedescription: "When it comes to group travel in Pimpri Chinchwad, Shraddha Travels stands out as the best choice for hiring a Tempo Traveller. Here’s why you should choose us for your next trip:"
      },
      {
        WhyChooseheading: "Diverse Fleet Options:",
        WhyChoosedescription: "We offer a wide range of tempo travellers, from 9-seaters to 24-seaters, ensuring you can choose the perfect vehicle for your group size."
      },
      {
        WhyChooseheading: "Comfort and Luxury:",
        WhyChoosedescription: "Our tempo travellers are designed for maximum comfort, featuring cushioned reclining seats, air-conditioning, ample legroom, and advanced suspension systems."
      },
      {
        WhyChooseheading: "Well-Maintained Vehicles:",
        WhyChoosedescription: "Every vehicle in our fleet is regularly inspected and serviced, ensuring top-notch performance and cleanliness."
      },
      {
        WhyChooseheading: "Experienced and Professional Drivers:",
        WhyChoosedescription: "Our tempo travellers are driven by experienced, licensed drivers who know the local routes well and prioritize courteous behavior."
      },
      {
        WhyChooseheading: "Affordable Pricing:",
        WhyChoosedescription: "We offer competitive pricing with a transparent policy, ensuring no hidden costs and excellent value for your money."
      },
      {
        WhyChooseheading: "Customizable Travel Packages:",
        WhyChoosedescription: "Whether you're planning a short trip or a long journey, we provide customizable travel packages to fit your schedule and budget."
      },
      {
        WhyChooseheading: "24/7 Availability:",
        WhyChoosedescription: "We operate round the clock, ready to serve you whenever you need to travel, with prompt booking assistance and support."
      },
      {
        WhyChooseheading: "Safety First:",
        WhyChoosedescription: "Our tempo travellers are equipped with GPS tracking, seat belts, and first-aid kits, adhering strictly to all safety regulations."
      },
      {
        WhyChooseheading: "On-Time Service:",
        WhyChoosedescription: "We pride ourselves on punctuality, ensuring our drivers are there on time, so you reach your destination without delays."
      },
      {
        WhyChooseheading: "Excellent Customer Reviews:",
        WhyChoosedescription: "Shraddha Travels has earned a reputation for exceptional service, and our satisfied customers can attest to a smooth, comfortable, and memorable experience."
      }
    ]
    
  }



  const faqData = [
    {
      question: "What seating options do you offer for tempo travellers?",
      answer: "Shraddha Travels offers a variety of seating options including 9, 12, 13, 14, 17, 20, and 24-seater tempo travellers, ensuring you find the perfect vehicle for your group size."
    },
    {
      question: "Can I book a tempo traveller for outstation trips?",
      answer: "Yes, you can! We provide tempo traveller services not just for local travel but also for outstation trips from Pimpri Chinchwad to popular destinations like Shirdi, Mahabaleshwar, and Lonavala."
    },
    {
      question: "How do I book a tempo traveller with Shraddha Travels?",
      answer: "You can easily book a tempo traveller by contacting us via phone, email, or through our website. Our 24/7 customer service team is always ready to assist with bookings and inquiries."
    },
    {
      question: "Are your tempo travellers equipped with air-conditioning?",
      answer: "Yes, all our tempo travellers are fully air-conditioned to provide a comfortable and pleasant travel experience, especially during long trips and in hot weather."
    },
    {
      question: "Do your tempo travellers have enough luggage space?",
      answer: "Yes, our tempo travellers are designed with ample luggage space to accommodate the baggage of all passengers comfortably."
    },
    {
      question: "Is there an entertainment system in your tempo travellers?",
      answer: "Yes, our tempo travellers come equipped with entertainment features such as LED TVs, music systems, and charging ports, ensuring an enjoyable journey for all passengers."
    },
    {
      question: "Do you offer tempo traveller services for weddings and events?",
      answer: "Absolutely! We specialize in providing tempo travellers for weddings, corporate events, family functions, and group tours. We ensure that your guests travel in comfort and style."
    },
    {
      question: "Is it possible to customize my trip itinerary with Shraddha Travels?",
      answer: "Yes, we offer customizable travel packages. Whether you need a tempo traveller for a short trip or a long-distance journey, we can tailor our services to fit your specific needs and schedule."
    },
    {
      question: "What safety measures do you have in place?",
      answer: "Safety is our priority. All tempo travellers are equipped with GPS tracking, seat belts, and first-aid kits. Our drivers are also experienced and trained to prioritize the safety of all passengers."
    },
    {
      question: "How much does it cost to rent a tempo traveller in Pimpri Chinchwad?",
      answer: "The cost depends on the size of the tempo traveller and the duration of your rental. We offer competitive and transparent pricing with no hidden charges. Contact us for a personalized quote based on your travel requirements."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Rakesh Patil",
      text: "We recently rented a 17-seater tempo traveller from Shraddha Travels for a family trip from Pimpri Chinchwad to Mahabaleshwar, and the experience was fantastic! The vehicle was clean, spacious, and well-maintained, providing a comfortable journey for everyone. Mr. Rakesh, the driver, was polite, punctual, and knew the route well, which made the trip stress-free. Shraddha Travels' customer service was also top-notch, and the booking process was seamless. I highly recommend their tempo traveller rental service for any group travel needs!"
    },
    {
      name: "Miss Sonal Desai",
      text: "Shraddha Travels made our corporate outing from Pimpri Chinchwad to Lonavala absolutely perfect. We hired a 20-seater tempo traveller, and the entire team was impressed with the comfort and luxury of the vehicle. The air-conditioning, reclining seats, and entertainment system added to the pleasant journey. Miss Sonal, our coordinator, ensured everything went smoothly with the booking and driver arrangements. Overall, Shraddha Travels provided an excellent tempo traveller rental experience, and we will definitely choose them again for future trips!"
    }
  ];
  


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Tempo Traveller on Rent in Pimpri Chinchwad Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pimpri Chinchwad | Affordable 17 Seater Rentals</title>
    <meta name="description" content="Rent Tempo Travellers in Pimpri Chinchwad for local and outstation trips. Options available from 9 to 25 seats at competitive rates!" />
    <meta name="keywords" content="Tempo traveller on rent in Pimpri Chinchwad, 17 Seater tempo traveller on rent, 13 Seater Tempo Travellers, Tempo Traveller hire in Hinjewadi, Pimpri Chinchwad to Shirdi tempo traveller" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pimpri Chinchwad | Affordable 17 Seater Rentals" />
    <meta property="og:description" content="Explore Tempo Traveller rentals in Pimpri Chinchwad for various group sizes. Comfortable travel options available!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-on-rent-pimpri-chinchwad" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pimpri-chinchwad.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental in Pimpri Chinchwad",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pimpri Chinchwad, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-on-rent-pimpri-chinchwad",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Comfortable Tempo Traveller rentals in Pimpri Chinchwad for group travel."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-10.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs - "Tempo Traveller on Rent in Pimpri Chinchwad" Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default TempoTravelleronrentinpimpri;