
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetokonkan() {



  const cardData =
  {
    keyword: ' Pune to Konkan Darshan Bus Hire	',
    heading: 'Shraddha Travels: Pune to Konkan Darshan Bus Hire	',
    headingDescription: 'If you’re planning a trip from Pune to the enchanting Konkan region, Shraddha Travels offers the perfect bus hire solutions to make your journey comfortable and memorable. The Konkan coastline, known for its scenic beauty, lush green landscapes, and pristine beaches, is a must-visit destination for travelers seeking both relaxation and adventure. Our dedicated bus services ensure you travel in comfort and style, exploring the best that Konkan has to offer.',
    top: 'Top Places to Visit in Konkan with Shraddha Travels:',

    topPlaces: [
        {
          title: "1. Ganpatipule",
          description: "Renowned for its beautiful beaches and the Swayambhu Ganpati Temple, Ganpatipule is a serene coastal destination perfect for relaxation and spiritual experiences. The temple, built on a hill overlooking the Arabian Sea, attracts thousands of devotees. Visitors can also enjoy water sports, local cuisine, and the breathtaking sunset views from the beach."
        },
        {
          title: "2. Alibaug",
          description: "Known for its sandy beaches, historic forts, and vibrant local markets, Alibaug is a popular getaway for beach lovers and history enthusiasts alike. The coastal town features attractions like the Kolaba Fort and beaches such as Alibaug and Varsoli, where one can indulge in water activities. The area is also famous for its delicious seafood and nearby historical sites."
        },
        {
          title: "3. Kunkeshwar",
          description: "This town is famous for its ancient Kunkeshwar Temple dedicated to Lord Shiva and its picturesque beaches. The temple, with its beautiful architecture and serene surroundings, attracts many pilgrims. Kunkeshwar’s beaches, such as Kunkeshwar Beach, offer a tranquil setting for relaxation and beach activities, making it a perfect blend of spirituality and leisure."
        },
        {
          title: "4. Ratnagiri",
          description: "Ratnagiri is known for its historical significance and beautiful beaches. Key attractions include the Ratnadurg Fort, which provides stunning views of the coastline, and Thibaw Palace, showcasing colonial architecture. The serene Mandvi Beach is ideal for unwinding, while the area is also famous for its delicious mangoes, particularly the Alphonso variety."
        },
        {
          title: "5. Malvan",
          description: "Malvan is famous for its stunning Tarkarli Beach, known for its clear waters and water sports like snorkeling and scuba diving. The Sindhudurg Fort, located on an island, offers a glimpse into the region's rich history. Malvan is also celebrated for its vibrant local seafood cuisine, with numerous eateries serving authentic Malvani dishes that are a must-try."
        },
        {
          title: "6. Dapoli",
          description: "Dapoli offers a range of attractions including pristine beaches, historic temples, and scenic hill stations. Visit the serene Murud Beach for a quiet day or explore the Keshavraj Temple, known for its spiritual significance. The area is also great for dolphin watching and enjoying panoramic views from the hills, making it an excellent destination for nature lovers."
        },
        {
          title: "7. Janjira Fort",
          description: "Located off the coast of Murud, Janjira Fort is a formidable sea fort with a rich history. The fort, built by the Siddis, boasts impressive architecture and provides panoramic views of the Arabian Sea. Accessible by boat, exploring the fort reveals ancient cannons and structures, making it a fascinating place for history buffs and adventure seekers alike."
        },
        {
          title: "8. Harihareshwar",
          description: "This town is known for the Harihareshwar Temple and its tranquil beaches. The temple, dedicated to Lord Shiva, is a major pilgrimage site. Harihareshwar's beaches, with their calm waters and scenic views, offer a peaceful destination for relaxation. The area is also ideal for hiking and exploring the surrounding natural beauty, providing a perfect escape from the city hustle."
        },
        {
          title: "9. Vengurla",
          description: "Vengurla offers beautiful beaches, historic temples, and a peaceful ambiance. The Vengurla Rocks and local temples such as the Shree Vengurla Ganesh Temple provide a unique experience. The serene beaches are perfect for leisurely strolls, and the vibrant local markets offer fresh produce and handmade crafts, making Vengurla a charming spot to visit."
        },
        {
          title: "10. Guhagar",
          description: "Guhagar is a quiet beach town known for its pristine beaches, ancient temples, and scenic beauty. Guhagar Beach, with its soft sands and calm waters, is ideal for relaxation and water sports. The Velneshwar Temple, dedicated to Lord Shiva, adds a spiritual touch to the visit. The surrounding landscape of coconut groves and hills makes Guhagar a picturesque getaway."
        }
      ],      
      services: [
        {
          name: "Pune to Konkan Darshan Tempo Traveller Package",
          description: "Discover the scenic beauty and cultural heritage of Konkan with our customized Tempo Traveller packages. Ideal for group tours, our packages include comfortable transport to key attractions such as beaches, temples, and forts. We ensure a memorable and hassle-free experience with well-planned itineraries and stops at must-see destinations."
        },
        {
          name: "Mini Bus on Rent in Ratnagiri",
          description: "For those visiting Ratnagiri, our mini buses offer a fantastic solution for group travel. Enjoy spacious seating with ample legroom, air conditioning, and modern amenities as you explore this beautiful coastal region. Our knowledgeable drivers will guide you to popular attractions like Ratnadurg Fort and Mandvi Beach, enhancing your overall experience."
        },
        {
          name: "Pune to Palghar Bus on Rent",
          description: "Travel from Pune to Palghar effortlessly with our reliable bus rental service. Our well-maintained buses are equipped with comfortable seating, entertainment options, and professional drivers. Whether you’re planning a beach outing or a visit to local attractions, we guarantee a smooth and enjoyable journey for all passengers."
        },
        {
          name: "Pune to Raigad Darshan Bus on Rent",
          description: "Our bus rental service for Pune to Raigad Darshan provides a comfortable and convenient way to visit the historic Raigad Fort. With a focus on safety and comfort, our well-equipped buses offer amenities like Wi-Fi and refreshments, ensuring a pleasant ride. Enjoy the stunning views and rich history as you explore this significant landmark."
        },
        {
          name: "Pune to Sindhudurg Bus Hire for Picnic",
          description: "Plan a fun and relaxing picnic to Sindhudurg with our bus hire services. Perfect for family outings or group excursions, our buses provide ample space and comfort for a pleasant trip to scenic beaches and forts. We also offer customizable itineraries to include local dining experiences, ensuring a memorable day out."
        },
        {
          name: "Pune to Konark Bus Hire for Picnic",
          description: "Enjoy a memorable picnic with our bus hire service for travel from Pune to Konark. Our buses ensure comfort and reliability, featuring spacious seating and entertainment options. Discover the beauty of this famous pilgrimage site while enjoying the company of friends and family in a hassle-free environment."
        },
        {
          name: "Pune to Imagica Bus Hire for Picnic",
          description: "Make your trip to Imagica theme park enjoyable with our dedicated bus hire services. Designed for family outings or group trips, our buses come equipped with modern amenities. We provide door-to-door service, ensuring a fun-filled day at one of India’s premier amusement parks without the stress of travel logistics."
        },
        {
          name: "Pune to Sawantwadi Bus Hire for Picnic",
          description: "Explore the vibrant town of Sawantwadi with our bus hire service. Ideal for picnics and group excursions, our buses are comfortable and spacious, allowing you to relax and enjoy the journey. Visit local attractions, including the famous Sawantwadi Palace, and experience the unique culture of this charming town."
        },
        {
          name: "Pune to Konkan Darshan Urbania on Rent",
          description: "Travel in style with our Urbania rental for a Konkan Darshan. This luxurious vehicle is perfect for larger groups or special occasions, offering plush interiors, ample space, and premium amenities. Experience the beauty of Konkan with the utmost comfort as you visit stunning beaches and cultural sites."
        },
        {
          name: "Pune to Devgad Bus on Rent for Picnic",
          description: "Our bus rental service for travel to Devgad is ideal for a relaxing picnic. Enjoy the scenic beauty of Devgad with our comfortable buses, which include features like reclining seats and air conditioning. We ensure a stress-free journey as you explore local attractions such as the Devgad Fort and beautiful beaches."
        },
        {
          name: "Hire Pune to Diveagar Mini Bus & Tempo Traveller on Rent",
          description: "For trips to Diveagar, choose from our mini buses and Tempo Travellers. Both options provide comfort and flexibility for exploring this charming coastal village. With experienced drivers and well-equipped vehicles, enjoy seamless travel to beautiful beaches and temples, making your outing truly memorable."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "Shraddha Travels is your go-to provider for vehicle rentals in Pune. Whether you're planning a family trip, corporate event, or special occasion, our diverse fleet includes buses, mini buses, tempo travellers, and Urbania rentals tailored to your needs. We prioritize customer satisfaction and ensure a smooth travel experience."
        },
        {
          name: "Ganpatipule Tour Package from Pune",
          description: "Experience the divine beauty of Ganpatipule with our comprehensive tour package from Pune. Our package includes comfortable transport, guided tours of the Swayambhu Ganpati Temple, and opportunities to explore the picturesque beaches. Enjoy a well-planned itinerary designed to make your visit to this sacred site unforgettable."
        },
        {
          name: "Pune to Alibaug Bus on Rent",
          description: "Travel to the coastal town of Alibaug with our reliable bus rental service. Ideal for beach outings and group excursions, our buses are equipped with modern amenities for a comfortable journey. Enjoy visiting local forts, vibrant markets, and pristine beaches as you create lasting memories with your group."
        },
        {
          name: "Pune to Konkan Darshan Bus Package",
          description: "Our Konkan Darshan bus package offers a complete solution for exploring the Konkan region. Enjoy a well-organized tour that includes comfortable transportation and guided visits to key attractions like beaches, temples, and historical sites. We take care of all logistics, allowing you to focus on enjoying the beauty of Konkan."
        },
        {
          name: "Tempo Traveller on Rent for Konkan Trip from Pune",
          description: "If you're planning a trip to Konkan from Pune, our Tempo Travellers are an excellent choice for comfortable and spacious travel. Designed for group excursions, these vehicles come equipped with modern amenities, ensuring you enjoy every moment of your journey through the scenic landscapes of Konkan."
        },
        {
          name: "Pune to Ratnagiri Tempo Traveller Hire on Rent",
          description: "Travel from Pune to Ratnagiri with ease using our Tempo Traveller rental service. Ideal for both small and large groups, our Tempo Travellers ensure a smooth and comfortable ride to one of Konkan's most scenic destinations. Experience personalized service with a knowledgeable driver who can guide you through the area."
        },
        {
          name: "Pune to Konkan Darshan Urbania on Rent",
          description: "Experience luxury and comfort with our Urbania rental for your Konkan Darshan. Perfect for larger groups or special occasions, the Urbania provides a high-end travel experience, complete with premium amenities like reclining seats and entertainment systems for a truly enjoyable journey."
        },
        {
          name: "Pune to Ratnagiri Tempo Traveller Hire on Rent Near Me",
          description: "Looking for a reliable Tempo Traveller rental for travel from Pune to Ratnagiri? Our service offers convenient options for hiring a Tempo Traveller near you. We prioritize your comfort and ensure you get the vehicle you need for a smooth and enjoyable journey."
        },
        {
          name: "Pune to Konkan Darshan Mini Bus on Rent",
          description: "For a more compact group travel option, our mini buses are ideal for Konkan Darshan. Enjoy the convenience and comfort of our mini buses, which feature comfortable seating and climate control, as you explore the scenic beauty and rich culture of Konkan."
        },
        {
          name: "Pune to Konkan Darshan Urbania on Rent",
          description: "Travel in style with our Urbania rentals for your Konkan Darshan. This luxurious vehicle is designed for groups seeking a premium experience, offering spacious interiors and high-end features, ensuring your journey is as enjoyable as your destination."
        },
        {
          name: "Pune to Goa Urbania on Rent",
          description: "Make your trip to Goa memorable with our Urbania rentals. Ideal for groups and special events, the Urbania offers a luxurious and comfortable ride, complete with modern amenities to enhance your journey from Pune to Goa, allowing you to relax and enjoy the ride."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "Shraddha Travels is your trusted partner for all vehicle rental needs from Pune. We provide a range of options including Tempo Travellers, mini buses, and Urbania rentals to suit your travel requirements. Our commitment to quality service ensures a smooth and enjoyable travel experience for every trip."
        },
        {
          name: "Pune to Konkan Darshan Bus Hire Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Konkan Darshan Bus Hire in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride, prioritizing your comfort and satisfaction throughout the journey."
        }
      ],      
      tableData: [
        ['- Pune to Konkan Darshan Tempo Traveller Package', '- Mini Bus On Rent in Ratnagiri'],
        ['- Pune to Palghar Bus on Rent', '- Pune to Raigad Darshan Bus on Rent'],
        ['- Pune to Sindhudurg Bus Hire for Picnic', '- Pune to Konark Bus Hire for Picnic'],
        ['- Pune to Imagica Bus Hire for Picnic', '- Pune to Sawantwadi Bus Hire for Picnic'],
        ['- Pune to Konkan Darshan Urbania on Rent', '- Pune to Devgad Bus on Rent for Picnic'],
        ['- Hire Pune to Diveagar Mini Bus & Tempo Traveller on Rent', '- Shraddha Travels Pune On Rent'],
        ['- Ganpatipule Tour Package from Pune', '- Pune to Alibaug Bus on Rent'],
        ['- Pune to Konkan Darshan Bus Package', '- Tempo Traveller on Rent for Konkan Trip from Pune'],
        ['- Pune to Ratnagiri Tempo Traveller Hire on Rent', '- Pune to Konkan Darshan Urbania on Rent'],
        ['- Pune to Ratnagiri Tempo Traveller Hire on Rent Near Me', '- Pune to Konkan Darshan Mini Bus on Rent'],
        ['- Pune to Goa Urbania on Rent', '- Shraddha Travels Pune On Rent']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune to Konkan Darshan Bus Hire?",
          WhyChoosedescription: "When it comes to traveling from Pune to Konkan, Shraddha Travels stands out for its commitment to providing top-notch bus hire services. Whether you're planning a family vacation, a group outing, or a pilgrimage tour, we ensure a comfortable and hassle-free journey. Our focus on customer satisfaction and attention to detail makes us the preferred choice for many travelers looking to explore the beautiful Konkan coast."
        },
        {
          WhyChooseheading: "Diverse Fleet of Vehicles:",
          WhyChoosedescription: "We offer a variety of buses, including 32, 35, 40, 45, and 50-seater options, to accommodate different group sizes and preferences. Each vehicle is meticulously maintained and equipped with modern amenities such as GPS navigation, entertainment systems, and onboard refreshments, ensuring a convenient and enjoyable journey for all passengers."
        },
        {
          WhyChooseheading: "Comfort and Luxury:",
          WhyChoosedescription: "Our buses are designed for maximum comfort, featuring spacious seating with ample legroom, air conditioning, and clean interiors. Plush upholstery and thoughtful design elements create an inviting atmosphere, allowing you to relax and enjoy the stunning landscapes of Konkan during your trip."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are highly trained and experienced, familiar with the best routes and traffic patterns. They prioritize safety, ensuring a smooth and enjoyable ride while being attentive to your needs throughout the trip. Their friendly demeanor adds a personal touch to your travel experience."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "We offer transparent and competitive pricing with no hidden charges. Our rental packages are designed to provide value for money, catering to various budget needs. We believe that quality travel shouldn't break the bank, and we strive to make our services accessible to everyone."
        },
        {
          WhyChooseheading: "Flexible Packages:",
          WhyChoosedescription: "Whether you need a one-day trip or an extended tour, we offer flexible rental packages tailored to your itinerary and requirements. Our team works closely with you to understand your preferences, ensuring that your travel plans align perfectly with your expectations and interests."
        },
        {
          WhyChooseheading: "Excellent Customer Service:",
          WhyChoosedescription: "Our dedicated customer support team is available to assist you with bookings, inquiries, and any issues that may arise during your trip. We pride ourselves on our responsiveness and commitment to ensuring that every aspect of your journey is smooth and enjoyable, from the initial inquiry to the completion of your trip."
        },
        {
          WhyChooseheading: "Well-Maintained Vehicles:",
          WhyChoosedescription: "Safety and reliability are our top priorities. Our buses undergo regular maintenance and thorough inspections to ensure they are in excellent condition for your journey. We adhere to strict safety protocols and standards, giving you peace of mind while traveling."
        },
        {
          WhyChooseheading: "Customized Itineraries:",
          WhyChoosedescription: "We can help you plan your trip and customize the itinerary to include must-visit spots in Konkan, such as pristine beaches, historical sites, and local attractions. Our local expertise allows us to recommend the best places to visit, ensuring you make the most of your journey and experience the rich culture and natural beauty of the region."
        }
      ]
      
  }



  const faqData = [
    {
      question: "What types of buses are available for Pune to Konkan Darshan?",
      answer: "We offer a range of buses including 32, 35, 40, 45, and 50-seaters. Our fleet includes both standard and luxury options to suit your group size and comfort preferences."
    },
    {
      question: "How do I book a bus for Konkan Darshan?",
      answer: "You can book a bus by contacting us directly through our website or customer service. Simply provide details of your trip, including dates, number of passengers, and any specific requirements."
    },
    {
      question: "Are the buses equipped with air conditioning?",
      answer: "Yes, all our buses come with air conditioning to ensure a comfortable journey, regardless of the weather."
    },
    {
      question: "How much does it cost to hire a bus for Konkan Darshan?",
      answer: "The cost depends on various factors including the type of bus, duration of hire, and the distance traveled. We provide transparent pricing and can offer a detailed quote based on your specific requirements."
    },
    {
      question: "Can I customize the itinerary for my Konkan trip?",
      answer: "Absolutely! We can help you plan and customize your itinerary to include the top attractions and destinations in Konkan, ensuring you have a memorable experience."
    },
    {
      question: "Are there any additional charges apart from the rental fee?",
      answer: "Our pricing is transparent, and we strive to avoid hidden charges. Any additional costs, such as tolls or parking fees, will be communicated in advance."
    },
    {
      question: "How do I ensure the safety of my group during the trip?",
      answer: "We prioritize safety and ensure all our buses are regularly maintained and inspected. Our experienced drivers are trained to handle various road conditions and ensure a safe journey."
    },
    {
      question: "What if I need to make changes to my booking?",
      answer: "If you need to make changes to your booking, please contact us as soon as possible. We will do our best to accommodate your changes based on availability and any applicable terms and conditions."
    },
    {
      question: "Is there a provision for luggage storage on the bus?",
      answer: "Yes, our buses are equipped with ample luggage storage space to accommodate your belongings safely during the trip."
    },
    {
      question: "How far in advance should I book the bus?",
      answer: "We recommend booking as early as possible to secure your preferred vehicle and dates. However, we can also accommodate last-minute bookings based on availability."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. and Mrs. Deshmukh",
      text: "Our trip from Pune to Konkan with Shraddha Travels was absolutely fantastic! The bus was comfortable and spacious, making our journey enjoyable for the entire group. Our driver was not only skilled but also knowledgeable about the region, sharing interesting insights along the way. Shraddha Travels made the entire experience smooth and hassle-free. I highly recommend them for anyone looking to explore Konkan!"
    },
    {
      name: "Mr. and Miss Kumar",
      text: "I recently booked a bus for a family trip to Konkan with Shraddha Travels, and it was one of the best decisions we made! The service was excellent from start to finish. The bus was clean and well-maintained, and the amenities provided made our trip more enjoyable. The staff was very accommodating, and they ensured we had everything we needed. I can’t wait to plan our next adventure with them!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune to Konkan Darshan Bus Hire Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title> Contact: +91 8087847191 | Shraddha Travels | Shraddha Travels Pune to Konkan Darshan Bus Hire | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Explore the scenic beauty of Konkan with our bus rental services from Pune. Enjoy comfortable journeys to popular destinations like Ganpatipule, Ratnagiri, and more." />
    <meta name="keywords" content="Pune to Konkan Darshan, Tempo Traveller Package, Mini Bus on Rent, Pune to Ratnagiri, Pune to Palghar, Pune to Raigad, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Pune to Konkan Darshan Bus Hire | Shraddha Travels | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Reliable bus rental services for Konkan Darshan from Pune, including options for family trips and group travel." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-to-konkan-darshan-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/konkan-darshan-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Konkan Darshan Bus Hire",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-to-konkan-darshan-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services for Konkan Darshan from Pune",
                    "description": "Comfortable and affordable bus rental services for Konkan Darshan, including options for group travel and family trips."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-02.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Pune to Konkan Darshan Bus Hire with Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetokonkan;