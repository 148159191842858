
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforkedarnath() {



  const cardData =
  {
    keyword: ' Bus Hire For Kedarnath Trip',
    heading: 'Shraddha Travels: Bus Hire For Kedarnath Trip',
    headingDescription: 'Shraddha Travels offers exceptional bus hire services for your Kedarnath trip, ensuring a seamless and comfortable journey to one of India’s most revered pilgrimage sites. Kedarnath, nestled in the majestic Himalayas, is renowned for its spiritual significance and breathtaking natural beauty. Let us handle your travel needs while you focus on your spiritual journey and exploration of this sacred destination.',
    top: 'Top Places to Visit in Kedarnath with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Kedarnath Temple",
            description: "The primary attraction, this ancient temple dedicated to Lord Shiva is renowned for its divine aura and intricate architectural grandeur. Built in the 8th century, it attracts millions of pilgrims every year. The temple's serene location amidst towering mountains enhances its spiritual significance, making it one of the most sacred temples in Hinduism."
        },
        {
            title: "2. Bhairav Nath Temple",
            description: "Located a short distance from Kedarnath Temple, this temple is dedicated to Lord Bhairav, a fierce manifestation of Lord Shiva. It offers breathtaking panoramic views of the surrounding Himalayan peaks and valleys. Pilgrims often visit this temple to seek blessings for strength and protection, adding to the spiritual experience of the journey."
        },
        {
            title: "3. Gaurikund",
            description: "The base camp for the Kedarnath trek, Gaurikund is a significant pilgrimage site featuring a hot water spring believed to have medicinal properties. It is also home to a temple dedicated to Goddess Parvati. The area is bustling with activity as pilgrims prepare for the trek, and the surrounding lush greenery provides a tranquil atmosphere."
        },
        {
            title: "4. Vasuki Tal",
            description: "A picturesque high-altitude lake situated about 6 km from Kedarnath Temple, Vasuki Tal is surrounded by snow-capped peaks and vibrant flora. The serene blue waters reflect the grandeur of the mountains, making it a perfect spot for nature lovers and photographers. It is often visited by trekkers seeking solitude and beauty away from the bustling temple area."
        },
        {
            title: "5. Shankaracharya Samadhi",
            description: "This site marks the place where Adi Shankaracharya, the revered philosopher and theologian, is believed to have attained samadhi (spiritual enlightenment). Visitors often come here to pay their respects and reflect on the profound teachings of Shankaracharya. The peaceful ambiance and stunning views add to the spiritual experience."
        },
        {
            title: "6. Chorabari Tal",
            description: "Also known as the Gandhi Sarovar, this glacial lake is located at an altitude of 3,900 meters. It is a serene spot ideal for reflection and nature lovers, offering breathtaking views of the surrounding mountains. The tranquil environment makes it a perfect retreat for those seeking solace amidst nature's beauty."
        },
        {
            title: "7. Kedarnath Wildlife Sanctuary",
            description: "This sanctuary is home to a diverse range of flora and fauna, including the elusive Himalayan musk deer and the bharal (blue sheep). It offers numerous trails for nature enthusiasts to explore, providing opportunities for wildlife spotting and experiencing the rich biodiversity of the region."
        },
        {
            title: "8. Kalimath Temple",
            description: "Situated in the nearby Kalimath village, this temple is dedicated to Goddess Kali and is a revered site for devotees seeking her blessings. The temple’s tranquil surroundings and spiritual atmosphere make it an essential stop for pilgrims, enhancing the divine journey to Kedarnath."
        },
        {
            title: "9. Rudraprayag",
            description: "Located at the confluence of the Alaknanda and Mandakini rivers, Rudraprayag is an important pilgrimage site and serves as a gateway to Kedarnath. The picturesque landscapes and spiritual significance of the area attract numerous visitors who seek both adventure and tranquility."
        },
        {
            title: "10. Triyuginarayan Temple",
            description: "This ancient temple, located about 12 km from Kedarnath, is believed to be the site where Lord Shiva and Goddess Parvati were married. It holds great significance for devotees and features a sacred fire that has been burning for ages, symbolizing the eternal love between the divine couple."
        }
    ],    
    services: [
        {
            name: "Luxury Bus Hire for Kedarnath Trip",
            description: "Elevate your pilgrimage experience with our luxury bus hire for the Kedarnath trip. Travel in comfort and style as you embark on this spiritual journey. Our luxury buses offer plush seating, air-conditioning, and modern amenities like onboard entertainment and Wi-Fi, ensuring a memorable and comfortable trip to Kedarnath. Enjoy scenic views while our professional drivers take care of the road, allowing you to focus on your spiritual reflections."
        },
        {
            name: "Luxury Coach Booking for Uttarakhand Tourism",
            description: "Experience the best of Uttarakhand tourism with our luxury coach booking for the Kedarnath trip from Pune. Our luxury coaches are equipped with state-of-the-art facilities, including reclining seats, individual charging points, and refreshments, to provide a luxurious and relaxing travel experience. Whether you are traveling with family or a larger group, our coaches ensure that your journey to Kedarnath is both comfortable and enjoyable, making every moment count."
        },
        {
            name: "Bus Hire for Kedarnath Trip",
            description: "For those planning a Kedarnath trip, our bus hire services offer a practical and comfortable solution. Choose from a range of bus options to suit your group size and travel preferences, ensuring a smooth and enjoyable pilgrimage. With spacious interiors and amenities like air conditioning and entertainment systems, our buses are designed to make your journey as pleasant as possible. Let us handle the logistics while you focus on your spiritual experience."
        },
        {
            name: "Char Dham Yatra Bus Package from Pune",
            description: "Embark on the sacred Char Dham Yatra with our specially designed bus package from Pune. This comprehensive package includes comfortable transportation, accommodation at selected lodges, and guided tours to the four sacred shrines: Yamunotri, Gangotri, Kedarnath, and Badrinath. With meals included and a dedicated tour guide to enrich your experience with historical and cultural insights, this package ensures a fulfilling spiritual journey tailored to your needs."
        },
        {
            name: "Pune to Badrinath Bus Tour Package",
            description: "Our Pune to Badrinath bus tour package offers a seamless travel experience to one of the most revered shrines in Uttarakhand. Enjoy comfortable and convenient transportation with reclining seats, air conditioning, and restroom facilities on board. We take care of all your travel needs, including accommodation arrangements and guided visits to local attractions, allowing you to embark on a spiritual journey to Badrinath without any hassles."
        },
        {
            name: "Pune to Vrindavan Bus Tour Package",
            description: "Explore the holy city of Vrindavan with our Pune to Vrindavan bus tour package. This package is designed to provide a comprehensive and comfortable travel experience, including visits to significant temples and cultural sites. Enjoy onboard amenities like refreshments and entertainment during your journey, and immerse yourself in the spiritual and cultural heritage of Vrindavan with knowledgeable guides to enhance your experience."
        },
        {
            name: "Vrindavan Tour Packages from Pune",
            description: "Our Vrindavan tour packages from Pune offer a range of options to suit your travel preferences. Whether you’re looking for a short trip or an extended stay, our packages ensure a comfortable and spiritually enriching experience in the sacred city of Vrindavan. Included in the packages are accommodations, transportation, and visits to famous temples, making your journey easy and fulfilling."
        },
        {
            name: "Pune to Kullu Manali Bus Tour Package",
            description: "Experience the scenic beauty of the Himalayas with our Pune to Kullu Manali bus tour package. Enjoy a comfortable journey with spacious seating, air conditioning, and stunning views of the mountains. Our well-planned itineraries include visits to local attractions, adventure activities, and plenty of leisure time to explore the picturesque landscapes of Kullu and Manali, making your trip unforgettable."
        },
        {
            name: "Pune to Amarnath Yatra Bus Tour Package",
            description: "Join our Pune to Amarnath Yatra bus tour package for a spiritually enriching experience. This package provides comfortable transportation and all necessary arrangements for your pilgrimage to the revered Amarnath cave shrine. With guided tours and accommodation included, you can focus on your spiritual journey while we take care of the logistics, ensuring a smooth and fulfilling experience."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your travel needs, including luxury and standard bus hire, Shraddha Travels Pune offers a range of options. Whether you need a bus for a religious pilgrimage, a scenic tour, or a corporate event, our reliable and well-maintained fleet ensures a smooth and enjoyable journey. Our experienced staff is dedicated to providing top-notch service, making your travel experience seamless and hassle-free."
        },
        {
            name: "Bus Hire For Kedarnath Trip Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Hire For Kedarnath Trip. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. We pride ourselves on our customer service and look forward to helping you make your pilgrimage a memorable one."
        }
    ],    
    tableData: [
        ['- Luxury Bus Hire For Kedarnath Trip', '- Luxury Coach Booking for Uttarakhand Tourism Kedarnath Trip From Pune'],
        ['- Bus Hire For Kedarnath Trip', '- Char Dham Yatra Bus Package from Pune'],
        ['- Pune to Badrinath Bus Tour Package', '- Pune to Vrindavan Bus Tour Package'],
        ['- Vrindavan Tour Packages from Pune', '- Pune to Kullu Manali Bus Tour Package'],
        ['- Pune to Amarnath Yatra Bus Tour Package', '- Shraddha Travels Pune On Rent']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire for Kedarnath Trip?",
            WhyChoosedescription: "When it comes to your Kedarnath pilgrimage, Shraddha Travels is your trusted partner for a comfortable and reliable journey. Our extensive experience in organizing travel to sacred destinations ensures that every aspect of your trip is thoughtfully planned and executed. We understand the unique needs of pilgrims and prioritize making your spiritual journey as seamless as possible. Here's why you should choose us:"
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Our modern fleet of buses is designed for comfort, featuring spacious seating with ample legroom, climate control, and other amenities such as onboard entertainment systems. We focus on providing a pleasant travel experience, so you can relax and enjoy the beautiful landscapes on your way to Kedarnath."
        },
        {
            WhyChooseheading: "Expert Drivers:",
            WhyChoosedescription: "Our skilled and experienced drivers are not only well-acquainted with the challenging mountain routes to Kedarnath but also trained in safety and customer service. They prioritize your comfort and security, ensuring a smooth journey, even on winding roads."
        },
        {
            WhyChooseheading: "Tailored Itineraries:",
            WhyChoosedescription: "We offer flexible and customizable itineraries that cater to your specific schedule and preferences. Whether you're on a pilgrimage with a specific purpose or simply want to explore the scenic beauty of Uttarakhand, we can tailor the trip to suit your needs, making adjustments as necessary along the way."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We provide transparent and competitive pricing for our bus hire services. Our cost-effective solutions ensure you receive excellent value for your money, with no hidden fees. We believe that spiritual journeys should be accessible, and we strive to keep our services affordable without compromising on quality."
        },
        {
            WhyChooseheading: "Punctuality:",
            WhyChoosedescription: "Timeliness is a key aspect of our service. We understand the importance of schedules, especially when it comes to pilgrimage timings. Our buses adhere to strict schedules, ensuring that you make the most of your trip without delays or unnecessary stress."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with bookings, address any questions or concerns, and provide support throughout your journey. Whether you need assistance with itinerary changes or have last-minute queries, we are just a call away."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "We adhere to stringent safety standards and conduct regular maintenance checks on our buses. Your safety is our top priority, and we take all necessary precautions to ensure a secure journey. Our commitment to hygiene and cleanliness, especially in light of recent health concerns, guarantees a safe environment for all passengers."
        }
    ]
      }


      const faqData = [
        {
            question: "What types of buses are available for hire for the Kedarnath trip?",
            answer: "We offer a variety of buses, including luxury, standard, and sleeper options. Each bus is equipped with comfortable seating, air conditioning, and other amenities to enhance your travel experience."
        },
        {
            question: "How do I book a bus for the Kedarnath trip?",
            answer: "Booking a bus is simple. You can contact us directly through our website or customer support. Provide details such as your travel dates, number of passengers, and any specific requirements, and we will assist you with the booking process."
        },
        {
            question: "Are there special packages available for the Kedarnath trip?",
            answer: "Yes, we offer special packages for Kedarnath trips that can be customized based on your needs. These packages include various options for accommodation, sightseeing, and other services."
        },
        {
            question: "What is the cost of hiring a bus for the Kedarnath trip?",
            answer: "The cost of hiring a bus depends on factors such as the type of bus, duration of the trip, and specific requirements. We provide competitive pricing and a detailed quote based on your needs."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our buses are equipped with essential amenities such as comfortable seating, air conditioning, and in some cases, Wi-Fi. The specific amenities may vary depending on the type of bus you choose."
        },
        {
            question: "How do you ensure safety during the journey?",
            answer: "We prioritize safety by conducting regular maintenance checks on our buses, employing experienced drivers, and following all necessary safety protocols. Our buses are equipped with safety features to ensure a secure journey."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, you can make changes to your booking based on availability and our terms and conditions. Please contact our customer support team as soon as possible to discuss any modifications."
        },
        {
            question: "What is the maximum number of passengers allowed?",
            answer: "The number of passengers depends on the type of bus you hire. We offer a range of bus sizes to accommodate different group sizes, from smaller buses for intimate groups to larger ones for bigger parties."
        },
        {
            question: "What is your cancellation policy?",
            answer: "Our cancellation policy varies depending on the booking terms and conditions. Please review the policy at the time of booking or contact our customer support team for detailed information."
        },
        {
            question: "Can Shraddha Travels assist with itinerary planning?",
            answer: "Yes, we can assist with planning your itinerary based on your preferences and destinations. Our team can help you design a customized travel plan to make the most of your Kedarnath trip."
        }
    ];
    

    const testimonials = [
        {
            name: "Ms. Priya Sharma",
            text: "Our Kedarnath pilgrimage with Shraddha Travels was a truly memorable experience. The bus was exceptionally comfortable and well-maintained, making the long journey through the mountains a pleasant one. The driver was skilled and ensured our safety on the challenging routes. Shraddha Travels' team provided excellent support throughout the trip, and their attention to detail was impressive. We felt well taken care of from start to finish. Highly recommend their services for anyone planning a Kedarnath trip!"
        },
        {
            name: "Mr. Anil Kumar",
            text: "I recently traveled to Kedarnath with Shraddha Travels, and it was a wonderful experience. The bus was clean, comfortable, and equipped with all the necessary amenities for a long journey. The driver was punctual, professional, and very knowledgeable about the route. Shraddha Travels made our trip stress-free and enjoyable with their reliable service. If you’re planning a trip to Kedarnath, I strongly recommend choosing Shraddha Travels for a smooth and hassle-free experience."
        }
    ];
    

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Hire For Kedarnath Trip Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire For Kedarnath Trip | Luxury & Affordable Packages</title>
    <meta name="description" content="Book our luxury bus services for your Kedarnath trip from Pune. Explore Char Dham Yatra and other Uttarakhand destinations with comfort." />
    <meta name="keywords" content="Bus Hire For Kedarnath Trip, Luxury Bus Booking, Char Dham Yatra from Pune, Pune to Badrinath, Pune to Kullu Manali" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire For Kedarnath Trip | Luxury & Affordable Packages" />
    <meta property="og:description" content="Experience a comfortable journey to Kedarnath with our luxury bus hire services. Perfect for Char Dham Yatra and other scenic trips from Pune." />
    <meta property="og:url" content="https://www.shraddhatravels.com/kedarnath-trip" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/kedarnath-trip.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire For Kedarnath Trip",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/kedarnath-trip",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Kedarnath Trip Bus Package",
                    "description": "Luxury bus hire services for your Kedarnath trip from Pune, including options for Char Dham Yatra and more."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -15.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Bus Hire for Kedarnath Trip for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforkedarnath;