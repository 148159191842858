



import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    "/img/slider/Shraddha slide-01.jpg",
    "/img/slider/Shraddha slide 2-01.jpg",
    "/img/slider/Shraddha slide 2-02.jpg",
    "/img/slider/Shraddha slide 3-01.jpg",
    "/img/slider/Shraddha slide 3-02.jpg",
    "/img/slider/Shraddha slide 3-03.jpg",
    "/img/slider/Shraddha slide 2-02.jpg"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div id="carouselExample" className="carousel slide" data-bs-ride="false">
      <div className="carousel-inner">
        {images.map((src, index) => (
          <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
            <img src={src} className="d-block w-100" alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" onClick={() => setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" onClick={() => setActiveIndex((prevIndex) => (prevIndex + 1) % images.length)}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
