
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Puneromahabaleshwarurbania() {



  const cardData =
  {
    keyword: 'Pune to Mahabaleshwar Urbania On Rent',
    heading: 'Shraddha Travels: Pune to Mahabaleshwar Urbania On Rent',
    headingDescription: 'When planning a trip from Pune to Mahabaleshwar, choosing the right transportation can significantly enhance your experience. At Shraddha Travels, we offer luxurious Force Urbania buses on rent, providing you with comfort and convenience for your journey to this picturesque hill station. Mahabaleshwar, renowned for its stunning landscapes and pleasant climate, is a perfect destination for a refreshing getaway. Our Urbania buses ensure a smooth and enjoyable ride, making your travel experience as delightful as your destination.',
    top: 'Top Places to Visit in Mahabaleshwar with Shraddha Travel:',

    topPlaces: [
        {
            title: "1. Venna Lake",
            description: "A popular spot for boating and picnics, Venna Lake offers serene views and a relaxing environment. Visitors can enjoy paddle boating or rowing on the tranquil waters while surrounded by lush greenery. The area is also dotted with eateries serving delicious local snacks, making it an ideal spot for families and friends to unwind."
        },
        {
            title: "2. Pratapgad Fort",
            description: "A historic fort that played a significant role in Maratha history, Pratapgad Fort provides panoramic views of the surrounding region. The fort features impressive architecture, with bastions and a statue of the legendary warrior king Shivaji Maharaj. Explore the ancient pathways and enjoy the rich heritage while soaking in breathtaking vistas of the Western Ghats."
        },
        {
            title: "3. Elephant's Head Point",
            description: "Known for its unique rock formation resembling an elephant's head, this viewpoint offers breathtaking vistas of the Sahyadri mountain range. It’s a popular spot for trekking and photography, especially during sunrise and sunset. The surrounding greenery and cool breeze add to the serene atmosphere, making it a favorite among nature lovers."
        },
        {
            title: "4. Arthur's Seat",
            description: "Often referred to as the 'Queen of the Points,' Arthur's Seat offers spectacular views of the Konkan coast and the surrounding valleys. The point is famous for its scenic beauty and is ideal for picnics. The area is also steeped in local legends, making it a great spot for both exploration and relaxation amid nature."
        },
        {
            title: "5. Mapro Garden",
            description: "A popular attraction, Mapro Garden is known for its strawberry farms and delicious fruit-based products. Visitors can enjoy fresh strawberries, juices, and a variety of confectioneries, including the famous Mapro chocolate. The garden features a play area for kids and beautiful landscapes, making it perfect for a family outing."
        },
        {
            title: "6. Lingmala Waterfall",
            description: "This stunning waterfall is a must-visit for nature enthusiasts. The cascading water creates a picturesque setting, and the surrounding forest adds to the tranquil ambiance. Visitors can trek to the viewpoint for an exhilarating view of the falls, especially during the monsoon when the water flow is at its peak."
        },
        {
            title: "7. Wilson Point",
            description: "The highest point in Mahabaleshwar, Wilson Point offers panoramic views of the sunrise and the surrounding landscape. It is an ideal spot for early morning visits and capturing stunning photographs. The surrounding hills and valleys create a breathtaking backdrop, making it a perfect escape for photographers and nature lovers alike."
        },
        {
            title: "8. Sunset Point",
            description: "As the name suggests, Sunset Point is the perfect location to witness mesmerizing sunset views over the hills. The vibrant colors of the sky at dusk create a magical atmosphere, drawing visitors for evening picnics. It’s an excellent spot for unwinding after a day of exploring, with the serene ambiance enhancing the experience."
        },
        {
            title: "9. Mahabaleshwar Temple",
            description: "An ancient temple dedicated to Lord Shiva, the Mahabaleshwar Temple is a significant religious site and an architectural marvel. The temple features intricate carvings and a sacred shrine, attracting devotees and tourists alike. Its historical significance and serene surroundings make it a peaceful place for reflection and spirituality."
        },
        {
            title: "10. Bhilar Waterfall",
            description: "A hidden gem, Bhilar Waterfall is known for its serene beauty and peaceful surroundings. This tranquil spot is ideal for relaxation and enjoying the natural beauty of Mahabaleshwar. The waterfall flows gently, creating a soothing sound that enhances the experience. It’s a perfect retreat for those seeking solitude in nature."
        }
    ],    
    services: [
        {
            name: "Pune to Mahabaleshwar Urbania On Rent",
            description: "When planning a trip from Pune to Mahabaleshwar, our Force Urbania rentals offer the perfect solution for a luxurious and comfortable journey. Our well-maintained buses are equipped with modern amenities, ensuring you reach Mahabaleshwar in style. Enjoy plush seating, ample legroom, and air conditioning as you take in the scenic views along the way. Book our Urbania for a smooth, hassle-free trip to this picturesque hill station."
        },
        {
            name: "Pune to Panchgani Urbania On Rent",
            description: "Explore the charming hill station of Panchgani with our Force Urbania on rent. Our spacious and comfortable Urbania buses are designed for group travel, providing a luxurious experience as you enjoy the beautiful landscapes. With features like reclining seats, large windows, and onboard entertainment options, your journey to Panchgani will be just as enjoyable as the destination itself."
        },
        {
            name: "Pune to Mahabaleshwar Urbania On Rent Contact Number",
            description: "For booking a Force Urbania from Pune to Mahabaleshwar, contact us at [insert phone number]. Our friendly and knowledgeable team is ready to assist you with reservations, providing detailed information on pricing, availability, and the various options we offer for your trip. Experience seamless booking with personalized service."
        },
        {
            name: "Force Urbania Luxury Mini Bus on Rent in Pune",
            description: "Experience luxury travel with our Force Urbania mini buses available for rent in Pune. Ideal for corporate events, family trips, and group outings, our Urbania buses combine comfort and style. Each bus is equipped with high-end features like plush interiors, advanced audio-visual systems, and spacious seating, ensuring a premium travel experience for every occasion."
        },
        {
            name: "Force Urbania 17 Seater for Rent",
            description: "Our 17-seater Force Urbania buses are perfect for medium-sized groups looking for a reliable travel option. Whether you need transportation for a corporate event, family gathering, or a local tour, our Urbania buses provide ample space and comfort. Enjoy amenities like climate control, ample luggage space, and a professional driver to ensure a pleasant journey."
        },
        {
            name: "Pune to Tapola Urbania on Rent",
            description: "Travel from Pune to Tapola in comfort with our Force Urbania rentals. Known for its scenic beauty and tranquil environment, Tapola is best explored in our spacious and luxurious Urbania buses. Enjoy a comfortable ride with plenty of space to relax, socialize, or enjoy the stunning landscapes as you make your way to this serene destination."
        },
        {
            name: "Force Urbania on Rent in Pimpri Chinchwad",
            description: "For those in Pimpri Chinchwad, our Force Urbania buses are available for rent to various destinations. Whether you're planning a local outing, a corporate retreat, or an outstation trip, our Urbania buses offer top-notch comfort and reliability. Each bus comes with experienced drivers who know the best routes, ensuring you arrive safely and on time."
        },
        {
            name: "Pune to Kolhapur Force Urbania on Rent",
            description: "Travel from Pune to Kolhapur with our Force Urbania rentals for a comfortable and luxurious ride. Our buses are designed for long-distance travel, equipped with features that enhance your journey, such as plush seating, entertainment systems, and onboard refreshments. Make your trip to Kolhapur enjoyable and stress-free with our premium travel services."
        },
        {
            name: "Pune to Jyotiba Force Urbania on Rent",
            description: "Visit Jyotiba with ease using our Force Urbania on rent. Known for its religious significance, Jyotiba is best accessed with our comfortable Urbania buses. Enjoy a smooth and enjoyable journey from Pune, complete with the comfort and amenities you need to relax and reflect during your pilgrimage."
        },
        {
            name: "Pune to Wai Satara Force Urbania on Rent",
            description: "Explore the scenic destinations of Wai and Satara with our Force Urbania rentals. Our luxurious Urbania buses provide a comfortable ride, ensuring your trip is enjoyable and relaxing. With features like large windows for viewing the stunning landscapes and comfortable seating for long journeys, your experience will be memorable."
        },
        {
            name: "Pune to Kaas Plateau Pathar Force Urbania on Rent",
            description: "Discover the stunning beauty of Kaas Plateau Pathar with our Force Urbania on rent. This unique destination is famous for its diverse flora and breathtaking landscapes, best explored in our comfortable Urbania buses. Enjoy ample space to move around and take in the natural beauty as you embark on an unforgettable journey."
        },
        {
            name: "Force Urbania Hire in Karve Nagar Kothrud",
            description: "For residents of Karve Nagar and Kothrud, our Force Urbania buses are available for hire for any occasion. Whether you need transportation for a local event, family gathering, or a special trip, our Urbania rentals offer comfort and reliability. With our professional drivers, you can focus on enjoying your time while we take care of the journey."
        },
        {
            name: "Force Urbania Hire in Shivaji Nagar Pune",
            description: "Residents of Shivaji Nagar can easily book our Force Urbania buses for various travel needs. Our Urbania rentals provide a luxurious travel experience, whether you're planning a local trip or an outstation journey. Each bus is equipped with modern amenities to enhance your travel comfort."
        },
        {
            name: "Force Urbania Hire in Pune Railway Station",
            description: "Conveniently hire our Force Urbania buses from Pune Railway Station. Ideal for travelers arriving by train, our Urbania rentals offer a comfortable and stylish way to continue your journey or explore Pune and beyond. Enjoy a seamless travel experience with our well-maintained vehicles and professional drivers."
        },
        {
            name: "Pune to Mahabaleshwar Urbania On Rent Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Mahabaleshwar Urbania rentals. Our dedicated team is ready to assist you with your booking, answer any queries, and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Pune to Mahabaleshwar Urbania On Rent', '- Pune to Panchgani Urbania On Rent'],
        ['- Pune to Mahabaleshwar Urbania On Rent Contact Number', '- Force Urbania Luxury Mini Bus on Rent in Pune'],
        ['- Force Urbania 17 Seater for Rent', '- Pune to Tapola Urbania On Rent'],
        ['- Force Urbania on Rent in Pimpri Chinchwad', '- Pune to Kolhapur Force Urbania on Rent'],
        ['- Pune to Jyotiba Force Urbania on Rent', '- Pune to Wai Satara Force Urbania on Rent'],
        ['- Pune to Kaas Plateau Pathar Force Urbania on Rent', '- Force Urbania Hire in Karve Nagar Kothrud'],
        ['- Force Urbania Hire in Shivaji Nagar Pune', '- Force Urbania Hire in Pune Railway Station']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels' Urbania on Rent for Pune to Mahabaleshwar?",
            WhyChoosedescription: "When it comes to renting an Urbania bus for your journey from Pune to Mahabaleshwar, Shraddha Travels is your trusted provider for a comfortable and reliable experience. Our commitment to excellence ensures that every journey is memorable and enjoyable. Here’s why you should choose us for your trip:"
        },
        {
            WhyChooseheading: "Exceptional Comfort:",
            WhyChoosedescription: "Our Force Urbania buses are designed with passenger comfort in mind, featuring spacious seating with generous legroom that allows you to stretch out and relax during your journey. The advanced climate control system ensures a pleasant environment, keeping you cool even on hot days. Whether you're traveling alone or with a group, comfort is guaranteed."
        },
        {
            WhyChooseheading: "Luxury on Wheels:",
            WhyChoosedescription: "Travel in style with our premium Urbania buses that come equipped with modern amenities. Enjoy plush interiors and high-quality seating that make your ride feel more like a first-class experience. The entertainment systems allow you to enjoy music or watch movies during your journey, making it both enjoyable and sophisticated."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "At Shraddha Travels, we pride ourselves on punctuality and reliability. Our Urbania buses are meticulously maintained and serviced regularly to ensure they are in peak condition for every journey. You can trust us to get you to Mahabaleshwar on time, without any hiccups or delays."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are not only highly experienced but also familiar with the best routes to Mahabaleshwar. They prioritize safety and comfort, ensuring you have a secure journey. With their knowledge of local attractions, they can also provide recommendations to enhance your travel experience."
        },
        {
            WhyChooseheading: "Customizable Options:",
            WhyChoosedescription: "We understand that every traveler has unique needs, which is why we offer flexible rental options tailored to your specific requirements. Whether you're planning a short day trip, a relaxing weekend getaway, or an extended stay, we can customize our services to fit your itinerary and budget perfectly."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "Enjoy high-quality travel at affordable rates with Shraddha Travels. We believe in transparent pricing with no hidden costs, allowing you to budget effectively. Our competitive rates ensure that you get excellent value for your money without compromising on comfort or service."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Your safety is our top priority. Our Urbania buses are equipped with the latest safety features, including GPS tracking, emergency exits, and first-aid kits. We conduct regular safety checks and maintenance to ensure that you have a secure and worry-free journey."
        },
        {
            WhyChooseheading: "Convenient Booking:",
            WhyChoosedescription: "Booking your Urbania bus rental is simple and hassle-free with our user-friendly reservation system. You can easily plan your trip, select your vehicle, and secure your booking in just a few clicks. Our streamlined process saves you time and ensures that your travel plans are set without complications."
        },
        {
            WhyChooseheading: "Local Expertise:",
            WhyChoosedescription: "As a local travel agency, we understand the unique needs and preferences of travelers heading to Mahabaleshwar. Our knowledgeable team provides valuable insights, recommendations for attractions, and tips for making the most of your trip. We are here to enhance your experience every step of the way."
        },
        {
            WhyChooseheading: "Excellent Customer Support:",
            WhyChoosedescription: "Our dedicated customer service team is available around the clock to assist you with any queries or concerns before, during, and after your trip. We strive to ensure a seamless experience for our customers, addressing any issues promptly and effectively so that you can focus on enjoying your journey."
        }
    ]
    
  }



  const faqData = [
    {
        question: "How do I book a Force Urbania for a trip from Pune to Mahabaleshwar?",
        answer: "You can book our Force Urbania buses by contacting us directly at [insert phone number] or using our online booking system on our website. Our team will assist you with the reservation process and provide all the necessary details."
    },
    {
        question: "What is the cost of renting a Force Urbania for a trip to Mahabaleshwar?",
        answer: "The cost of renting a Force Urbania for a trip to Mahabaleshwar depends on various factors, including the duration of the rental, the number of passengers, and any additional services requested. For a detailed quote, please contact us directly."
    },
    {
        question: "Are the Urbania buses equipped with amenities for long journeys?",
        answer: "Yes, our Force Urbania buses are equipped with a range of amenities designed for long journeys, including comfortable seating, climate control, entertainment systems, and ample luggage space."
    },
    {
        question: "Can I customize my Urbania rental package for Mahabaleshwar?",
        answer: "Absolutely! We offer customizable rental packages to suit your specific needs. Whether you need a day trip, weekend getaway, or extended rental, we can tailor our services to match your itinerary and preferences."
    },
    {
        question: "How experienced are the drivers for the Pune to Mahabaleshwar route?",
        answer: "Our drivers are highly experienced and familiar with the Pune to Mahabaleshwar route. They prioritize safety and are committed to providing a smooth and pleasant journey."
    },
    {
        question: "What safety features are included in the Force Urbania buses?",
        answer: "Our Urbania buses are equipped with modern safety features, including GPS tracking, emergency exits, seat belts, fire extinguishers, and first-aid kits to ensure a secure travel experience."
    },
    {
        question: "Can I make changes to my booking after it has been confirmed?",
        answer: "Yes, changes to your booking can be made based on availability and with prior notice. Please contact us as soon as possible to discuss any changes to your reservation."
    },
    {
        question: "Is there a minimum rental duration for the Urbania buses?",
        answer: "There may be a minimum rental duration depending on the time of year and availability. For specific details, please reach out to our customer service team."
    },
    {
        question: "What should I do if I have special requests or requirements?",
        answer: "If you have any special requests or requirements, please inform us at the time of booking. We will do our best to accommodate your needs and ensure a comfortable trip."
    },
    {
        question: "How can I contact Shraddha Travels for more information?",
        answer: "For more information, you can contact us at [insert phone number] or visit our website. Our customer service team is available to assist you with any questions or concerns you may have."
    }
];



const testimonials = [
    {
        name: "Miss. Anjali Patil",
        text: "I recently hired the Urbania from Shraddha Travels for a weekend trip to Mahabaleshwar, and it was an amazing experience! The bus was clean, comfortable, and the driver was very professional. I highly recommend them!"
    },
    {
        name: "Mr. Rahul Joshi",
        text: "Shraddha Travels made our monthly trips to Mahabaleshwar so convenient! The Urbania is spacious, and we love the comfort it offers. The rates are affordable, and the service is exceptional. Thank you, Shraddha Travels!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Mahabaleshwar Urbania On Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Mahabaleshwar Urbania On Rent</title>
    <meta name="description" content="Rent Force Urbania for trips from Pune to Mahabaleshwar and Panchgani. Comfortable luxury travel for your journey!" />
    <meta name="keywords" content="Pune to Mahabaleshwar Urbania, Urbania on rent Pune, Force Urbania 17 seater for rent, Pune to Panchgani Urbania" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Mahabaleshwar Urbania On Rent" />
    <meta property="og:description" content="Explore Mahabaleshwar and Panchgani with our Force Urbania rental service. Comfortable travel at affordable rates!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-mahabaleshwar-urbania-on-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/urbania-mahabaleshwar.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Mahabaleshwar Urbania On Rent",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-mahabaleshwar-urbania-on-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Force Urbania Rental for Pune to Mahabaleshwar",
                    "description": "Rent Force Urbania for a comfortable trip from Pune to Mahabaleshwar and nearby locations."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-24.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Pune to Mahabaleshwar Urbania On Rent – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Puneromahabaleshwarurbania;