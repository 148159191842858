
import React, { useState } from 'react';
import '../css/GallerySection.css';
import { Helmet } from 'react-helmet';


const vehicleData = [

    { name: 'Vehicle 6', imgSrc: '/img/gallery/Gallery-06.jpg' },
    { name: 'Vehicle 2', imgSrc: '/img/gallery/Gallery-02.jpg' },
    { name: 'Vehicle 4', imgSrc: '/img/gallery/Gallery-04.jpg' },
    { name: 'Vehicle 7', imgSrc: '/img/gallery/Gallery-07.jpg' },
    { name: 'Vehicle 8', imgSrc: '/img/gallery/Gallery-08.jpg' },
    { name: 'Vehicle 9', imgSrc: '/img/gallery/Gallery-09.jpg' },
    { name: 'Vehicle 9', imgSrc: '/img/gallery/Gallery-13.jpg' },
    { name: 'Vehicle 9', imgSrc: '/img/gallery/Gallery-14.jpg' },
    { name: 'Vehicle 9', imgSrc: '/img/gallery/Gallery-15.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20240315-WA0009.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20240506-WA0032.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20240506-WA0033.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20241005-WA0001.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20241005-WA0000.jpg' },
    { name: 'Vehicle 12', imgSrc: '/img/gallery/IMG-20240403-WA0007.jpg' },
   
  ];

const GallerySection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setSelectedImage(vehicleData[index]);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const nextImage = () => {
    const nextIndex = (currentIndex + 1) % vehicleData.length;
    setSelectedImage(vehicleData[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (currentIndex - 1 + vehicleData.length) % vehicleData.length;
    setSelectedImage(vehicleData[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

<section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Gallery</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>

      <section className="gallery-section px-md-2 px-lg-4 p-0">
        <div className='container-fluid px-0'>
          <div className="gallery-container container row g-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {vehicleData.map((vehicle, index) => (
              <div
                key={index}
                className="gallery-card col-12 col-md-6 col-lg-4"
                onClick={() => openModal(index)}
                style={{ position: 'relative', cursor: 'pointer' }}
              >
                <img
                  src={vehicle.imgSrc}
                  alt={vehicle.name}
                  className="gallery-image"
                  style={{ width: '100%', height: 'auto', display: 'block' }}
                />
                <div className="overlay" style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  color: '#fff',
                  fontSize: '2rem',
                  zIndex: 1,
                }}>
                  +
                </div>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                  onMouseEnter={(e) => {
                    const overlay = e.currentTarget.previousElementSibling;
                    overlay.style.opacity = '1';
                  }}
                  onMouseLeave={(e) => {
                    const overlay = e.currentTarget.previousElementSibling;
                    overlay.style.opacity = '0';
                  }}
                />
              </div>
            ))}
          </div>

          {selectedImage && (
            <div
              className="modal"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
              }}
            >
              <div className="modal-content" style={{
                textAlign: 'center',
                position: 'relative',
                maxWidth: '60%',
                maxHeight: '80%',
                overflow: 'hidden',
              }}>
                <span className="modal-close" onClick={closeModal} style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  cursor: 'pointer',
                  fontSize: '2rem',
                  // color: 'white',
                }}>×</span>
                <img src={selectedImage.imgSrc} alt={selectedImage.name} style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '70vh',
                  objectFit: 'contain',
                }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: '0',
                  width: '100%',
                }}>
                  <button className="modal-prev" onClick={prevImage} style={{ fontSize: '2rem', cursor: 'pointer', background: 'transparent', border: 'none', color: 'black' }}>‹</button>
                  <button className="modal-next" onClick={nextImage} style={{ fontSize: '2rem', cursor: 'pointer', background: 'transparent', border: 'none', color: 'black' }}>›</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default GallerySection;
