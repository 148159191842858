
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Forceurbaniaonrentforshta() {



  const cardData =
  {
    keyword: ' Force Urbania on Rent for Ashtavinayak Darshan',
    heading: 'Shraddha Travels: Force Urbania on Rent for Ashtavinayak Darshan',
    headingDescription: 'Ashtavinayak Darshan is a revered pilgrimage that involves visiting eight sacred temples dedicated to Lord Ganesha. This spiritual journey is deeply cherished by devotees, and to make it comfortable and memorable, renting a Force Urbania from Shraddha Travels is an excellent choice. Here’s why:',
    top: 'Ashtavinayak means "eight Ganeshas," and each temple holds significant religious and cultural importance. This pilgrimage involves visiting the following temples:',

    topPlaces: [
        {
            title: "1. Moreshwar",
            description: "Located in Morgaon, this temple is considered the first and most important of the Ashtavinayak temples. It features a unique idol of Ganesh in a seated posture, with a peacock, his vehicle, nearby. The temple is known for its spiritual significance and is often visited by devotees seeking blessings for new beginnings."
        },
        {
            title: "2. Siddhivinayak",
            description: "Situated in Siddhatek, this temple is renowned for its idol with a distinctive curved trunk, which is believed to have significant spiritual powers. The temple is a serene place of worship, drawing pilgrims who come to seek guidance and protection. Its beautiful architecture and tranquil surroundings make it a popular spot for reflection."
        },
        {
            title: "3. Ballaleshwar",
            description: "Located in Pali, this temple is famous for its deity, who is revered as a protector of devotees. Unique in its representation of Ganesh with an elephant-like trunk, it attracts many visitors seeking strength and courage. The temple’s vibrant atmosphere during festivals is a sight to behold, making it a culturally rich experience."
        },
        {
            title: "4. Varadavinayak",
            description: "Found in Mahad, this temple is known for its idol, which is adorned with precious stones and considered a wish-fulfilling deity. Many devotees come here to express their desires and seek divine intervention. The temple’s ornate carvings and peaceful ambiance create a perfect setting for prayer and contemplation."
        },
        {
            title: "5. Chintamani",
            description: "Located in Theur, this temple is significant for its ability to relieve devotees from stress and worries. The deity here is believed to grant peace and satisfaction, making it a sought-after destination for those facing challenges. The serene environment enhances the spiritual experience, allowing visitors to connect deeply with their faith."
        },
        {
            title: "6. Girijatmaj",
            description: "Situated in Lonavala, this temple is famous for its natural cave setting and the statue of Ganesh, which is said to be one of the oldest in the Ashtavinayak series. The breathtaking surroundings add to the temple's charm, making it a great place for nature lovers and spiritual seekers alike."
        },
        {
            title: "7. Vighneshwar",
            description: "Located in Ozar, this temple is dedicated to Ganesh as the remover of obstacles. Highly revered for its significant spiritual energy, it attracts devotees looking for guidance in their endeavors. The intricate architecture and peaceful atmosphere make it an ideal spot for prayer and meditation."
        },
        {
            title: "8. Ranjangaon",
            description: "Found in Ranjangaon, this temple is known for its powerful deity, believed to remove all troubles and grant success in endeavors. The temple’s design, adorned with beautiful sculptures, and its vibrant festivals attract numerous visitors, making it a hub of spirituality and culture."
        }
    ],    
    services: [
        {
            name: "Pune to Ashtavinayak Urbania Tour Package",
            description: "Embark on a spiritual journey with our Pune to Ashtavinayak Urbania tour package. This package offers a comfortable and luxurious way to visit the eight revered Ganesh temples of Ashtavinayak. Our Force Urbania buses provide ample space, climate control, and modern amenities to ensure a pleasant and hassle-free pilgrimage experience. Whether you are looking for a single day trip or a multi-day tour, we have customizable packages to fit your schedule and budget."
        },
        {
            name: "Cheapest Ashtavinayak Tour from Pune",
            description: "Looking for an affordable way to experience the Ashtavinayak pilgrimage? Our cheapest Ashtavinayak tour from Pune offers cost-effective solutions without compromising on comfort and quality. We provide competitive pricing for our Force Urbania rentals, ensuring that you receive excellent value for your money. Enjoy the convenience of a well-maintained vehicle and professional drivers at budget-friendly rates."
        },
        {
            name: "13 Seater Urbania on Rent for Ashtavinayak Tour from Pune",
            description: "For smaller groups or families, our 13-seater Urbania on rent is the perfect choice for the Ashtavinayak tour from Pune. This vehicle combines comfort and convenience with its spacious seating and climate-controlled environment. Ideal for intimate groups, the 13-seater Urbania ensures that your pilgrimage is both comfortable and enjoyable."
        },
        {
            name: "17 Seater Urbania on Rent for Ashtavinayak Tour from Pune",
            description: "Our 17-seater Urbania on rent provides additional space for slightly larger groups embarking on the Ashtavinayak tour from Pune. With its luxurious interiors and advanced amenities, this vehicle offers a comfortable and enjoyable travel experience. It’s an excellent option for groups looking for a blend of space and comfort."
        },
        {
            name: "Urbania on Rent for Pune to Ranjangaon Ganpati Darshan",
            description: "Experience a divine journey to the Ranjangaon Ganpati temple with our Urbania on rent. Located in Ranjangaon, this temple is one of the important Ashtavinayak sites. Our Force Urbania buses are equipped with all necessary comforts to make your trip smooth and enjoyable, ensuring that you reach the temple in a relaxed state."
        },
        {
            name: "Pune to Siddhivinayak Temple Force Urbania on Rent",
            description: "Travel comfortably from Pune to the Siddhivinayak Temple in Siddhatek with our Force Urbania on rent. This temple is renowned for its unique and powerful idol of Lord Ganesha. Our Urbania buses are designed to offer a premium travel experience, complete with climate control, entertainment options, and ample luggage space, making your journey to Siddhivinayak Temple a memorable one."
        },
        {
            name: "Force Urbania on Rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan",
            description: "The Moreshwar Temple at Morgaon is the first and one of the most significant temples in the Ashtavinayak pilgrimage. Rent our Force Urbania for a comfortable trip from Pune to this sacred site. Our Urbania buses offer generous seating, modern amenities, and a well-maintained environment, ensuring that your visit to Moreshwar Temple is both enjoyable and spiritually fulfilling."
        },
        {
            name: "Pune to Siddhivinayak Temple Siddhatek Force Urbania on Rent",
            description: "Make your pilgrimage to the Siddhivinayak Temple in Siddhatek seamless and comfortable with our Force Urbania on rent. Known for its powerful deity and significant spiritual value, Siddhivinayak Temple is an essential stop on the Ashtavinayak tour. Our Urbania buses provide a luxurious travel experience with features like climate control, advanced entertainment systems, and professional drivers to ensure a smooth journey from Pune to Siddhatek."
        },
        {
            name: "Force Urbania on Rent for Ashtavinayak Darshan from Pimpri Chinchwad",
            description: "Experience the convenience and comfort of a Force Urbania rental for your Ashtavinayak Darshan journey from Pimpri Chinchwad. Our Urbania buses are equipped to handle group travel with ease, offering a spacious and comfortable environment to visit the eight revered Ganesh temples. Whether you're planning a day trip or an extended pilgrimage, our rental service ensures a smooth and enjoyable experience."
        },
        {
            name: "Pune to Ballaleshwar Temple Pali Urbania Bus on Rent",
            description: "Embark on a spiritual journey to the Ballaleshwar Temple in Pali with our Force Urbania bus on rent. Located in Pali, this temple is a significant part of the Ashtavinayak pilgrimage. Our Urbania buses provide ample space, climate control, and modern amenities, making your travel from Pune to Ballaleshwar Temple comfortable and enjoyable."
        },
        {
            name: "Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan",
            description: "For your visit to the Varad Vinayak Temple in Mahad, we offer Force Urbania rentals that ensure a luxurious travel experience. This temple is an integral part of the Ashtavinayak tour, known for its unique and sacred idol. Our Urbania buses offer a blend of comfort and convenience, featuring climate control, reclining seats, and entertainment options for a pleasant journey."
        },
        {
            name: "Pune to Chintamani Temple Theur Ashtavinayak Darshan Urbania on Rent",
            description: "Travel in style and comfort to the Chintamani Temple in Theur with our Force Urbania on rent. This temple is an essential stop in the Ashtavinayak Darshan, renowned for its spiritual significance. Our Urbania buses are designed to provide a premium travel experience, ensuring that your visit to Chintamani Temple is both comfortable and memorable."
        },
        {
            name: "Pune to Girijatmaj Temple Lenyadri Urbania on Rent",
            description: "Make your pilgrimage to the Girijatmaj Temple in Lenyadri a comfortable and enjoyable experience with our Force Urbania on rent. Located in Lenyadri, this temple is one of the key sites in the Ashtavinayak tour. Our Urbania buses offer spacious seating, climate control, and advanced amenities, ensuring a smooth journey from Pune to Girijatmaj Temple."
        },
        {
            name: "Pune to Vighnahar Temple Ozar Ganpati Ashtavinayak Darshan",
            description: "Our Force Urbania rentals are ideal for visiting the Vighnahar Temple in Ozar, an important site in the Ashtavinayak Darshan. Known for its powerful Ganpati idol, this temple is a must-visit on your pilgrimage. Our Urbania buses provide a comfortable and well-equipped travel experience, complete with climate control and entertainment options to enhance your journey from Pune to Vighnahar Temple."
        },
        {
            name: "Force Urbania On Rent for Ashtavinayak Darshan Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Force Urbania On Rent for Ashtavinayak Darshan. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
    tableData: [
        ['- Pune to Ashtavinayak Urbania Tour Package', '- Cheapest Ashtavinayak Tour from Pune'],
        ['- 13 Seater Urbania on Rent for Ashtavinayak Tour from Pune', '- 17 Seater Urbania on Rent for Ashtavinayak Tour from Pune'],
        ['- Urbania on Rent for Pune to Ranjangaon Ganpati Darshan', '- Pune to Siddhivinayak Temple Force Urbania on Rent'],
        ['- Force Urbania on Rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan', '- Pune to Siddhivinayak Temple Siddhatek Force Urbania on Rent'],
        ['- Force Urbania on Rent for Ashtavinayak Darshan from Pimpri Chinchwad', '- Pune to Ballaleshwar Temple Pali Urbania Bus on Rent'],
        ['- Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan', '- Pune to Chintamani Temple Theur Ashtavinayak Darshan Urbania on Rent'],
        ['- Pune to Girijatmaj Temple Lenyadri Urbania on Rent', '- Pune to Vighnahar Temple Ozar Ganpati Ashtavinayak Darshan']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Force Urbania on Rent for Ashtavinayak Darshan?",
            WhyChoosedescription: "Shraddha Travels stands out as the premier choice for renting a Force Urbania for your Ashtavinayak Darshan due to our unwavering commitment to providing exceptional travel experiences. Our focus on customer satisfaction ensures that you have a memorable and enriching journey, making us a trusted partner for your spiritual pilgrimage."
        },
        {
            WhyChooseheading: "Comfort and Luxury:",
            WhyChoosedescription: "Our Force Urbania buses are meticulously designed for maximum comfort, featuring spacious seating arrangements, individual air-conditioning controls, and advanced entertainment systems. This ensures that you can relax and enjoy your journey to all Ashtavinayak temples, free from the stress of travel."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our drivers are not only experienced but also possess extensive knowledge of the routes to various Ashtavinayak temples. They prioritize your safety and comfort, navigating efficiently to ensure a smooth and timely journey while also being courteous and respectful to all passengers."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "We pride ourselves on our reputation for punctuality and reliability. Our buses undergo regular maintenance checks to ensure they are always in top condition, allowing you to depend on us for a hassle-free trip. Your schedule is our priority, and we strive to meet your travel needs promptly."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We understand that every pilgrimage is unique, which is why we offer flexible rental packages tailored to your specific needs. Whether you're planning a single-day trip or an extended pilgrimage, we work closely with you to create a package that fits your schedule, preferences, and budget."
        },
        {
            WhyChooseheading: "Top-Notch Safety:",
            WhyChoosedescription: "Your safety is our utmost priority. Our Force Urbania buses are equipped with modern safety features, including GPS tracking for real-time location monitoring, seat belts for all passengers, and comprehensive emergency equipment. We ensure that you travel with peace of mind, knowing that safety protocols are always in place."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We offer transparent and competitive pricing with no hidden charges, ensuring you receive excellent value for your money. Our rates are designed to be affordable without compromising on quality or service, making it easier for you to embark on your spiritual journey without financial stress."
        },
        {
            WhyChooseheading: "Well-Maintained Vehicles:",
            WhyChoosedescription: "Our Urbania buses are regularly serviced and cleaned to maintain high standards of hygiene and comfort. We take pride in presenting vehicles that are not only well-maintained but also equipped with modern conveniences, ensuring that your travel experience is pleasant and enjoyable from start to finish."
        },
        {
            WhyChooseheading: "Easy Booking Process:",
            WhyChoosedescription: "We provide a straightforward and user-friendly booking process. You can easily book your Urbania online through our website or contact our customer service team for assistance. We aim to ensure that your booking experience is seamless, allowing you to focus on your pilgrimage rather than logistics."
        },
        {
            WhyChooseheading: "Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with any queries or special requests you may have. From initial inquiries to post-booking support, we are committed to providing a personalized experience, ensuring that your needs are met at every stage of your journey."
        },
        {
            WhyChooseheading: "Positive Reviews:",
            WhyChoosedescription: "We take pride in our track record of satisfied customers who have enjoyed our service. Our positive reviews and testimonials reflect our commitment to excellence, customer satisfaction, and our goal of creating memorable travel experiences. Your happiness is our success."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What is the seating capacity of the Force Urbania buses?",
        answer: "Our Force Urbania buses come in various capacities, including 13, 17, and 20-seaters. Please specify your group size, and we will provide the appropriate bus for your needs."
    },
    {
        question: "Are the Force Urbania buses equipped with air-conditioning?",
        answer: "Yes, all our Force Urbania buses are fully air-conditioned to ensure a comfortable journey regardless of the weather conditions."
    },
    {
        question: "Can I book the Urbania for a one-day trip?",
        answer: "Absolutely! We offer flexible rental options, including one-day trips. You can choose a package that best suits your itinerary for the Ashtavinayak Darshan."
    },
    {
        question: "How do I book a Force Urbania for Ashtavinayak Darshan?",
        answer: "Booking is simple. You can either visit our website or contact our customer service team to make a reservation. We will guide you through the process and confirm your booking promptly."
    },
    {
        question: "What safety features are included in the Force Urbania?",
        answer: "Our Force Urbania buses are equipped with safety features such as seat belts, emergency exits, fire extinguishers, and GPS tracking for real-time monitoring."
    },
    {
        question: "Are there any additional charges for the Urbania rental?",
        answer: "We offer transparent pricing with no hidden charges. The cost of your rental will be provided upfront, and any additional fees will be clearly communicated before booking."
    },
    {
        question: "Can I request special amenities on the bus?",
        answer: "Yes, if you have specific requirements or requests, please let us know at the time of booking. We will do our best to accommodate your needs."
    },
    {
        question: "What is the cancellation policy for the Urbania rental?",
        answer: "Our cancellation policy varies depending on the booking terms. Please review our terms and conditions or contact our customer service team for details on cancellations."
    },
    {
        question: "Are the drivers experienced and familiar with the Ashtavinayak routes?",
        answer: "Yes, our drivers are experienced and knowledgeable about the routes to the Ashtavinayak temples, ensuring a smooth and safe journey."
    },
    {
        question: "How can I contact Shraddha Travels for further assistance?",
        answer: "You can reach out to us via our website, email, or phone. Our customer support team is available to assist you with any questions or concerns regarding your Urbania rental."
    }
];



const testimonials = [
    {
        name: "Mr. Ravi Prajapati",
        text: "We recently hired a Force Urbania from Shraddha Travels for our Ashtavinayak Darshan trip, and it was an outstanding experience. The bus was luxurious, clean, and incredibly comfortable. The air-conditioning made our journey pleasant even in the hot weather, and the reclining seats provided ample relaxation. Our driver, Mr. Shinde, was punctual and knowledgeable, ensuring we visited all the temples smoothly and safely. Shraddha Travels made our pilgrimage stress-free and enjoyable. Highly recommend their services!"
    },
    {
        name: "Miss Snehal Yadav",
        text: "Our family trip to the Ashtavinayak temples was made perfect thanks to Shraddha Travels. We opted for their Force Urbania on rent, and it exceeded our expectations. The bus was spacious, with plenty of room for everyone, and the amenities were top-notch. The booking process was straightforward, and the customer support team was helpful and responsive. The driver, Miss Anjali, was excellent, ensuring a smooth and safe journey. We couldn’t have asked for a better service. Thank you, Shraddha Travels, for making our spiritual journey memorable!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Force Urbania On Rent for Ashtavinayak Darshan Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Force Urbania On Rent for Ashtavinayak Darshan</title>
    <meta name="description" content="Rent Force Urbania for Ashtavinayak Darshan from Pune. Affordable packages to visit all Ashtavinayak temples." />
    <meta name="keywords" content="Force Urbania on rent, Ashtavinayak Darshan, Pune to Ashtavinayak tour, 13 seater Urbania, 17 seater Urbania" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Force Urbania On Rent for Ashtavinayak Darshan" />
    <meta property="og:description" content="Explore the Ashtavinayak temples with our Force Urbania rental service from Pune. Affordable rates and reliable service!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/force-urbania-ashtavinayak-darshan" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/urbania-ashtavinayak.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Force Urbania On Rent for Ashtavinayak Darshan",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/force-urbania-ashtavinayak-darshan",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Force Urbania Rental for Ashtavinayak Darshan",
                    "description": "Rent Force Urbania for tours to Ashtavinayak temples including Moreshwar, Siddhivinayak, and more from Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-23.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs - Force Urbania on Rent for Ashtavinayak Darshan</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Forceurbaniaonrentforshta;