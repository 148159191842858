import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const navbarCollapseRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const handleNavItemClick = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };

  useEffect(() => {
    const handleMobileMenuToggle = () => {
      if (navbarCollapseRef.current) {
        navbarCollapseRef.current.classList.toggle('show');
      }
    };

    const mobileMenuIcon = mobileMenuRef.current;
    mobileMenuIcon.addEventListener('click', handleMobileMenuToggle);

    return () => {
      mobileMenuIcon.removeEventListener('click', handleMobileMenuToggle);
    };
  }, []);

  return (
    <header className="main-header">
      <div className="py-3 bgg">
        <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
        <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
  <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
    <i className="las la-phone-volume fs-4 text-white colored me-2"></i>
    <div>
      <h6 className="mb-0">
        <div className="phone-numbers d-md-flex">
          <a href="tel:+918087847191" className="d-block text-white fw-bold fs-5"> <i className="fas fa-phone"></i> +91 8087847191</a>
          <a href="tel:+91" className=" text-white fw-bold d-block d-md-none fs-5"> <i className="fas fa-phone"></i> +91 9552010777</a>
          <a href="tel:+91" className=" text-white fw-bold d-none d-md-block fs-5">&nbsp;/&nbsp;+91 9552010777</a>
        </div>
      </h6>
    </div>
  </li>
  <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
    <i className="las la-envelope-open fs-4 text-white colored me-2"></i>
    <div>
      <h6 className="mb-0">
        <a href="mailto:booking@shraddhatravel.com" className="d-block text-white fw-bold fs-5"> <i className="fas fa-envelope"></i> booking@shraddhatravel.com</a>
      </h6>
    </div>
  </li>
  
 
  <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
    <div>
      <h6 className="mb-0 px-2">
        <div className="social-icons d-flex d-none d-md-block">
          <a href="https://www.facebook.com" target="_blank" className="text-white me-3 fs-5"><i className="fab fa-facebook"></i></a>
          <a href="https://www.instagram.com" target="_blank" className="text-white me-3 fs-5"><i className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com" target="_blank" className="text-white me-3 fs-5"><i className="fab fa-linkedin"></i></a>
          <a href="https://www.twitter.com" target="_blank" className="text-white fs-5"><i className="fab fa-twitter"></i></a>
        </div>
      </h6>
    </div>
  </li>
</ul>

        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          <Link className="navbar-brand w-50" to="/">
            <img src="/img/logo.jpg" className="px-4 px-md-5 px-lg-0 py-md-0 logoo" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler borderr"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={mobileMenuRef}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" ref={navbarCollapseRef} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/" onClick={handleNavItemClick}>Home</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle active fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About
                </Link>
                <ul className="dropdown-menu ">
                  <li><Link className="dropdown-item  fw-bold" to="/about-us" onClick={handleNavItemClick}>About Us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link></li>
                  <li><Link className="dropdown-item  fw-bold" to="/term-condition" onClick={handleNavItemClick}>Term & Condition</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/privacy-policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/service" onClick={handleNavItemClick}>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-fleet" onClick={handleNavItemClick}>Our&nbsp;Fleet</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/packages" onClick={handleNavItemClick}>Packages</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-network" onClick={handleNavItemClick}>Our&nbsp;Network</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/book-taxi" onClick={handleNavItemClick}>Booking</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle active fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Contact
                </Link>
                <ul className="dropdown-menu active">
                  <li><Link className="dropdown-item   fw-bold" to="/contact" onClick={handleNavItemClick}>Contact Us</Link></li>
                  <li><Link className="dropdown-item  fw-bold" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link></li>
                  <li><Link className="dropdown-item  fw-bold" to="/carrer" onClick={handleNavItemClick}>Career</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/blog" onClick={handleNavItemClick}>Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

